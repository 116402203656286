//http://192.168.2.121:4000/exhibitor-event-payment
//test_aman.singh@lmdconsulting.com
//123456
/// <reference types="@types/googlemaps" />
import {
  Component,
  OnInit,
  NgZone,
  ElementRef,
  ViewChild,
} from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
  FormArray,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { forkJoin } from "rxjs";
// Import utility method class
// Handle the global property
import { Globals } from "../globals";
import { LoginService } from "../login/login.service";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
// import { } from 'googlemaps';
import { MapsAPILoader, AgmMap } from "@agm/core";
import { MouseEvent } from "@agm/core";
import { TaxonomyService } from "../taxonomy/taxonomy.service";
import { FileUploader } from "ng2-file-upload";
import { environment } from "../../environments/environment";
import { CreditCardValidators } from "angular-cc-library";
import { ExhibitorService } from "../exhibitor-event-payment/exhibitor.service";
import * as _ from "lodash";
import { ActivatedRoute } from "@angular/router";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SystemConstants } from "../constants/system.constants";
import * as TranslatorJson from "./../../assets/i18n/en.json";
import { CommonService } from "../services/common.service";
import { ErrorsValidatorOptions } from "../interface/common-validators/common-validators.interface";
import { UserRole } from "../constants/user-role.constants";
import { CommonStatus } from "../constants/common-status.constants";
@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit {
  tab: string;
  userData: any;
  latitude: number;
  longitude: number;
  zoom: number;
  modalRef: BsModalRef;
  address: string;
  private geoCoder;
  imageSrc = "";
  conditionAccepted: boolean = false;
  conditionAccept: boolean = false;
  total = 0;
  registerPaymentAmount: number = 0;
  showScreeingList: boolean = false;

  @ViewChild("search")
  public searchElementRef: ElementRef;
  public authorizeStep = "";
  paymentSubmitted = false;
  userBillingSubmitted = false;
  finalformgSubmitted = false;
  userInfoForm: FormGroup;
  step3form: FormGroup;
  region: FormArray;
  savedMember: any;
  userInfoObj: any;
  finalFormObj: any;
  prodList: Array<string | number>;
  authorizeForm: FormGroup;
  isSignUp = false;
  isShow: boolean = false;
  showPwd: boolean = false;
  type: string = "password";
  isAvatar = true;
  selectedAvatar = "";
  access: any = {};
  signUpForm: FormGroup;
  imageProfile: FormGroup;
  addressProfile: FormGroup;
  registerData: any = {};
  //centerRadius: 16094
  avatars: any = [];
  signupFormShow = -1;
  map: AgmMap;
  miles = 50;
  location: any = [];
  private notifier: NotifierService;
  isExibitor = false;

  // TODO:- hidning Industries we are not using this time 

  // industriesList: any = [];
  screeningList: any = [];
  imgUrl: string;
  defaultValue = [
    {
      id: 1,
      value: "Day before  the event",
    },
    {
      id: 7,
      value: "One week before the event",
    },
    {
      id: 14,
      value: "Two weeks before the event",
    },
    {
      id: 28,
      value: "Four weeks before the event",
    },
  ];

  // TODO :- Hiding the medical exhibitor

  // medicalExhibitor: any = [
  //   { name: "Yes", value: "1" },
  //   { name: "No", value: "0" },
  // ];

  imageType: any = [
    { type: "avatar", value: "Select Avatar" },
    { type: "image", value: "Upload Image" },
  ];

  fAttributes: any = {};
  isMedical = false;
  public uploader: FileUploader = new FileUploader({
    url:
      environment.apiUrl + SystemConstants.USER_IMAGE_FOLDER_UPLOADER_URL,
  });
  translatorJson: typeof TranslatorJson;
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  defaultExhibitorProfileImage = "defaultAvatar.png";
  selectedRegionCode: any;
  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    public globals: Globals,
    private loginService: LoginService,
    notifierService: NotifierService,
    private router: Router,
    public mapsApiLoader: MapsAPILoader,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private taxonomyService: TaxonomyService,
    private exhibitorService: ExhibitorService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {
      if (params["slug"]) {
      }
      //signupFormShow
    });
    let curUrl = this.router.url.split("/");
    // console.log("aa",curUrl);
    // console.log('eee',curUrl[2]);
    this.authorizeStep = "event-step-b-0";

    this.tab = curUrl[curUrl.length - 1];

    this.notifier = notifierService;
    // this is used to get all list of avatar already in folder
    for (let i = 0; i < 91; i++) {
      this.avatars.push("avatar-" + i + ".png");
    }
    this.selectedAvatar = this.defaultExhibitorProfileImage;
    this.translatorJson = Object.assign({}, (TranslatorJson as any).default);
  }

  ngOnInit() {
    //new
    this.route.queryParams.subscribe(() => {
      // Defaults to 0 if no query param provided.
    });

    this.route.params.subscribe((params) => {
      if (params["slug"]) {
      }
      //signupFormShow
    });
    let curUrl = this.router.url.split("/");
    // console.log("aa",curUrl);
    // console.log('eee',curUrl[2]);
    this.authorizeStep = "event-step-b-0";
    this.signupFormInitialization();
    if (curUrl && curUrl[2] == "attend") {
      this.exhibitorUserInit();
    } else if (curUrl && curUrl[2] == "host") {
      this.corporateUserInit();
    }

    this.fAttributes = Object.assign({}, this.globals.adminPaginationAttrs);
    // Copy object
    this.registerPaymentAmount = this.globals.registerPaymentAmount;
    //  to load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.latitude = 38.8936966;
      this.longitude = -77.0199396;
      this.zoom = 7;
      this.geoCoder = new google.maps.Geocoder();
      let autocomplete = new google.maps.places.Autocomplete(
        <HTMLInputElement>document.getElementById("eventMap"),
        {}
      );
      google.maps.event.addListener(autocomplete, "place_changed", () => {
        this.ngZone.run(() => {
          // to get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          // to verify result from map
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // to set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.getAddress(this.latitude, this.longitude);
          this.zoom = 7;
        });
      });
    });

    // file upload steps
    this.uploader.onAfterAddingFile = (file: any) => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any) => {
      const data = JSON.parse(response);

      if (
        status === 200 &&
        data &&
        data.files &&
        data.files[0] &&
        data.files[0].filename
      ) {
        this.imgUrl = data.files[0].filename;
        this.signupFormImageName.patchValue(data.files[0].filename);
      } else {
        if (data && data.error && data.error.message) {
          console.error(data.error.message);
        }
      }
    };
  }

  firstFormInit() {
    this.authorizeForm = this.formBuilder.group({
      card: this.formBuilder.group({
        cardNumber: [
          "",
          [Validators.required, CreditCardValidators.validateCCNumber],
        ],
        expireAt: [
          "",
          [
            Validators.required,
            CreditCardValidators.validateExpDate,
            Validators.maxLength(7),
          ],
        ],
        cvv: [
          "",
          [
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(4),
          ],
        ],
        fullName: ["", [Validators.required]],
        amount: [
          this.globals.registerPaymentAmount,
          [Validators.required],
        ],
      }),
    });
    //this.googleAddress('autocomplete1');
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    //  console.log($event);
    // this.getAddress(this.latitude, this.longitude);
  }
  centerChangeds($event: any, indexed: number) {
    // alert();
    this.regionArray.value[indexed]["centerRadius"] = $event;
    this.regionArray.controls[indexed]["centerRadius"] = $event;
    // console.log(this.regionArray);

    //  console.log($event);
    //  console.log(indexed);

    // this.regionArray.controls[indexed]["miles"] =$event*0.00062137;
  }
  // centerChanged($event: any, indexed: number) {
  //   this.regionArray.controls[indexed]["centerRadius"] =$event;
  //   console.log("================>");

  //   console.log("---<");

  // }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          //  console.log(this.miles * 1609.34);
          var regionsObj = this.fb.group({
            name: [results[0].formatted_address],
            lat: [results[0].geometry.location.lat()],
            long: [results[0].geometry.location.lng()],
            loc: [
              [
                results[0].geometry.location.lng(),
                results[0].geometry.location.lat(),
              ],
            ],
            miles: this.miles,
            meters: this.miles * 1609.34,
            centerRadius: this.miles * 1609.34,
          }) as FormGroup;
          this.regionArray.push(regionsObj);
          //    console.log(this.regionArray);
        }
      }
    );
  }
  /*
   * Remove circle
   */
  remvoeCircle(index: number): any {
    this.regionArray.removeAt(index);
  }

  //second form
  initBillingInfo() {
    this.userInfoForm = this.formBuilder.group({
      users: this.formBuilder.group({
        firstName: ["", Validators.required],
        lastName: ["", Validators.required],
        company: [this.registerData.data.company_name, []],
        address: [
          this.registerData.address.addressOne,
          Validators.required,
        ],
        city: [this.registerData.address.city, Validators.required],

        state: [this.registerData.address.state, Validators.required],
        zipcode: [this.registerData.address.zip_code, Validators.required],
        country: ["", Validators.required],

        phone: [
          this.registerData.data.phone_number,
          [
            Validators.required,
          ],
        ],
        regionCode: [
          "", 
          [Validators.required] // Add validation if necessary
        ],
        email: [
          this.registerData.email,
          [Validators.required, Validators.email],
        ],
      }),
    });
    setTimeout(() => {
      this.googleAddress("autocomplete1");
      this.googleAddress("autocomplete2");
    }, 2000);
  }
  backInintSecondForm() {
    this.initBillingInfo();
    this.authorizeStep = "event-step-b-2";
    this.userInfoForm.patchValue(this.userInfoObj);
  }

  // Signup form init
  signupFormInitialization(): void {
    this.getScreeningTaxo();
    this.signUpForm = this.fb.group({
      username: [
        "",
        // this.commonService.setCustomValidatorMethods({ isNoWhitespaceValidation: true, minLength: 2, maxLength: 60 })
        [Validators.required]
      ],
      image: this.fb.group({
        type: ["avatar", []],
        name: [this.selectedAvatar, [Validators.required]],
      }),
      role: [UserRole.COMPANY_ADMIN, Validators.required],
      status: [CommonStatus.PENDING, []],
      eventStatus: [CommonStatus.PENDING, []],
      data: this.fb.group({
        company_name: [
          "",
          // this.commonService.setCustomValidatorMethods({ isTextPattern: true, minLength: 2, maxLength: 200 })
          [Validators.required]
        ],
        contact: [
          "",
          [Validators.required]
          // this.commonService.setCustomValidatorMethods({ isTextPattern: true, minLength: 2, maxLength: 100 })
        ],

        phone_number: [
          "",
          [
            Validators.required
          ],
        ],
        regionCode: [
          "", 
          [Validators.required] // Add validation if necessary
        ],
        website: ["", [Validators.required]
        // this.commonService.setCustomValidatorMethods({ isUrlPattern: true })
      ],
        checkbox: [null, [Validators.required]],
      }),
      password: [
        "",[Validators.required]
        // this.commonService.setCustomValidatorMethods({ isPasswordPattern: true, minLength: 6, maxLength: 25 })

      ],
      email: [
        "",
        this.commonService.setCustomValidatorMethods({ isEmailPattern: true })

      ],
      address: this.fb.group({
        // formatted_address: ['', Validators.required],
        formatted_address: [""],
        state: ["", Validators.required],
        city: ["", Validators.required],
        zip_code: [
          "",
          [
            Validators.required,
            // Validators.minLength(4),
            // Validators.maxLength(12),
            // Validators.pattern(/^[0-9a-zA-Z]+$/),
          ],
        ],

        //zip_code: ['', [Validators.required, Validators.minLength(4),
        // Validators.pattern(new RegExp('([A-Z]{1}[0-9]{1}){3}'))]],
        lat: [, []],
        lng: [, []],
        loc: [, []],

        addressOne: ["", Validators.required]
      }),
      recaptcha: ["", Validators.required],

      //  recaptcha2: ['', Validators.required]
    });
  }

  /**
   * This function is used to get screening taxo list.
   */
  getScreeningTaxo(): void {
    forkJoin([
      //
      this.taxonomyService.getTaxonomyList({
        filter: {
          where: {
            status: 1,
            taxonomyTypeId: this.globals.taxonomyTypesConst.industryId,
          },
        },
      }),
      this.taxonomyService.getTaxonomyList({
        filter: {
          where: {
            status: 1,
            taxonomyTypeId: this.globals.taxonomyTypesConst.screeningId,
          },
        },
      }),
    ]).subscribe(
      (results) => {
        // this.screeningParentList = results[0] ? results[0] : [];

        // TODO:- hidning IndustriesList we are not using this time 
        
        // this.industriesList = results[0] ? results[0] : [];
        this.screeningList = results[1] ? results[1] : [];
        this.screeningList.forEach(item => {
          item.formattedName = this.buildFormattedName(item, this.screeningList);
      });
        this.screeningList.sort((a, b) => {
          return a.formattedName.localeCompare(b.formattedName);
      });
        
      },
      () => { }
    );
  }

  buildFormattedName(item, allItems) {
    if (!item.parentId) {
        return item.name;
    }
    const parent = allItems.find(parentItem => parentItem.id === item.parentId);
    if (!parent) {
        return item.name;
    }
    const parentName = this.buildFormattedName(parent, allItems);
    return `${parentName} > ${item.name}`;
}

  screeningCheckboxChange(values: any) {
    //  console.log(values.currentTarget.checked);
    if (values.currentTarget.checked == true) {
      // this.showScreeingList=true
      this.signUpForm.value.screenRequired = 1;
    } else if (values.currentTarget.checked == false) {
      this.signUpForm.value.screenRequired = 0;
      //  this.showScreeingList=false
      //  console.log(values.currentTarget.checked);
    }
  }

  handleLoad() { }
  handleSuccess() { }
  get sf() {
    return this.signUpForm.controls;
  }

  get addressForm() {
    const addForm = this.signUpForm.get("address") as FormGroup;
    return addForm.controls;
  }

  get dataForm() {
    return this.signupDataFG.controls;
  }

  get addRegionGroup() {
    return this.fb.group({
      name: [],
      lat: [],
      long: [],
      miles: [],
      meters: [],
    }) as FormGroup;
  }

  get addIndustryGroup() {
    return this.fb.group({ objectId1: [], objectId2: [] }) as FormGroup;
  }

  // Get Industry array from form Group
  get industryArray() {
    return this.signUpForm.get("industry") as FormArray;
  }

  // Get Industry array from form Group
  get screeningArray() {
    return this.signUpForm.get("screening") as FormArray;
  }
  onImageSelect(event) {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageSrc = event.target.result;
        this.uploader.uploadAll();
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  get signupFormImage() {
    return this.signUpForm.get("image")
  }

  get signupFormImageName() {
    return this.signupFormImage.get("name")
  }

  /**
   * login user in portal
   */
  signUpSubmit(): void {
    if(this.userRole.value === UserRole.EXHIBITOR){
          this.website.clearValidators()
          this.website.updateValueAndValidity()
    }
    if (this.signUpForm.invalid) {
      this.fAttributes.submitted = true;
      return;
    }
    this.sf.username.setValue(this.sf.username.value.trim())

    if (this.signupFormShow == 1) {
      this.signUpForm.patchValue({
        image: { name: "defaultAvatar.png", type: "avatar" },
      });
    } else {
      if (this.selectedAvatar && this.selectedAvatar != "") {
        this.signUpForm.patchValue({
          image: { name: this.selectedAvatar, type: "avatar" },
        });
      }
    }

    let curUrl = this.router.url.split("/");

    if (curUrl && curUrl[2] == "attend") {
      this.signUpForm.patchValue({ role: UserRole.EXHIBITOR });

    } else if (curUrl && curUrl[2] == "host") {
      this.signUpForm.patchValue({ role: UserRole.COMPANY_ADMIN });
    }
    if (this.signUpForm.valid) {
      const loginObj = Object.assign({}, this.signUpForm.value);

      loginObj.address.loc = [loginObj.address.lng, loginObj.address.lat];
      if (
        this.signUpForm.value.address.lat == null ||
        this.signUpForm.value == ""
      ) {
        this.notifier.notify(SystemConstants.ERROR_TEXT, this.translatorJson.INVALID_ADDRESS_ERROR);
        return;
      }
      this.loginService.addMember(loginObj).subscribe(
        (res) => {
          //  console.log("amanandsfdsfds", res);
          ///email start code
          let temEmail: any = {};
          temEmail.userId = res.id;
          temEmail.slug = "register-exhibitor-template";
          //  temEmail.subject = 'Your Event Approved';
          //   temEmail.keys = { userName: res };
          temEmail.keys = {
            userName: loginObj.username,
            password: loginObj.password,
            email: loginObj.email,
            companyName:loginObj && loginObj.data && loginObj.data.company_name
          };
          this.emailSends(temEmail);

          this.fAttributes.submitted = false;
          if (res.role === 20) {
            //  console.log("I am in..........");
            this.authorizeStep = "event-step-b-1";
            this.notifier.notify(this.translatorJson.SUCCESS,this.translatorJson.ACCOUNT_MUST_BE_APPROVED);
            this.firstFormInit();
            this.registerData = res;
          } else {
            this.notifier.notify("success", "SignUp Successful");
            this.router.navigateByUrl("/login");
          }
          ///email end code

          this.signUpForm.reset();
        },
        (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            this.notifier.notify("error", err.error.error.message);
          } else if (
            err &&
            err.error &&
            err.error.error &&
            err.error.error.message
          ) {
            if (err.error.error.message.message != "") {
              this.notifier.notify("error", err.error.error.message.message);
            } else {
              this.notifier.notify(
                "error",
                err.error.error.details.path + err.error.error.details.message
              );
            }
          }
        }
      );
    }
    window.scroll(-9, -5);
  }

  // Google autocomplete address for address field
  onSearchAddressForRegion(event) {
    event.preventDefault();
    const autocompleteFrom = new google.maps.places.Autocomplete(
      <HTMLInputElement>event.srcElement,
      {
        types: ["geocode"],
      }
    );

    google.maps.event.addListener(autocompleteFrom, "place_changed", () => {
      this.ngZone.run(() => {
        // to get the place result
        let place: google.maps.places.PlaceResult = autocompleteFrom.getPlace();
        // to verify result from map
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        // to set latitude, longitude and zoom
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.getAddress(this.latitude, this.longitude);
        this.zoom = 7;
      });
    });
  }
  skipRegistration() {
    this.notifier.notify("success", "SignUp Successful!");
    this.router.navigateByUrl("/login");
  }

  backInintFirstForm() {
    // console.log(this.savedMember);
    // console.log(this.userInfoObj);

    this.firstFormInit();
    this.authorizeStep = "event-step-b-1";
    this.authorizeForm.patchValue(this.savedMember);
    setTimeout(() => {
      const cardNumberInput = document.querySelector('.card-nmr') as HTMLElement;
      if (cardNumberInput) {
        const event = new Event('input');
        cardNumberInput.dispatchEvent(event); 
      }
    })
  }

  // get power() { return this.userInfoForm.controls; }
  paymentSend() {
    if (this.authorizeForm.valid) {
      // console.log("I m in..22");
      const authorizeObj: any = Object.assign({}, this.authorizeForm.value);
      this.savedMember = authorizeObj;
      this.authorizeStep = "event-step-b-2";
      this.initBillingInfo();

      if (this.userInfoObj) {
       this.userInfoForm.patchValue(this.userInfoObj);
        // console.log(this.savedMember);
        // console.log(this.userInfoObj);
      }
      this.selectedRegionCode =  this.registerData?.data?.regionCode
    } else {
      this.paymentSubmitted = true;
    }
  }
  //get formControls() { return this.authorizeForm.controls; }
  userInfoSubmitForm() {
    if(this.phone.value){
      this.phone.setErrors(null);
      this.phone.updateValueAndValidity()
      }
    if (this.userInfoForm.valid) {
      const infObj: any = Object.assign({}, this.userInfoForm.value);
      this.userInfoObj = infObj;
      this.authorizeStep = "event-step-b-2";
      //  this.initBillingInfo();
      this.thirdFormInit();
      if (this.userInfoObj) {
        this.userInfoForm.patchValue(this.userInfoObj);
        //this.finalFormObj = finalObj;
        console.log(this.savedMember);
      }
    } else {
      this.userBillingSubmitted = true;
    }
  }
  thirdFormInit() {
    this.step3form = this.formBuilder.group({
      orderDesc: this.formBuilder.group({
        description: [""],
      }),
    });
  }
  finalSubmitForm() {
    if (this.step3form.valid) {
      const finalObj: any = Object.assign({}, this.step3form.value);
      if (finalObj) {
        this.finalFormObj = finalObj;
        const firstFormVal: any = Object.assign({}, this.savedMember.card);
        const secondFormVal: any = Object.assign({}, this.userInfoObj.users);
        const finalFormVal: any = Object.assign(
          {},
          this.finalFormObj.orderDesc
        );
        const sendObj: any = {
          userName: this.registerData.username,
          userId: this.registerData.id,
          userEmail: this.registerData.email,
          cardNumber: firstFormVal.cardNumber.replace(/ /g, ""),
          cardExpiry: firstFormVal.expireAt
            .replace(/\//g, "")
            .replace(/ /g, ""),
          //  cardExpiry: "0922",
          cardCvv: firstFormVal.cvv.replace(/ /g, ""),
          prodDesc: finalFormVal.description,
          firstName: secondFormVal.firstName,
          lastName: secondFormVal.lastName,
          totalPayment: firstFormVal.amount,

          address: {
            address: secondFormVal.address,
            city: secondFormVal.city,
            state: secondFormVal.state,
            zip: secondFormVal.zipcode,
            country: secondFormVal.country,
          },
          prodList: this.prodList,
          phone: secondFormVal.phone,
          fullName: firstFormVal.fullName,
          orderDescription: finalFormVal.description,
        };

        this.exhibitorService.registerPayment(sendObj).subscribe(
          (data) => {
            // console.log(data);
            if (data.status == 400) {
              this.notifier.notify("error", data.desc);
            } else if (data.id != "") {
              //console.log(data);
              //  this.toggleForm();
              this.step3form.reset();
              this.userInfoForm.reset();
              this.authorizeForm.reset();
              //email start
              let temEmail: any = {};
              temEmail.userId = this.registerData.id;
              temEmail.slug = "register-payment-template";
              temEmail.subject = "Registration Payment";

              temEmail.keys = {
                USERNAME: this.registerData.username,
                transactionId: data.transId,
                invoiceNumber: data.invoiceNumber,
                amount: firstFormVal.amount,
                companyName:this.registerData && this.registerData.data && this.registerData.data.company_name
              };
              this.emailSends(temEmail);
              //email end
              this.notifier.notify("success", "Your Payment is Successful.");
              this.router.navigateByUrl("/login");
            }
            // this.finalformgSubmitted = false;
            // Push new object on zero index
            // this.wAttributes.pageItems.splice(0, 0, data);
            // this.wAttributes.count += 1;
          },
          (err) => {
            this.notifier.notify("error", err.error.error.message);
            //   this.notifier.notify('error', err.error.error.message);

            //  console.log(err);
          }
        );
        /**/
        // console.log(this.savedMember);
        // console.log(this.userInfoObj);
        // console.log(this.finalFormObj);
      }
    } else {
      this.finalformgSubmitted = true;
    }
  }

  finalPaymentSubmitFormWithUserInfo(){
    forkJoin([
      this.userInfoSubmitForm(),
      this.finalSubmitForm()
    ]);
  }
  //email send
  emailSends(sendObj: any) {
    this.loginService.sendEmailRegister(sendObj).subscribe(
      (data) => {
        console.log("email send success");
        console.log(data);
      },
      (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          //this.notifier.notify('error', err.error.error.message);
        }
      }
    );
  }

  handleExpire() { }
  handleReset() { }

  // to get data group from main form
  get signupDataFG() {
    return this.signUpForm.get("data") as FormGroup;
  }

  // to corporate user init at tab click
  corporateUserInit(): void {
    this.signupFormShow = 1;
    this.isExibitor = false;
    this.signUpForm.reset(this.signUpForm.value);
    this.signUpForm.patchValue({
      role: UserRole.COMPANY_ADMIN,
    });
    this.signupDataFG.addControl("number_employee", new FormControl('', 
    // this.commonService.setCustomValidatorMethods({ isNumericPattern: true })
    [Validators.required]
    ));
    this.signUpForm.removeControl("region");
    this.signupDataFG.addControl("title", new FormControl('', [Validators.required]
    // this.commonService.setCustomValidatorMethods({ isTextPattern: true, minLength: 2, maxLength: 100 })
    ));
  }
  exhibitorUserInit(): void {
    this.signupFormShow = 2;
    this.isExibitor = true;
    this.signUpForm.reset(this.signUpForm.value);
    this.signUpForm.clearValidators()
    this.signUpForm.updateValueAndValidity()
    this.addMoreFieldsInData();
  }

  //  add more fileds to data group as per required by exibitor form
  addMoreFieldsInData(): void {
    this.isExibitor = true;
    this.signUpForm.patchValue({
      role: UserRole.EXHIBITOR,
    });

    // TODO :- Hiding the medical and insurance form control

    // this.signupDataFG.addControl("medical", new FormControl("0"));
    // this.signupDataFG.addControl("insurance", new FormControl(""));

    // TODO:- hidning Industries we are not using this time 

    // this.signUpForm.addControl(
    //   "industry",
    //   new FormControl([], Validators.required)
    // );
    this.signUpForm.addControl("screening", new FormControl([],Validators.required));
    this.signUpForm.addControl("screenRequired", new FormControl(false));

    //todo: we are not using them at this time but we can use in future

    // this.signupDataFG.addControl(
    //   "attendevent",
    //   new FormControl("", this.commonService.setCustomValidatorMethods({ disableRequired: true, minLength: 2, maxLength: 400 }))
    // );

    // this.signupDataFG.addControl("infoFair", new FormControl("", this.commonService.setCustomValidatorMethods({ disableRequired: true, minLength: 2, maxLength: 600 })));
    // this.signupDataFG.addControl("offered", new FormControl("", this.commonService.setCustomValidatorMethods({ disableRequired: true, minLength: 2, maxLength: 1000 })));
    this.signupDataFG.removeControl("number_employee");
    this.signupDataFG.removeControl("title");
    this.signUpForm.addControl("region", this.fb.array([], Validators.required));
    this.signupDataFG.addControl(
      "reminder",
      this.fb.array([1, 7, 14, 28])
    );
    //this.googleAddress('autocomplete1');
    setTimeout(() => {
      this.googleAddress("autocomplete1");
    }, 2000);
  }

  get getRemainderArray() {
    return this.signupDataFG.get("reminder") as FormArray;
  }

  // Get Region array from Form Group
  get regionArray() {
    return this.signUpForm.get("region") as FormArray;
  }

  signUpSubmitForExi() {
    this.signUpSubmit();
    window.scroll(0, 0);
  }

  /*
   * add medical value to data
   */
  onRadioBoxChange(option, event) {
    if (event.target.checked) {
      this.signUpForm.patchValue({
        data: { medical: option.value },
      });
    }
    this.isMedical = !this.isMedical;
  }

  onImageTypeChange(option) {
    if (option) {
      this.isAvatar = option.type == "avatar" ? true : false;
      this.signUpForm.patchValue({
        image: { type: option.type },
      });
    }
  }

  /*
   * Push Default reminder value in array
   */
  onCheckboxChange(option, event) {
    if (this.getRemainderArray) {
      if (event.target.checked) {
        this.getRemainderArray.push(new FormControl(option.id));
      } else {
        for (var i = 0; i < this.getRemainderArray.length; i++) {
          if (
            this.getRemainderArray &&
            this.getRemainderArray.at(i).value == option.id
          ) {
            this.getRemainderArray.removeAt(i);
          }
        }
      }
    }
  }

  /*
   * Google Address
   */
  googleAddress(id: string) {
    const autocompleteFrom = new google.maps.places.Autocomplete(
      <HTMLInputElement>document.getElementById(id),
      {
        types: ["geocode"],
      }
    );
    google.maps.event.addListener(autocompleteFrom, "place_changed", () => {
      const place = autocompleteFrom.getPlace();
      if (place.address_components && place.address_components.length) {
        if (place.formatted_address) {
          this.signUpForm.patchValue({
            address: { addressOne: place.formatted_address },
          });
        }
        for (let i = 0; i < place.address_components.length; i++) {
          this.signUpForm.patchValue({
            address: { lat: place.geometry.location.lat() },
          });

          this.signUpForm.patchValue({
            address: { lng: place.geometry.location.lng() },
          });
          this.signUpForm.patchValue({
            location: [
              place.geometry.location.lng(),
              place.geometry.location.lat(),
            ],
          });

          let addressType = place.address_components[i].types[0];
          if (addressType === "administrative_area_level_2") {
            this.signUpForm.patchValue({
              address: { city: place.address_components[i].long_name },
            });
          }
          if (addressType === "administrative_area_level_1") {
            this.signUpForm.patchValue({
              address: { state: place.address_components[i].long_name },
            });
          }
          if (addressType === "postal_code") {
            this.signUpForm.patchValue({
              address: { zip_code: place.address_components[i].long_name },
            });
          }
        }
      }
    });
  }
  searchTaxo(term: string, item: any) {
    // console.log(term,item);
    // let strSearchLow = term.toLowerCase();
    // let objectLowerCase = item.name.toLowerCase();
    // var n = objectLowerCase.startsWith(strSearchLow);
    // if (n && n == true) {
    //   return item;
    // }
    let strSearchLow = term.toLowerCase();
    let nameLowerCase = item.name.toLowerCase();
    let formattedNameLowerCase = item.formattedName.toLowerCase();
    
    // Check if either the item's name or its formatted name contains the search term
    if (nameLowerCase.includes(strSearchLow) || formattedNameLowerCase.includes(strSearchLow)) {
        return true;
    } else {
        return false;
    }
  }
  goToLink(url: string) {
    window.open(url, "_blank");
  }

  patchDataForLocFormGroup(locFormGroupData: { location: number[] }): void {
    this.signUpForm.patchValue(locFormGroupData);
  }

  getValidationErrors(options: ErrorsValidatorOptions): string {
    return this.commonService.getErrorMessage(options);
  }

  toggleImageChange(): void {
    if (this.isAvatar) {
      this.signupFormImageName.patchValue(this.selectedAvatar);
    } else {
      if (this.imgUrl) {
        this.signupFormImageName.patchValue(this.imgUrl);
      } else {
        this.signupFormImageName.patchValue('');
      }
    }
  }

  resetSelectAvatar(): void {
    if (this.isAvatar) {
      this.isAvatar = false;
      this.selectedAvatar = '';
    } else {
      this.isAvatar = true;
      this.selectedAvatar = this.defaultExhibitorProfileImage;
    }
  }

  selectAvatar(avatarName: string): void {
    this.selectedAvatar = avatarName;
    this.signupFormImageName.patchValue(this.selectedAvatar);
  }
  get phoneNumberGroup(){
    return this.userInfoForm.get('users') as FormGroup
  }
  get data() {
    return this.phoneNumberGroup.controls;
  }
  get companyName(){
    return this.data.company
  }
  get userAddress(){
    return this.data.address
  }
 get phone(){
  return this.data.phone
 }
 get regionCode(){
  return this.data.regionCode
 }
 get userRole(){
  return this.signUpForm.controls['role']
 }
 get dataInfo(){
 return this.signUpForm.get('data') as FormGroup
}

 get userInfo(){
  return this.dataInfo.controls
 }
 get website(){
  return this.userInfo.website
 }

}
