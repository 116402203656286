import { Component, Injector, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { LoginService } from '../login/login.service';
import { AppComponent } from '../app.component';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
// Import utility method class
import { Utility } from '../utility';
// Handle the global property
import { NotifierService } from 'angular-notifier';
import { ProfileService } from '../profile/profile.service';
import { TaxonomyService } from '../taxonomy/taxonomy.service';
import { Globals } from '../globals';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, RouterModule, NavigationStart, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { GetRouterLinkService } from '../services/getrouter-link.service';
import { CommonService } from "../services/common.service";
import { ErrorsValidatorOptions } from "../interface/common-validators/common-validators.interface";
import * as TranslatorJson from "./../../assets/i18n/en.json";
@Component({
  selector: 'app-find-exhibitors',
  templateUrl: './find-exhibitors.component.html',
  styleUrls: ['./find-exhibitors.component.scss']
})
export class FindExhibitorsComponent implements OnInit {

  userData: any;
  loginUserRole: any = null;
  showAdvance = false;
  exhibAttributes: any = {};
  math = Math;
  modalRef: BsModalRef;
  exhiBitorContactStatus = false;
  contactForm: FormGroup;
  notifier: NotifierService;
  findExhibSearchForm: FormGroup;
  findExhibAdvanceSearchForm: FormGroup;
  industriesList: any = [];
  cityList: any = [];
  stateList: any = [];
  private value: any = {};
  imageSrc = '';
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  eventSubmit: any = {};
  isSubmitted: boolean;
  translatorJson: typeof TranslatorJson = Object.assign({}, (TranslatorJson as any).default);
  screeningParentList:any = []
  constructor(
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private appComponent: AppComponent,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalService: BsModalService,
    public globals: Globals,
    private profileService: ProfileService,
    private loginService: LoginService,
    private injector: Injector,
    private taxonomyService: TaxonomyService,
    private utility: Utility,
    private commonService: CommonService) { this.notifier = notifierService; }


  ngOnInit() {

    // form init

    this.contactForm = this.fb.group({
      eventName: ['', [Validators.required]
      // this.commonService.setCustomValidatorMethods({ isNoWhitespaceValidation: true, minLength: 2, maxLength: 60 })
    ],
      subject: ['', [Validators.required]
      //  this.commonService.setCustomValidatorMethods({ isNoWhitespaceValidation: true, minLength: 2, maxLength: 60 })
      ],

      emailId: ['', [Validators.required]],
      message: ['',  [Validators.required]
      // this.commonService.setCustomValidatorMethods({ isNoWhitespaceValidation: true })
    ]

    });



    // This  is widget  seach form
    this.findExhibSearchForm = this.fb.group({
      username: ['', []]
    });
    // This  is widget  seach form
    this.findExhibAdvanceSearchForm = this.fb.group({
      industry: new FormControl(null, []),
      company_name: ["",  [Validators.required]
      // this.commonService.setCustomValidatorMethods({ isNoWhitespaceValidation: true, minLength: 2, maxLength: 60 })
    ],
      health: ['', []],
      workshop: ['', []],
      screening: ['', []],
      services: ['', []],
      state: ['', []],
      city: ['', []]
    });

    // Copy object
    this.exhibAttributes = Object.assign({}, this.globals.adminPaginationAttrs);


    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.userData = this.appComponent.userData;
    //   this.loginUserRole = this.userData.role;
    //   if (this.loginUserRole === 10) {
    //     this.exhibitorMultipLeRequest();
    //   }
    //   this.getScreeningTaxo();
    // } else {
    //   this.router.navigateByUrl("/");
    // }


    this.authService.isLoggedIn.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.userData = this.appComponent.userData;
        this.loginUserRole = this.userData.role;
        if (this.loginUserRole === 10) {
          this.exhibitorMultipLeRequest();
          this.getScreeningTaxo();
        }else{
          this.router.navigateByUrl('/home');
        }
      } else {
        this.router.navigateByUrl('/');
      }
    }, err => {
      console.log(err);
    });

    // console.log(this.exhibAttributes)

  }
  get companyName() {
    return this.findExhibAdvanceSearchForm.get("company_name");

  }
  getValidationErrors(options: ErrorsValidatorOptions): string {
    return this.commonService.getErrorMessage(options);
  }

  get eventName() {
    return this.contactForm.get("eventName") ;
  }
  get subject() {
    return this.contactForm.get("subject") ;
  }
  get message() {
    return this.contactForm.get("message") ;
  }



  /**
   * Multi request for Exhibitor.
   */
  exhibitorMultipLeRequest(): void {
    forkJoin([this.getExhibitorList(this.exhibAttributes.limits, this.exhibAttributes.skip), this.getExhibitorCount()]);
  }

  /**
   * This method is used for search widget
   */
  exhibitorSearch() {
    if (this.findExhibSearchForm.valid) {
      this.exhibitorMultipLeRequest();
    }
  }
  exhibitorSearchAdvance(limitNum: number, skipNum: number) {
    if (this.findExhibAdvanceSearchForm.invalid) {

      this.isSubmitted = true;
      return;
    }
    this.isSubmitted = false;
    const queryObj: any = {
      limit: this.exhibAttributes.limits,
      skip: this.exhibAttributes.skip,
    };

    if (this.findExhibAdvanceSearchForm && this.findExhibAdvanceSearchForm.value && this.findExhibAdvanceSearchForm.value.city) {
      queryObj.city = this.findExhibAdvanceSearchForm.value.city;
    }
    if (this.findExhibAdvanceSearchForm && this.findExhibAdvanceSearchForm.value && this.findExhibAdvanceSearchForm.value.state) {
      queryObj.state = this.findExhibAdvanceSearchForm.value.state;
    }
    if (this.findExhibAdvanceSearchForm && this.findExhibAdvanceSearchForm.value && this.findExhibAdvanceSearchForm.value.company_name) {
      queryObj.company_name = this.findExhibAdvanceSearchForm.value.company_name
    }
    if (this.findExhibAdvanceSearchForm && this.findExhibAdvanceSearchForm.value && this.findExhibAdvanceSearchForm.value.industry) {
      queryObj.industry = this.findExhibAdvanceSearchForm.value.industry;
    }
    if (this.findExhibAdvanceSearchForm && this.findExhibAdvanceSearchForm.value && this.findExhibAdvanceSearchForm.value.health) {
      queryObj.health = this.findExhibAdvanceSearchForm.value.health;
    }
    if (this.findExhibAdvanceSearchForm && this.findExhibAdvanceSearchForm.value && this.findExhibAdvanceSearchForm.value.workshop) {
      queryObj.workshop = this.findExhibAdvanceSearchForm.value.workshop;
    }


    this.loginService.findUserListAdvance(queryObj)
      .subscribe(data => {
        this.exhibAttributes.pageItems = (data && data.length) ? data : [];
        // this.exhibAttributes.count = (data && data.length) ? data.length : 0;
      }, (err) => {
        this.exhibAttributes.pageItems = [];
        // this.exhibAttributes.count = 0;
      });
  }
  exhibitorSearchAdvanceCount() {
    // console.log("Aaya to sahi");
    if (this.findExhibAdvanceSearchForm.valid) {
      const queryObj: any = {};

      // console.log(this.findExhibAdvanceSearchForm.value);

      // This statement for serch user  list

      if (this.findExhibAdvanceSearchForm && this.findExhibAdvanceSearchForm.value && this.findExhibAdvanceSearchForm.value.city) {
        queryObj.city = this.findExhibAdvanceSearchForm.value.city;
      }
      if (this.findExhibAdvanceSearchForm && this.findExhibAdvanceSearchForm.value && this.findExhibAdvanceSearchForm.value.state) {
        queryObj.state = this.findExhibAdvanceSearchForm.value.state;
      }
      if (this.findExhibAdvanceSearchForm && this.findExhibAdvanceSearchForm.value && this.findExhibAdvanceSearchForm.value.company_name) {
        queryObj.company_name = this.findExhibAdvanceSearchForm.value.company_name
      }
      if (this.findExhibAdvanceSearchForm && this.findExhibAdvanceSearchForm.value && this.findExhibAdvanceSearchForm.value.industry) {
        queryObj.industry = this.findExhibAdvanceSearchForm.value.industry;
      }
      if (this.findExhibAdvanceSearchForm && this.findExhibAdvanceSearchForm.value && this.findExhibAdvanceSearchForm.value.health) {
        queryObj.health = this.findExhibAdvanceSearchForm.value.health;
      }
      if (this.findExhibAdvanceSearchForm && this.findExhibAdvanceSearchForm.value && this.findExhibAdvanceSearchForm.value.workshop) {
        queryObj.workshop = this.findExhibAdvanceSearchForm.value.workshop;
      }
      this.loginService.findUserListAdvanceCount(queryObj)
        .subscribe(data => {
          //  this.exhibAttributes.pageItems = (data && data.length) ? data : [];
          this.exhibAttributes.count = data;
        }, (err) => {
          //   this.exhibAttributes.pageItems = [];
          this.exhibAttributes.count = 0;
        });
    }
  }

  // Get Exhibitor List
  getExhibitorList(limitNum: number, skipNum: number): void {


    const queryObj: any = {

      where: {
        status: 1,
        role: 20,
      },
      limit: limitNum,
      skip: skipNum,
      order: 'created DESC'

    };

    const longitudes = this.userData.address.lng ? this.userData.address.lng : -120.740135;
    const latitudes = this.userData.address.lat ? this.userData.address.lat : 47.751076;
    //  console.log(this.userData);
    //     queryObj['region'] = [75.748863, 26.904776];
    //   console.log(longitudes, latitudes);
    queryObj['region'] = [longitudes, latitudes];

    // This statement for serch user  list
    if (this.findExhibSearchForm && this.findExhibSearchForm.value && this.findExhibSearchForm.value.username) {
      queryObj.username = this.findExhibSearchForm.value.username;

    }

    //this.userData

    // queryObj['region'] = [75.748863, 26.904776];

    this.loginService.findUserListAdvance(queryObj)
      .subscribe(data => {
        this.exhibAttributes.pageItems = (data && data.length) ? data : [];
       // this.exhibAttributes.count = (data && data.length) ? data.length : 0;
        // this.findEventsBySearchText();

      }, (err) => {
        console.log(err);
        if (err.status == 422) {
          this.notifier.notify('error', "Please select address in my account section for better experience.");
        }
        this.exhibAttributes.pageItems = [];
        this.exhibAttributes.count = 0;
      });

  }

  // findEventsBySearchText() {
  //   this.exhibAttributes.pageItems = this.exhibAttributes.pageItems.filter((x) => {
  //     return x.username.startsWith(this.findExhibSearchForm.value.username.toLowerCase());
  //   })
  // }
  /**
   * Get Exhibitor counts
   */
  getExhibitorCount(): void {
    const queryObj: any = {

      where: {
        status: 1,
        role: 20,
      },


    };

    const longitudes = this.userData.address.lng ? this.userData.address.lng : -120.740135;
    const latitudes = this.userData.address.lat ? this.userData.address.lat : 47.751076;
    //  console.log(this.userData);
    //     queryObj['region'] = [75.748863, 26.904776];
    //   console.log(longitudes, latitudes);
    queryObj['region'] = [longitudes, latitudes];

    // This statement for serch user  list
    if (this.findExhibSearchForm && this.findExhibSearchForm.value && this.findExhibSearchForm.value.username) {
      queryObj.username = this.findExhibSearchForm.value.username;

    }

    this.loginService.findUserListAdvanceCount(queryObj)
      .subscribe(data => {
        this.exhibAttributes.count = data;
      }, (err) => {
        this.exhibAttributes.count = 0;
      });
  }

  /**
   * This function used to submit the contact form.
   */
  addMessage(): void {

    if (this.contactForm.invalid) {
      this.isSubmitted = true;
      return;
    }
    this.isSubmitted = false;
    const contactObj: any = this.contactForm.value;
    this.eventSubmit.submitted = false;
    let mszObj: any = {};
    mszObj.receiverEmail = this.exhibAttributes.itemArray.email;
    mszObj.senderEmail = contactObj.emailId;
    let mszOf: any = {
      text: '<p>' + contactObj.eventName + '</P>' + '<pre>' + contactObj.message + '<pre>',
      email: contactObj.emailId
    };
    mszObj.mszSenderUpdateStatus = 0;
    mszObj.mszReceiverUpdteStatus = 1;
    mszObj.status = 1;
    mszObj.subject = contactObj.subject;
    mszObj.msz = [mszOf];
    mszObj.senderId = this.userData.id;
    mszObj.receiverId = this.exhibAttributes.itemArray._id;
    if (this.exhibAttributes.itemArray) {
      this.sendMszToProfile(mszObj);

    }
  }


  /**
  * This function is used to get screening taxo list.
  */
  getScreeningTaxo(): void {
    forkJoin([
      this.taxonomyService.getTaxonomyList({ filter: { where: { status: 1, taxonomyTypeId: this.globals.taxonomyTypesConst.screeningId } }}),
      this.taxonomyService.getTaxonomyList({ filter: { where: { status: 1, taxonomyTypeId: this.globals.taxonomyTypesConst.industryId } } }),
      this.taxonomyService.getStateList({ where: {} }),
      this.taxonomyService.getCity({ where: {} })
    ]).subscribe(results => {
      this.screeningParentList = results[0] ? results[0] : [];
      this.industriesList = results[0] ? results[0] : [];
      this.stateList = results[2] ? results[2] : [];
      this.cityList = results[3] ? results[3] : [];
    }, (err) => { });
  }


  public selected(value: any): void {
    // alert(1);
    // console.log('Selected value is: ', value);
  }

  public removed(value: any): void {
    //  alert(2);
    // console.log('Removed value is: ', value);
  }

  public typed(value: any): void {
    //alert(3);
    //  console.log('New search input: ', value);
  }

  public refreshValue(value: any): void {
    // alert(4);
    this.value = value;
    //  console.log('In refresh: ', value);
  }


  /**
   * Close Popup
   */

  closPopup(): void {
    const localModalRef = this.modalRef;
    localModalRef.hide();
  }
  // Open model box to contact for exhibitor
  openRequestPopUp(content: any, data: any): any {
    // console.log(data);
    this.exhibAttributes.itemArray = data;

    this.modalRef = this.modalService.show(content, Object.assign({}, { class: 'modal-lg contact-to-exhibitor-pop' }));
    if (this.contactForm.controls['emailId']) {
      this.contactForm.controls['emailId'].setValue(this.userData.email);
    }
  }

  getMember(index: number): void {
    setTimeout(() => {
      this.exhibAttributes.itemArray = this.exhibAttributes.pageItems[index];
    }, 0);
  }

  /**
   * This method is used for pagination
   * @param event
   */
   pageChangedRecord(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    this.exhibAttributes.isIndex = -1
    this.getExhibitorList(this.exhibAttributes.limits, startItem);

  }

  distance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") { dist = dist * 1.609344 }
      if (unit == "N") { dist = dist * 0.8684 }
      return dist;
    }
  }

  /**
   * This function is used to send message
   */
  sendMszToProfile(qObj: any) {
    this.profileService.addProfileMessage(qObj).subscribe(res => {
      this.notifier.notify('success', 'Message send Successful.');
      this.closPopup();
      this.contactForm.reset();
    }, err => {
      if (err && err.error && err.error.error && err.error.error.message) {
        this.notifier.notify('error', err.error.error.message);
      }
    });
  }
  getServices(serviceId: string): string {
    let selectedService: string
    this.screeningParentList.forEach((value) => {
      if (value._id == serviceId) {
        selectedService = value.name;
      }
    });
    if (selectedService) return selectedService
  }

}
