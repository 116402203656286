import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

export interface TimezoneOption {
  value: {
    name: string;
    label: string;
  };
  label: string;
  offset: number;
}

@Injectable({
  providedIn: 'root',
})
export class USTimezoneService {
  private readonly US_TIMEZONES: { [key: string]: string } = {
    'America/New_York': 'Eastern Standard Time',
    'America/Chicago': 'Central Standard Time',
    'America/Denver': 'Mountain Standard Time',
    'America/Los_Angeles': 'Pacific Standard Time',
    'America/Anchorage': 'Alaska Standard Time',
    'Pacific/Honolulu': 'Hawaii-Aleutian Standard Time',
  };

  constructor() {}

  // Get only US-specific timezones
  getUSTimezones(): TimezoneOption[] {
    return Object.entries(this.US_TIMEZONES).map(([tz, label]) => this.createTimezoneOption(tz, label));
  }

  // Get all timezones formatted consistently
  getAllTimezones(): TimezoneOption[] {
    return moment.tz.names().map((tz) => {
      const label = this.US_TIMEZONES[tz] || tz; // Use predefined label if available, otherwise use timezone name
      return this.createTimezoneOption(tz, label);
    });
  }

  // Helper to create a formatted TimezoneOption
  private createTimezoneOption(tz: string, label: string): TimezoneOption {
    const offset = moment.tz(tz).utcOffset();
    return {
      value: { name: tz, label },
      label: `(GMT${this.formatOffset(offset)}) ${label}`,
      offset,
    };
  }

  // Format the offset as ±HH:mm
  private formatOffset(offset: number): string {
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    return `${offset >= 0 ? '+' : '-'}${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  }

  getCurrentTimezone(): TimezoneOption | undefined {
    const currentTz = moment.tz.guess();
    return this.getAllTimezones().find(tz => tz.value.name === currentTz);
  }
}
