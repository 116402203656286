import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { PhoneNumberUtil, PhoneNumberFormat, PhoneNumber } from 'google-libphonenumber';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { SystemConstants } from 'src/app/constants/system.constants';
@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit {
  @ViewChild('phoneNumber') phoneNumber: ElementRef;
  @Input() phoneNumberFormGroup: FormGroup;
  @Input() phoneNumberFormControlName: string;
  @Input() regionCodeFormControlName: string; // NEW: Input for region code control
  @Input() selectedRegionCode: string;


  @Input() phoneCodePanelClass = ""

  regionsString = PhoneNumberUtil.getInstance().getSupportedRegions();
  phoneNumberFormat = '';
  selectCountryCode = '';
  phoneNumberMaxLength: number;
  defaultPhoneNumber = SystemConstants.DEFAULT_COUNTRY;
  isValidNumber:boolean;
  isRegionSelected = false;
  length: number = 2;
  phoneValue: string;
  maxLength: number = 0;
  constructor() { }

  ngOnInit(): void {
    if(this.selectedRegionCode){
      this.defaultPhoneNumber = this.selectedRegionCode
    }
    this.setDefaultRegionCode(this.defaultPhoneNumber)
    // this.selectPhoneNumberExampleUsingCountryRegionCode(this.defaultPhoneNumber);
    this.setPhoneNumberAccordingToSelectedCountry()
    this.setCustomValidators();
  }  

  ngOnChanges(){
    if(this.selectedRegionCode){
      this.defaultPhoneNumber = this.selectedRegionCode
      this.setDefaultRegionCode(this.defaultPhoneNumber)
      this.setPhoneNumberAccordingToSelectedCountry()
      this.setCustomValidators();
    }
  } 
  get phone(): AbstractControl {
    return this.phoneNumberFormGroup.get(this.phoneNumberFormControlName);
  }
  get regionCode(): AbstractControl {
    return this.phoneNumberFormGroup.get(this.regionCodeFormControlName)!;
  }

  selectValue(regionCode: string): void {
    this.regionCode.setValue(regionCode);
    this.selectPhoneNumberExampleUsingCountryRegionCode(regionCode);
    this.phone.reset();
    this.phoneValue  = ''
  }
  private setDefaultRegionCode(regionCode: string): void {
    this.selectCountryCode = regionCode;
    this.regionCode.setValue(regionCode); // Set the default region code in the form group
    this.selectPhoneNumberExampleUsingCountryRegionCode(regionCode);
  }

  private selectPhoneNumberExampleUsingCountryRegionCode(regionCode: string): void {
    this.selectCountryCode = regionCode;
    if (regionCode) {
      const phoneNumberUtileInstance = PhoneNumberUtil.getInstance();
      const number = phoneNumberUtileInstance.getExampleNumber(regionCode);
      this.phoneNumberMaxLength = number.getNationalNumberOrDefault().toString().length;
      this.phoneNumberFormat = phoneNumberUtileInstance.format(number, PhoneNumberFormat.NATIONAL);
      this.maxLength = this.phoneNumberFormat.length
      if (this.phoneNumber && this.phoneNumber.nativeElement) {
        const phNumber = this.phoneNumber.nativeElement as HTMLInputElement
        phNumber.disabled = false;
      }
    }

  }

 
  setPhoneNumberAccordingToSelectedCountry(){
    this.phone.valueChanges.subscribe((value)=>{
    if (value) {
      this.maxLength = this.phoneNumberFormat.length
      if(this.length >= 2 && value.toString().length > 2){
      let inputValue = value.toString()
      const phoneNumberUtileInstance = PhoneNumberUtil.getInstance();
      const parsedNumber = phoneNumberUtileInstance.parse(inputValue, this.selectCountryCode);
      const formattedValue = phoneNumberUtileInstance.format(parsedNumber, PhoneNumberFormat.NATIONAL);
      this.phoneValue = formattedValue;
      this.isValidNumber = phoneNumberUtileInstance.isValidNumber(parsedNumber);
   }  
  }
    })
  }

  reset() {
    if (!this.selectCountryCode) {
      this.defaultPhoneNumber = '';
        this.phoneNumberFormat = '';
      const phNumber = this.phoneNumber.nativeElement as HTMLInputElement
      this.phone.reset();
      phNumber.value = ''
      phNumber.disabled = true;
    }
  }
   validatePhoneNumberInput() {
    const phoneNumberInput = this.phoneNumber.nativeElement as HTMLInputElement;
    let onlyNumbers = phoneNumberInput.value.replace(/[^\d-()+]/g, '');
    if (onlyNumbers.startsWith('-')) {
      onlyNumbers = onlyNumbers.substr(1);
    }
    if (phoneNumberInput.value !== onlyNumbers) {
      phoneNumberInput.value = onlyNumbers;
      this.phoneValue= onlyNumbers; 
    }
  }

  getMaximumLengthValidationOnTypeValue(event: HTMLInputElement): boolean {
    this.length = event.value.length
    if (event.value.length > (this.phoneNumberFormat && this.phoneNumberFormat.length)) {
      return false;
    }   
    if( this.phoneValue && event.value.length > 2){
      this.phone.updateValueAndValidity()
      this.phone.setValue(this.phoneValue)
      }
    return true;
  }


  private invalidNumber(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.value) {
        return of(null);
      }
      if(this.phoneNumber){
      const phoneNumberInput = this.phoneNumber.nativeElement as HTMLInputElement;
      let onlyNumbers = phoneNumberInput.value.replace(/[^\d-()+]/g, '');
      if (onlyNumbers.startsWith('0')) {
        return  !this.isValidNumber ? of({ invalidNumber: true }) : of(null)
      }else{
      return control.value.toString().length !== (this.phoneNumberFormat && this.phoneNumberFormat.length ? this.phoneNumberFormat.length : this.phoneNumberMaxLength)  ? of({ invalidNumber: true }) : of(null);
      }
    }else{
      return  of(null)
    }
  };
  }

  private setCustomValidators(): void {
    this.phone.setValidators(Validators.required)
    this.phone.setAsyncValidators(this.invalidNumber())
    this.phone.updateValueAndValidity();
  }

}
