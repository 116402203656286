import { Component, OnInit, NgZone, Injector } from '@angular/core';
import { SharedService } from '../shared.service';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from '../services/auth.service';
import { LoginService } from '../login/login.service';
import { ProfileService } from '../profile/profile.service';
import { AppComponent } from '../app.component';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { TaxonomyService } from '../taxonomy/taxonomy.service';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';

// Import utility method class
import { Utility } from '../utility';
import { FileUploader } from 'ng2-file-upload';
// Handle the global property
import { NotifierService } from 'angular-notifier';
import { Globals } from '../globals';
import { Router, RouterModule, NavigationStart, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { EventService } from '../event/event.service';
import * as TranslatorJson from "./../../assets/i18n/en.json";
import { GetRouterLinkService } from '../services/getrouter-link.service';
import { AboutComponent } from '../about/about.component';
import { CredentialByAdmin } from '../client-schema';
import * as CryptoJS from "crypto-js";
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  checkboxdata: any;
  isCollapsed: boolean = true;
  userData: any;
  userSlug: string = '';
  userRole: any = null;
  loginUserRole: any = null;
  tabName = 'profile';
  mszData: any = [];
  passwordForm: FormGroup;
  submitted = false;
  notifier: NotifierService;
  mszSingleItm: any = {};
  totalMszCounts = 0;
  tabStatus: number = 0;
  tabactive = true;
  total = 0;
  centerRadius = 16094;
  imageSrc = '';
  allPage: any = [];
  eventPaymenIndex: Array<any> = [];
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  signUpForm: FormGroup;
  replyForm: FormGroup;
  uAttributes: any = {};
  adminApprovedCount = 0;
  industriesList: any = [];
  screeningParentList: any = [];
  showScreeingList: boolean = false;
  loginUser: any;

  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  showInd = 0;
  private geoCoder;
  miles: number = 10;
  onReplyStatus = false;
  eventPaymentCheckOutList: any = [];
  searchStatus: number = 0;
  isFormSubmited:boolean=false;
  countsForPaymet: any = {
    paidCount: 0,
    unPaidCount: 0,
  };
  translatorJson: typeof TranslatorJson;
  eventSearchForm: FormGroup;
  defaultValue = [{
    id: 1,
    value: 'Day before of the event'
  }, {
    id: 7,
    value: 'One week before the event'
  }, {
    id: 14,
    value: 'Two week before the event'
  }, {
    id: 28,
    value: 'Four week before the event'
  }];

  // medicalExhibitor: any = [
  //   { name: 'Yes', value: '1' },
  //   { name: 'No', value: '0' }
  // ];

  paymentListData: any = [];
  public uploader: FileUploader = new FileUploader({
    url: environment.apiUrl + '/containers/upload?folderName=users&fileType=image'
  });
  selectedRegionCode: any;


  constructor(
    private sharedService: SharedService,
    private fb: FormBuilder,
    private notifierService: NotifierService,
    private appComponent: AppComponent,
    private router: Router,
    private injector: Injector,
    private route: ActivatedRoute,
    private authService: AuthService,
    public globals: Globals,
    private loginService: LoginService,
    private profileService: ProfileService,
    private utility: Utility,
    private ngZone: NgZone,
    private taxonomyService: TaxonomyService,
    private eventService: EventService,

  ) {
    this.notifier = notifierService;
    this.translatorJson = Object.assign({}, (TranslatorJson as any).default);



  }

  ngOnInit() {



    this.paymentListData = Object.assign({}, this.globals.adminPaginationAttrs);

    this.passwordForm = this.fb.group({
      oPassword: ['', [Validators.required 
        // Validators.minLength(6)
      ]],
      // password: ['', [Validators.required, Validators.minLength(6)]],
      password: ['', [Validators.required //, Validators.minLength(6),//Validators.pattern(new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$'))
      ]],
      cPassword: ['', Validators.required],
      checkbox1: ['',]
    });


    this.replyForm = this.fb.group({
      message: ['', [Validators.required]]
    });
    // user edit form for coroporate and exhibitor




    // Copy object
    this.uAttributes = Object.assign({}, this.globals.adminPaginationAttrs);


    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.appComponent)) {
    //   this.eventSearchForm = this.fb.group({
    //     eventName: ['', []]
    //   });
    //   this.userData = this.appComponent.userData;
    //   this.loginUserRole = this.appComponent.role;
    //   this.uAttributes.itemArray = this.userData;
    //   if (this.userData.role === 20) {
    //     this.signUpForm = this.fb.group({
    //       username: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
    //       industry: [[], []],
    //       region: this.fb.array([]),
    //       data: this.fb.group({
    //         number_employee: [0, [Validators.pattern(new RegExp('^[0-9]*$'))]],
    //         company_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
    //         contact: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
    //         phone_number: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(14),
    //         Validators.pattern(/^\(?(\d{3})\)?[- ()]?(\d{3})[-]?(\d{4})$/)]],

    //         //    title: ['User', [Validators.required,Validators.minLength(2), Validators.maxLength(250)]],
    //         website: ['', []]
    //       }),
    //       email: ['', [Validators.required,
    //         // Validators.pattern(new RegExp('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{1,}'))
    //       ]
    //       ],
    //       address: this.fb.group({
    //         formatted_address: ['',],
    //         state: ['', Validators.required],
    //         city: ['', Validators.required],
    //         zip_code: ['', []],
    //         lat: [, []],
    //         lng: [, []],
    //         loc: [, []],
    //         addressOne: ['', Validators.required],
    //       }),
    //     });
    //     this.signUpForm.patchValue({
    //       data: { title: "User" }
    //     });
    //     if (this.uAttributes.itemArray.region && this.uAttributes.itemArray.region.length) {
    //       this.latitude = this.uAttributes.itemArray.region[0].lat ? this.uAttributes.itemArray.region[0].lat : 47.751076;
    //       this.longitude = this.uAttributes.itemArray.region[0].long ? this.uAttributes.itemArray.region[0].long : -120.740135;

    //     } else {
    //       this.latitude = 47.751076;
    //       this.longitude = -120.740135;
    //     }

    //     this.zoom = 8;
    //     this.geoCoder = new google.maps.Geocoder;


    //     this.addMoreFieldsInExibitiorForm();
    //     this.getScreeningTaxo();
    //     setTimeout(() => {
    //       this.signUpForm.patchValue(this.uAttributes.itemArray);
    //       if (this.uAttributes.itemArray.screening.length > 0) {
    //         this.showScreeingList = true;
    //       }

    //       this.getAllCounts();
    //       if (this.userData.role == 20 && (this.userData.region.length == 0 || this.userData.region[0].loc[0] == 0)) {
    //         setTimeout(() => {
    //           this.notifier.notify('error', 'Please select the watch regions on the map to receive notification of events in selected areas. You can change the watch region at any time!');
    //         }, 1000);

    //       }
    //     }, 0);



    //   } else {
    //     this.signUpForm = this.fb.group({
    //       username: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
    //       industry: [[], []],
    //       region: this.fb.array([]),
    //       data: this.fb.group({
    //         number_employee: [0, [Validators.pattern(new RegExp('^[0-9]*$'))]],
    //         company_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
    //         contact: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
    //         phone_number: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(14),
    //         Validators.pattern(/^\(?(\d{3})\)?[- ()]?(\d{3})[-]?(\d{4})$/)
    //           //  Validators.pattern('[0-9]\\d{9}')
    //         ]],

    //         title: ['User', [Validators.required, Validators.minLength(2), Validators.maxLength(250)]],
    //         website: ['', []]
    //       }),
    //       email: ['', [Validators.required,
    //         // Validators.pattern(new RegExp('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{1,}'))
    //       ]],
    //       address: this.fb.group({
    //         formatted_address: ['',],
    //         state: ['', Validators.required],
    //         city: ['', Validators.required],
    //         zip_code: ['', []],
    //         lat: [, []],
    //         lng: [, []],
    //         loc: [, []],
    //         addressOne: ['', Validators.required],
    //       }),
    //     });
    //     if (this.uAttributes.itemArray.address && !this.uAttributes.itemArray.address.loc) {
    //       this.uAttributes.itemArray.address.loc = [this.uAttributes.itemArray.address.lng, this.uAttributes.itemArray.address.lat];
    //     }
    //     // console.log(this.uAttributes.itemArray);
    //     this.signUpForm.patchValue(this.uAttributes.itemArray);
    //   }
    //   this.getProfileData();
    // } else {
    //   this.router.navigateByUrl("/");
    // }

    this.authService.isLoggedIn.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.eventSearchForm = this.fb.group({
          eventName: ['', []]
        });
        this.userData = this.appComponent.userData;
        this.loginUserRole = this.userData.role;
        this.authService.masqueradeUserRoleObservable.subscribe((masqueradeRole) => {
          if (masqueradeRole) {
            this.loginUserRole = masqueradeRole;
          }
        })
        console.log(this.loginUserRole)
        this.uAttributes.itemArray = this.userData;
        if (this.loginUserRole === 20) {
          this.signUpForm = this.fb.group({
            username: ['', [Validators.required]],

            // TODO:- hidning Industries we are not using this time 

            // industry: [[], []],
            region: this.fb.array([]),
            data: this.fb.group({
              number_employee: [0, [Validators.required]],
              company_name: ['', [Validators.required]],
              contact: ['', [Validators.required]],
              phone_number: ['', [Validators.required]],
              regionCode: [
                "", 
                [Validators.required] // Add validation if necessary
              ],

              //    title: ['User', [Validators.required,Validators.minLength(2), Validators.maxLength(250)]],
              website: ['', []]
            }),
            email: ['', [Validators.required,,Validators.email]
            ],
            address: this.fb.group({
              formatted_address: ['',],
              state: ['', Validators.required],
              city: ['', Validators.required],
              zip_code: ['', []],
              lat: [, []],
              lng: [, []],
              loc: [, []],
              addressOne: ['', Validators.required],
            }),
          });
          this.signUpForm.patchValue({
            data: { title: "User" }
          });
          if (this.uAttributes.itemArray.region && this.uAttributes.itemArray.region.length) {
            this.latitude = this.uAttributes.itemArray.region[0].lat ? this.uAttributes.itemArray.region[0].lat : 47.751076;
            this.longitude = this.uAttributes.itemArray.region[0].long ? this.uAttributes.itemArray.region[0].long : -120.740135;

          } else {
            this.latitude = 47.751076;
            this.longitude = -120.740135;
          }

          this.zoom = 7;
          this.geoCoder = new google.maps.Geocoder;


          this.addMoreFieldsInExibitiorForm();
          this.getScreeningTaxo();
          setTimeout(() => {
            this.signUpForm.patchValue(this.uAttributes.itemArray);
            this.regionCode.patchValue(this.uAttributes?.itemArray?.data?.regionCode)
            this.selectedRegionCode =  this.uAttributes?.itemArray?.data?.regionCode
            // console.log(this.selectedRegionCode)
            this._patchReminderArray()
            if (this.uAttributes.itemArray.screening.length > 0) {
              this.showScreeingList = true;
            }

            this.getAllCounts();
            if (this.loginUserRole == 20 && (this.userData.region.length == 0 || this.userData.region[0].loc[0] == 0)) {
              setTimeout(() => {
                this.notifier.notify('error', 'Please select the watch regions on the map to receive notification of events in selected areas. You can change the watch region at any time!');
              }, 1000);

            }
          }, 0);



        } else {
          this.signUpForm = this.fb.group({
            username: ['', [Validators.required]],

            // TODO:- hidning Industries we are not using this time 
            
            // industry: [[], []],
            region: this.fb.array([]),
            data: this.fb.group({
              number_employee: [0, [Validators.pattern]],
              company_name: ['', [Validators.required]],
              contact: ['', [Validators.required]],
              phone_number: ['', [Validators.required]],
              regionCode: [
                "", 
                [Validators.required] // Add validation if necessary
              ],
              title: ['User', [Validators.required]],
              website: ['', []]
            }),
            email: ['', [Validators.required,Validators.email]],
            address: this.fb.group({
              formatted_address: ['',],
              state: ['', Validators.required],
              city: ['', Validators.required],
              zip_code: ['', []],
              lat: [, []],
              lng: [, []],
              loc: [, []],
              addressOne: ['', Validators.required],
            }),
          });
          if (this.uAttributes.itemArray.address && !this.uAttributes.itemArray.address.loc) {
            this.uAttributes.itemArray.address.loc = [this.uAttributes.itemArray.address.lng, this.uAttributes.itemArray.address.lat];
          }
          // console.log(this.uAttributes.itemArray);
          this.signUpForm.patchValue(this.uAttributes.itemArray);
          console.log(this.uAttributes.itemArray)
          this.selectedRegionCode =  this.uAttributes?.itemArray?.data?.regionCode

        }
        this.getProfileData();
      } else {
        this.router.navigateByUrl('/');
      }
    }, err => {
      console.log(err);
    });
    // file upload steps
    this.uploader.onAfterAddingFile = (file: any) => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      const data = JSON.parse(response);

      if (status === 200 && data && data.files && data.files[0] && data.files[0].filename) {

        // this.eventMediaArray.push(new FormControl(data.files[0].filename));
        let img = data.files[0].filename;
        const queryObj: any = { 'image': { 'type': 'image', 'name': img } };

        this.loginService.updateUser(this.userData.id, queryObj)
          .subscribe(data => {
            this.userData.image.type = 'image';
            this.userData.image.name = img;
            this.notifier.notify('success', 'Update Successful!');

          }, (err) => {
            if (err && err.error && err.error.error && err.error.error.message) {
              this.notifier.notify('error', err.error.error.message);
            }
          });



      } else {
        if (data && data.error && data.error.message) {
          this.notifier.notify('error', data.error.message);
        } else {
          this.notifier.notify('error', 'Something went wrong!');
        }
      }
    };

  }
  clearInputMethod() { this.passwordForm.setValue({ oPassword: '', password: '', cPassword: '', checkbox1: '' }); }
  get sf() {
    return this.passwordForm.controls;
  }

  get screeningArray() {
    return this.signUpForm.get('screening') as FormArray;
  }
  screeningCheckboxChange(values: any) {
    //  console.log(values.currentTarget.checked);
    if (values.currentTarget.checked == true) {
      this.showScreeingList = true

    }
    else if (values.currentTarget.checked == false) {
      this.showScreeingList = false
      //  console.log(values.currentTarget.checked);
    }
  }

  getScreeningTaxo(): void {

    // TODO:- hidning Industries we are not using this time 
    
    // this.signUpForm.addControl('industry', new FormControl([], Validators.required));
    this.signUpForm.addControl('screening', new FormControl([],Validators.required));
    this.signUpForm.addControl('screenRequired', new FormControl(''));

    forkJoin([
      this.taxonomyService.getTaxonomyList({ filter: { where: { status: 1, taxonomyTypeId: this.globals.taxonomyTypesConst.screeningId } } }),
      // this.taxonomyService.getTaxonomyList({ filter: { where: { status: 1, taxonomyTypeId: this.globals.taxonomyTypesConst.industryId } } })
    ]).subscribe(results => {
      this.screeningParentList = results[0] ? results[0] : [];
      this.screeningParentList.forEach(item => {
        item.formattedName = this.buildFormattedName(item, this.screeningParentList);
    });
    this.screeningParentList.sort((a, b) => {
      return a.formattedName.localeCompare(b.formattedName);
  });

      // TODO:- hidning Industries List we are not using this time 

      // this.industriesList = results[1] ? results[1] : [];

      this.showInd = 1

    }, (err) => { });
  }

  buildFormattedName(item, allItems) {
    if (!item.parentId) {
        return item.name;
    }
    const parent = allItems.find(parentItem => parentItem.id === item.parentId);
    if (!parent) {
        return item.name;
    }
    const parentName = this.buildFormattedName(parent, allItems);
    return `${parentName} > ${item.name}`;
}

  editIndex(id: string) {

    this.signUpForm.patchValue(this.uAttributes.itemArray);

    // this.eventSearchForm = this.fb.group({
    //   eventName: ['', []]
    // });


    // this.userData = this.comp.userData;
    // this.loginUserRole = this.userData.role;
    // this.uAttributes.itemArray = this.userData;
    // if (this.userData.role === 20) {
    //   this.addMoreFieldsInExibitiorForm();
    //   this.signUpForm.patchValue(this.uAttributes.itemArray);
    //   this.getAllCounts();
    // } else {
    //   this.signUpForm.patchValue(this.uAttributes.itemArray);
    // }
    // this.getProfileData();


  }
  //hidden button and show button
  // toggleCollapse(){
  //   this.isCollapsed = !this.isCollapsed;
  // }
  // Cancel Button


  //on image upload
  onImageSelect(event) {
    if (event && event.target && event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageSrc = event.target.result;
        this.uploader.uploadAll();
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }


  // Get Industry array from form Group
  get getAddress() {
    return this.signUpForm.get('address') as FormGroup;
  }

  get addRegionGroup() {
    return this.fb.group({ name: [], lat: [], long: [], miles: [], meters: [] }) as FormGroup;
  }

  // Get Region array from Form Group
  get regionArray() {
    return this.signUpForm.get('region') as FormArray;
  }

  centerChanged($event: any, indexed: number) {

    // this.latitude = $event.coords.lat;
    // this.longitude = $event.coords.lng;
    // console.log("i am in..");
    // console.log($event);
    // console.log(indexed);
    //this.uAttributes.itemArray.region[].push(regionsObj);

    this.uAttributes.itemArray.region[indexed]["centerRadius"] = $event;
    this.uAttributes.itemArray.region[indexed]["miles"] = $event * 0.00062137;
    // this.uAttributes.push(regionsObj);
    // if (this.uAttributes.itemArray && this.uAttributes.itemArray.region) {
    //   this.uAttributes.itemArray.region.push(regionsObj);
    // }

    console.log("================>");
    ///   console.log(this.regionArray);
    console.log("---<");
    // this.getAddress(this.latitude, this.longitude);
  }

  //TODO :- hiding industry group and array
  
  // get addIndustryGroup() {
  //   return this.fb.group({ objectId1: [], objectId2: [] }) as FormGroup;
  // }

  // Get Industry array from form Group

  // TODO :- Hiding industryArray

  // get industryArray() {
  //   return this.signUpForm.get('industry') as FormArray;
  // }

  // to get data group from main form
  get getDataFromSigupForm() {
    return this.signUpForm.get('data') as FormGroup;
  }
  get profileDataForm() {
    return this.getDataFromSigupForm.controls;
  }
  get phone(){
    return this.profileDataForm.phone_number
  }
  get regionCode(){
    return this.profileDataForm.regionCode
  }


  get getRemainderArray() {
    return this.getDataFromSigupForm.get('reminder') as FormArray;
  }


  // add more fileds to exibitor form
  addMoreFieldsInExibitiorForm(): void {

    // TODO :- hindig the medical and insurance form control

    // this.getDataFromSigupForm.addControl('medical', new FormControl('0'));
    // this.getDataFromSigupForm.addControl('insurance', new FormControl('', Validators.required));
    //  this.getDataFromSigupForm.addControl('attendevent', new FormControl('', Validators.required));
    // this.getDataFromSigupForm.addControl('attendevent', new FormControl('', []));
    // this.getDataFromSigupForm.addControl('infoFair', new FormControl('', []));
    // this.getDataFromSigupForm.addControl('offered', new FormControl('', []));
    this.getDataFromSigupForm.addControl('reminder', this.fb.array([1, 7, 14, 28]));
  }

  corporateUserUpdate(id: string): void {
    if(this.phone.status == "PENDING" && this.phone.value){
      this.phone.setErrors(null);
      this.phone.updateValueAndValidity()
      }
      console.log(this.phone)
    if (this.signUpForm.valid) {


      if (this.signUpForm.value.address && this.signUpForm.value.address.loc == null) {
        this.signUpForm.value.address.loc = [0.00, 0.00];
      }
      const queryObj: any = this.signUpForm.value;
      this.loginService.updateUser(id, queryObj)
        .subscribe(data => {
          this.isFormSubmited=false

          this.notifier.notify('success', 'Update Successful!');
          this.uAttributes.isIndex = -1;
          this.getLoginUser();
          // this.sharedService.setSession(data);

          console.log("aaaaaaa", this.uAttributes)
          this.uAttributes.isActiveIdx = -1;
        }, (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            this.notifier.notify('error', err.error.error.message);
          }
        });
    } else {
      //  console.log(this.signUpForm)
      this.uAttributes.submitted = true;
    }
  }

  exibitorUserUpdate(id: string): void {
    if (this.signUpForm.valid) {

      let exibObj = this.signUpForm.value;
      exibObj.region = this.uAttributes.itemArray.region;
      try {
        delete exibObj.data.number_employee;
      } catch (error) { }


      this.loginService.updateUser(id, exibObj)
        .subscribe(data => {
          this.notifier.notify('success', 'Update Successful!');
          //this.getUserById(id);
          //  this.sharedService.setSession(exibObj);
          //this.userRole = exibObj.role;
          //this.getMenu(.role);
          this.getLoginUser();

          this.uAttributes.isIndex = -1;
          this.uAttributes.isActiveIdx = -1;

        }, (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            this.notifier.notify('error', err.error.error.message);
          }
        });
    } else {
      console.log(this.signUpForm)
      this.uAttributes.submitted = true;
    }
  }
  searchTaxo(term: string, item: any) {
    // console.log(term,item);
    // let strSearchLow = term.toLowerCase();
    // let objectLowerCase = item.name.toLowerCase();
    // var n = objectLowerCase.startsWith(strSearchLow);
    // if (n && n == true) {
    //   return item;
    // }
    let strSearchLow = term.toLowerCase();
    let nameLowerCase = item.name.toLowerCase();
    let formattedNameLowerCase = item.formattedName.toLowerCase();
    
    // Check if either the item's name or its formatted name contains the search term
    if (nameLowerCase.includes(strSearchLow) || formattedNameLowerCase.includes(strSearchLow)) {
        return true;
    } else {
        return false;
    }
  }

  getLoginUser() {
    this.loginService.getCurrentUser().subscribe(user => {
      this.loginUser = user;
      this.sharedService.setSession(user);
      this.userRole = this.loginUser.role;
      if(this.loginUser?.data?.regionCode){
        this.regionCode.patchValue(this.loginUser.data.regionCode)
        this.selectedRegionCode = this.loginUser.data.regionCode
      }
      // this.getMenu(this.loginUser.role);
    }, err => {
      console.log(err);
    });
  }

  /*
  * add medical value to data
  */
  // onRadioBoxChange(option, event) {
  //   if (event.target.checked) {
  //     this.signUpForm.patchValue({
  //       data: { medical: option.value }
  //     });
  //   }
  // }
  //Reset Button in Exhibitor
  resetForm(id: string) {
    this.signUpForm.patchValue(this.uAttributes.itemArray);
    console.log("abc", this.uAttributes)
  }


  /*
   * Push Default reminder value in array
   */
  onCheckboxChange(option, event) {
    if (this.getRemainderArray) {
      if (event.target.checked) {
        this.getRemainderArray.push((new FormControl(option.id)));
      } else {
        for (var i = 0; i < this.getRemainderArray.length; i++) {
          if (this.getRemainderArray && this.getRemainderArray.at(i).value == option.id) {
            this.getRemainderArray.removeAt(i);
          }
        }
      }
    }
  }


  // Google autocomplete address for address field
  onAddreessChange(event) {
    event.preventDefault();
    const autocompleteFrom = new google.maps.places.Autocomplete(<HTMLInputElement>event.srcElement, {
      types: ['geocode']
    });
    google.maps.event.addListener(autocompleteFrom, 'place_changed', () => {

      const place = autocompleteFrom.getPlace();
      if (place.address_components && place.address_components.length) {
        if (place.formatted_address) {
          this.signUpForm.patchValue({
            address: { addressOne: place.formatted_address }
          });
        }
        for (let i = 0; i < place.address_components.length; i++) {
          this.signUpForm.patchValue({
            address: { lat: place.geometry.location.lat() }
          });

          this.signUpForm.patchValue({
            address: { lng: place.geometry.location.lng() }
          });
          this.signUpForm.patchValue({
            address: { loc: [place.geometry.location.lng(), place.geometry.location.lat()] }
          });
          this.signUpForm.patchValue({
            'loc': [place.geometry.location.lng(), place.geometry.location.lat()]
          });

          let addressType = place.address_components[i].types[0];
          if (addressType === 'administrative_area_level_2') {
            this.signUpForm.patchValue({
              address: { city: place.address_components[i].long_name }
            });
          }
          if (addressType === 'administrative_area_level_1') {
            this.signUpForm.patchValue({
              address: { state: place.address_components[i].long_name }
            });
          }
          if (addressType === 'postal_code') {

            this.signUpForm.patchValue({
              address: { zip_code: place.address_components[i].long_name }
            });
          }
        }
      }
    });

  }

  getAddressAccordingLatLng(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        //    console.log("john doe",results);
        var regionsObj = {
          name: results[0].formatted_address,
          lat: latitude,
          long: longitude,
          loc: [longitude, latitude],
          miles: this.miles,
          meters: this.miles * 1609.34,
          centerRadius: 16094
        };

        if (this.uAttributes.itemArray && this.uAttributes.itemArray.region) {
          this.uAttributes.itemArray.region.push(regionsObj);
        }
      }
    });
  }
  // remvoeCircle(index: number): any {
  //   this.regionArray.removeAt(index);
  // }
  /*
    * Remove circle
   */
  remvoeCircle(index: number): any {
    this.uAttributes.itemArray.region.pop(index);
  }

  // serach address for region

  // Google autocomplete address for address field
  onSearchAddressForRegion(event) {
    event.preventDefault();
    const autocompleteFrom = new google.maps.places.Autocomplete(<HTMLInputElement>event.srcElement, {
      types: ['geocode']
    });

    google.maps.event.addListener(autocompleteFrom, 'place_changed', () => {
      this.ngZone.run(() => {
        // to get the place result
        let place: google.maps.places.PlaceResult = autocompleteFrom.getPlace();
        // to verify result from map
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        // to set latitude, longitude and zoom
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.getAddressAccordingLatLng(this.latitude, this.longitude);
        this.zoom = 7;
      });
    });

  }

  passwordFormSubmit() {

    if (this.passwordForm.valid) {

      const passwordObj = Object.assign({}, this.passwordForm.value);
      // delete passwordObj.cPassword;
      if (passwordObj.cPassword != passwordObj.password) {
        // alert("Password do not match")
        this.notifier.notify("error", "password not match");
        return
      }


      this.loginService.updatePassword(passwordObj).subscribe(res => {
        console.log(res);
        if (this.passwordForm.value.checkbox1 && this.passwordForm.value.checkbox1 == true) {
          let temEmail: any = {};
          temEmail.userId = this.userData.id,
            temEmail.slug = 'Change-password template';

          temEmail.keys = {

            username: this.signUpForm.value.username,
            oldpwd: this.passwordForm.value.oPassword,
            newpwd: this.passwordForm.value.password,
            companyName: this.signUpForm.value && this.signUpForm.value.data && this.signUpForm.value.data.company_name

          }
          this.emailSends(temEmail);
        }

        this.notifier.notify('success', 'Successful changed pasword!');
        this.passwordForm.reset();

        //this.tabName ='profile'


      }, err => {
        if (err && err.error && err.error.error && err.error.error.message) {
          this.notifier.notify('error', err.error.error.message);
        }
      });
    } else {
      this.submitted = true;
    }
  }


  emailSends(sendObj: any) {
    this.eventService.sendEmail(sendObj)
      .subscribe(data => {
        console.log('email send success');
        // console.log(data);
      }, (err) => {
        console.log(err);
        if (err && err.error && err.error.error && err.error.error.message) {
          //this.notifier.notify('error', err.error.error.message);
        }
      });

  }

  /**
   * This function is used to get message
   */
  getMeszForProfile() {
    forkJoin([this.getUnreadMessageCount(), this.mszProfile()]);
  }

  mszProfile(): void {
    const qObj: any = {
      receiverId: this.userData.id,
      mszReceiverUpdteStatus: {
        inq: [0, 1]
      },
    };
    /*   const qObj: any = {
        where: {
          receiverId: this.userData.id,
          mszReceiverUpdteStatus: { inq: [1, 0] }
        }
      };*/

    this.profileService.getProfileMsz(qObj).subscribe(res => {
      this.mszData = res;
    }, err => {
      if (err && err.error && err.error.error && err.error.error.message) {
        this.notifier.notify('error', err.error.error.message);
      }
    });
  }

  mszSingleItem(index: number) {
    this.mszSingleItm = this.mszData[index];
    if (this.mszSingleItm && this.mszSingleItm.mszReceiverUpdteStatus === 1) {
      this.updateEventById(this.mszSingleItm.id, index);
    }
  }

  mszRemove(index: number) {
    console.log("aaa");
    this.mszSingleItm = this.mszData[index];
    this.removeMessageById(this.mszSingleItm.id, index);
    // if (this.mszSingleItm && this.mszSingleItm.mszReceiverUpdteStatus === 1) {

    // }
  }

  removeMessageById(id: string, index: number) {
    const queryObj = {
      mszReceiverUpdteStatus: 2
    };
    this.profileService.updateMessage(id, queryObj)
      .subscribe(data => {
        this.mszData.splice(index, 1);
        // this.mszData[index].mszReceiverUpdteStatus = 0;
        // this.mszSingleItm.mszReceiverUpdteStatus = 0;
      }, (err) => { });

  }
  /**
   * This function is used to update message via id .
   */

  updateEventById(id: string, index: number) {
    const queryObj = {
      mszReceiverUpdteStatus: 0
    };
    this.profileService.updateMessage(id, queryObj)
      .subscribe(data => {
        this.mszData[index].mszReceiverUpdteStatus = 0;
        this.mszSingleItm.mszReceiverUpdteStatus = 0;
        this.totalMszCounts -= 1;

        this.sharedService.setMsg(this.totalMszCounts);
      }, (err) => { });

  }

  /**
   * This function is used to read count for unread messages.
   */

  getUnreadMessageCount() {
    const queryObj = {
      mszReceiverUpdteStatus: 1,
      receiverId: this.userData.id,
      status: 1
    };
    this.profileService.getProfileMessageCounts(queryObj)
      .subscribe(data => {
        this.totalMszCounts = data.count;
      }, (err) => { });

  }

  /**
   * Multi request for profile.
   */
  getProfileData(): void {
    forkJoin([this.getUnreadMessageCount()]);
  }
  changePayment(values: any, amount: number, item: any, index: number) {
    // console.log(index);

    if (values.currentTarget.checked == true) {
      // this.showScreeingList=true
      //   console.log(values.currentTarget.checked);
      this.total += amount;

      this.eventPaymentCheckOutList.push(item);

    }
    else if (values.currentTarget.checked == false) {
      // console.log("1111111111",this.eventPaymentCheckOutList);
      // this.showScreeingList=false

      this.total -= amount;
      this.eventPaymentCheckOutList.splice(index, 1);

      // this.profileService.removeOption(index);
    }




  }



  /**
   * This function used to submit message reply.
   */
  addMessage(): void {
    if (this.replyForm.valid) {
      const contactObj: any = this.replyForm.value;
      const currentDate = new Date();
      let mszObj: any = {
        msz: this.mszSingleItm.msz
      };
      mszObj.receiverEmail = this.mszSingleItm.senderEmail;
      mszObj.senderEmail = this.userData.email;
      let mszOf: any = {
        text: '<p>' + '</P>' + '<pre>' + contactObj.message + '<pre>',
        email: this.userData.email,
        mszTime: currentDate
      };
      mszObj.mszSenderUpdateStatus = 0;
      mszObj.mszReceiverUpdteStatus = 1;
      mszObj.status = 1;
      mszObj.subject = 'Re: -'
      mszObj.msz.push(mszOf);
      mszObj.senderId = this.userData.id;
      mszObj.receiverId = this.mszSingleItm.senderId;
      if (mszObj && this.mszSingleItm && this.mszSingleItm.id) {
        let updateObj = Object.assign({}, this.mszSingleItm);
        try {
          delete updateObj.id;
        } catch (error) { }
        forkJoin([this.sendMszToProfile(mszObj), this.updateMsz(this.mszSingleItm.id, this.mszSingleItm)]);
      }
    }
  }

  /**
   * This function is used to send message
   */
  sendMszToProfile(qObj: any) {
    this.profileService.addProfileMessage(qObj).subscribe(res => {
      this.notifier.notify('success', 'Message send Successful.');
      this.replyForm.reset();
    }, err => {
      if (err && err.error && err.error.error && err.error.error.message) {
        this.notifier.notify('error', err.error.error.message);
      }
    });
  }

  /**
   * This function is used update  message
   */
  updateMsz(id: any, qObj: any) {
    this.profileService.updateMessage(id, qObj).subscribe(res => {
    }, err => {
      if (err && err.error && err.error.error && err.error.error.message) {
      }
    });
  }


  // Get payment list for Events
  getPaymentList(limitNum: number, skipNum: number, statusPayment: number): void {
    //paymentStatus
    if (statusPayment == 2) { statusPayment = 0; }
    this.tabStatus = statusPayment;
    this.searchStatus = statusPayment;
    this.paymentListData.pageItems = [];
    this.paymentListData.count = this.adminApprovedCount;
    //this.paymentListData.limits = limitNum;
    //
    const queryObjPaymentList: any = {
      memberId: this.userData.id,
      paymentStatus: statusPayment,
      limit: 100,
      skip: skipNum,
      status: 1
    };
    // This statement for serch widget type list
    if (this.eventSearchForm && this.eventSearchForm.value
      && this.eventSearchForm.value.eventName) {
      queryObjPaymentList.eventName = {
        regexp: 'm.*' + this.eventSearchForm.value.eventName + '/mi'
      };
    }

    // console.log("=============>");
    // console.log(queryObjPaymentList);

    //  const queryObj: any = { memberId: this.userData.id, paymentStatus: status };
    this.total = 0;
    this.eventPaymentCheckOutList = [];
    this.profileService.getExhibitorPaymentList(queryObjPaymentList)
      .subscribe(data => {
        this.paymentListData.pageItems = (data && data.length) ? data : [];

        this.paymentListData.pageItems.forEach(element => {
          this.total += element.eventPayment;
          this.eventPaymentCheckOutList.push(element);
        });



      }, (err) => {
        this.paymentListData.pageItems = [];
      });
  }
  // This method is used for search widget
  eventSearch() {
    if (this.eventSearchForm.valid) {
      this.getPaymentList(this.paymentListData.limits, this.paymentListData.skips, this.searchStatus)
    }
  }


  getAllCounts(): void {
    forkJoin([this.getPaymentListCount(1), this.getPaymentListCount(0)]);
  }


  // Get payment list count for Events
  getPaymentListCount(status: number): void {
    //paymentStatus

    this.searchStatus = status;
    this.paymentListData.pageItems = [];
    this.paymentListData.count = this.adminApprovedCount;

    const queryObjects: any = {
      status: 1, memberId: this.userData.id,
      paymentStatus: status
    };
    // console.log(queryObj);
    //  const queryObj: any = { memberId: this.userData.id, paymentStatus: status };
    this.profileService.getPaymentEventListCount(queryObjects)
      .subscribe(data => {
        this.paymentListData.count = (data && data.count) ? data.count : 0;
        if (status == 1) {
          this.countsForPaymet.paidCount = (data && data.count) ? data.count : 0;
        } else if (status == 0) {
          this.countsForPaymet.unPaidCount = (data && data.count) ? data.count : 0;
        }
      }, (err) => {
        this.paymentListData.count = 0;
      });
  }
  // getListAndCounts(): void {
  //   forkJoin([this.getPaymentList(), this.getPaymentListCount(1)]);
  // }
  /**
  * This function is used for pagination
  */
  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * event.itemsPerPage;
    console.log(this.paymentListData.limits);
    this.getPaymentList(this.paymentListData.limits, startItem, this.searchStatus);
    this.getPaymentListCount(this.searchStatus);

  }

  addPaymentForEvent(item: any, index: any): void {
    // console.log(index);
    this.total += item.eventPayment;
    this.eventPaymenIndex.push(index);
    // console.log(this.eventPaymenIndex);
    this.eventPaymentCheckOutList.push(item);
  }

  logout() {
    if(this.loginUserRole === 0){    
    localStorage.clear();
    this.authService.clear();
    this.appComponent.userData = []
    this.router.navigateByUrl('/dashboard');
    this.userRole = -1;
    }else{
      this.backToTheSuperAdminPage()
    }
  }

  backToTheSuperAdminPage() {
    if (localStorage.getItem("Em")) {
      const em = CryptoJS.AES.decrypt(
        localStorage.getItem("Em"),
        "q.[;a27ms.292'xs"
      ).toString(CryptoJS.enc.Utf8);
      let key = "key 4 admin1234";
      let queryObj: CredentialByAdmin = {
        email: CryptoJS.AES.encrypt(em, key).toString(),
        rememberMe: true,
      };
      this.loginService.userLoginByAdmin(queryObj).subscribe(
        (res) => {
          this.authService.masqueradeUserRoleSubject.next(null);
          localStorage.removeItem("RememberMe");
          this.authService.loggedOutSource.next(false);
          this.userRole = -1;
          this.router.navigateByUrl("/dashboard")
        },
        (err) => {
          console.log(err.error);
          if (err && err.error && err.error.error && err.error.error.message) {
            this.notifier.notify("error", err.error.error.message);
          }
        }
      );
    }else{
      this.authService.clear();
      this.authService.clear();
      this.router.navigateByUrl("/");
      this.userRole = -1;
      localStorage.clear();
      this.appComponent.userData = []
    }
  }

  setEventCheckout(): void {
    this.router.navigateByUrl('/exhibitor-event-payment');
    this.profileService.setOption(this.eventPaymentCheckOutList);
  }

  // changebox(values: any, index: number) {
  //   if (values.currentTarget.checked == true) {

  //    this.checkboxdata =this.passwordForm.value
  //     console.log("abcd",  this.passwordForm.value )
  //   }

  // }

  isChecked(id: number): boolean {
    return this.getRemainderArray.value.includes(id);
  }

_patchReminderArray(): void {
   const reminderArray = this.getRemainderArray;
   if (this.uAttributes && this.uAttributes.itemArray && this.uAttributes.itemArray.data && this.uAttributes.itemArray.data.reminder) {
     reminderArray.clear();
    this.uAttributes.itemArray.data.reminder.forEach(reminder => {
      this.getRemainderArray.push(this.fb.control(reminder));
     });
   }
 }

}
