import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { isEmpty } from 'lodash';
import { FileUploader } from 'ng2-file-upload';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SystemConstants } from 'src/app/constants/system.constants';
import { ErrorsValidatorOptions } from 'src/app/interface/common-validators/common-validators.interface';
import { Industries } from 'src/app/interface/industries/industries.interface';
import { ScreeningParent } from 'src/app/interface/screening-parent/screening-parent.interface';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
import * as translateJson from '../../../../../../assets/i18n/en.json'
import { EventService } from 'src/app/event/event.service';
@Component({
  selector: 'app-unregister-event-request-form',
  templateUrl: './unregister-event-request-form.component.html',
  styleUrls: ['./unregister-event-request-form.component.scss']
})
export class UnregisterEventRequestFormComponent implements OnInit {
  @Input() userData = {} as {
    data: any; email: string; id: string;
};
  @Input() eventReqItem: {
    phone: any;
    onsiteContact: string; eventOptionType: string; 
};
  @Input() industriesList: Industries[];
  @Input() screeningParentList: ScreeningParent[];
  @Output() eventSubmitted: EventEmitter<UnregisterEventRequestFormComponent> = new EventEmitter();
  @Input() eventRequestFormData: UnregisterEventRequestFormComponent;
  fileType = SystemConstants.FileMimeType.PDF;
  eventRequestForm: FormGroup
  translateJson: typeof translateJson = Object.assign({}, (translateJson as any).default);
  isElectricity: boolean;
  isSubmitted: boolean;
  isConditionAccepted = true;
  defaultValue = [
    {
      id: 1,
      value: "Day before of the event",
    },
    {
      id: 7,
      value: "One week before the event",
    },
    {
      id: 14,
      value: "Two week before the event",
    },
    {
      id: 28,
      value: "Four week before the event",
    },
  ];
  electricityNeed = [
    { name: "Yes", value: true ,checked:false },
    { name: "No", value: false ,checked:false },
  ];
  public uploader: FileUploader = new FileUploader({
    url: environment.apiUrl + SystemConstants.DOCUMENT_FOLDER_UPLOADER_URL
  });
  exhibitorEventReqDocSrc: string;
  systemConstants: typeof SystemConstants = SystemConstants;
  reminder: [];
  selectedRegionCode: any;
  constructor(
    private _commonService: CommonService,
    private _fb: FormBuilder,
    private _modalService: BsModalService,
    private eventService :EventService,
  ) { }

  ngOnInit(): void {
    this.initEventReqForm();
    this._addMoreFields();
    this._patchEventReqData();
    this._setAttributesForFileUploading();
    this.scrollToFirstInvalidControl()
    if(this.eventRequestFormData?.regionCode){
      this.selectedRegionCode = this.eventRequestFormData.regionCode
    }else{
    this.selectedRegionCode =  this.userData?.data?.regionCode
    }
  }

  initEventReqForm(): void {
    this.eventRequestForm = this._fb.group({
      firstName: [
        "",
        [Validators.required]
        // this._commonService.setCustomValidatorMethods({ isTextPattern: true, minLength: 2, maxLength: 60 })
      ],
      lastName: [
        "", [Validators.required]
        // this._commonService.setCustomValidatorMethods({ isTextPattern: true, minLength: 2, maxLength: 60 })
      ],
      email: [this.userData.email.trim(), [Validators.required]],
      phone: [
        this.userData.data && this.userData.data.phone_number ? this.userData.data.phone_number : "",
        [
          Validators.required,
        ],
      ],
      regionCode: [
        "", 
        [Validators.required] // Add validation if necessary
      ],
      contactName: [
        this.userData.data && this.userData.data.contact ? this.userData.data.contact : "", [Validators.required]
        // this._commonService.setCustomValidatorMethods({ isTextPattern: true, minLength: 2, maxLength: 60 })
      ],
      contactNumber: [this.userData.data && this.userData.data.phone_number ? this.userData.data.phone_number : ""],
      // exhibitorReq: new FormControl(null, Validators.required),
      screenReq: new FormControl(null, Validators.required),
      attendees: this._fb.array([]),
      additionalNeeds: ["", []
      //  this._commonService.setCustomValidatorMethods({ disableRequired: true, minLength: 2, maxLength: 500 })
      ],
      reminder: this._fb.array([1, 7, 14, 28]),
      memberId: [this.userData.id, [Validators.required]],
    });
    // this.electricity = false;
  }

  get reqForm() {
    return this.eventRequestForm.controls;
  }

  getValidationErrors(options: ErrorsValidatorOptions): string {
    return this._commonService.getErrorMessage(options);
  }

  resetUploadedDocument(ElementRef:HTMLInputElement){
    this.exhibitorEventReqDocSrc = ''
    ElementRef.value = ''
    this.eventRequestForm.controls.exhibitorEventDocument.setValue('');
  }

  searchTaxo(term: string, item: any) {
    let strSearchLow = term.toLowerCase();
    let objectLowerCase = item.name.toLowerCase();
    var n = objectLowerCase.startsWith(strSearchLow);
    if (n && n == true) {
      return item;
    }
  }

  get attendees(): FormArray {
    return this.eventRequestForm.controls["attendees"] as FormArray;
  }

  removeOptions(index: number): void {
    this.attendees.removeAt(index);
  }

  createAttendeeItem() {
    let contactName = this.userData && this.userData.data && this.userData.data.contact
    if(this.attendees.controls && this.attendees.controls.length >= 1){
      return this._fb.group({
        name: ["",  [Validators.required]
      ],
        email: ["", [Validators.required]
        ],
      });
    }else{
      return this._fb.group({
        name: [ contactName , [Validators.required]
      ],
        email: [this.userData.email, [Validators.required]
        ],
      });
    }
   
  }

  addMoreAttendee() {
    this.attendees.push(this.createAttendeeItem());
  }

  get getRemainderArray() {
    return this.eventRequestForm.get("reminder") as FormArray;
  }
  get regionCode():AbstractControl {
    return this.eventRequestForm.get("regionCode");

  }
  onCheckboxChange(option, event) {
    if (this.getRemainderArray) {
      if (event.target.checked) {
        this.getRemainderArray.push(new FormControl(option.id));
      } else {
        for (var i = 0; i < this.getRemainderArray.length; i++) {
          if (
            this.getRemainderArray &&
            this.getRemainderArray.at(i).value == option.id
          ) {
            this.getRemainderArray.removeAt(i);
          }
        }
      }
    }
  }
  isChecked(id: number): boolean {
     return this.getRemainderArray.value.includes(id);
   }

 _patchReminderArray(): void {
    const reminderArray = this.getRemainderArray;
  
    if (this.eventRequestFormData.reminder) {
      reminderArray.clear();
     this.eventRequestFormData.reminder.forEach(reminder => {
        reminderArray.push(this._fb.control(reminder));
      });
    }
  }
  closPopup(): void {
    this._modalService.hide();
  }

  onSubmit() {
    if (this.eventRequestForm.invalid) {
      this.isSubmitted = true;
      this.scrollToFirstInvalidControl()
      return
    } else {
      this.isSubmitted = false;
      if (this.eventReqItem.eventOptionType === SystemConstants.VIRTUAL && this.exhibitorEventReqDocSrc) { 
        this.uploader.uploadAll();
      if(this.exhibitorEventDocument && this.exhibitorEventDocument.value){
        this.eventSubmitted.emit(this.eventRequestForm.value);
        this.closPopup()
      }
      } else {
        this.eventSubmitted.emit(this.eventRequestForm.value);
        this.closPopup()
      }
    }
  }

  _patchEventReqData(): void {
    if (!isEmpty(this.eventRequestFormData)) {
      if(this.eventRequestFormData && this.eventRequestFormData.exhibitorEventDocument)this.loadPdf(this.eventRequestFormData.exhibitorEventDocument.toString())
    if(this.eventRequestFormData && this.eventRequestFormData.regionCode){
      this.selectedRegionCode = this.eventRequestFormData.regionCode
      this.regionCode.patchValue(this.eventRequestFormData.regionCode)
    }
    
    if (this.eventRequestFormData && this.eventRequestFormData.attendees && this.eventRequestFormData.attendees.length > 1) {
      const attendeesArray = this.eventRequestFormData.attendees;
      const numAttendees = attendeesArray.length - 1;
      for (let i = 0; i < numAttendees; i++) {
        this.addMoreAttendee();
      }
    }
      this.eventRequestForm.patchValue(this.eventRequestFormData); 
      this.regionCode.patchValue(this.eventRequestFormData?.regionCode)
      this._patchReminderArray()
      if(this.eventReqItem.eventOptionType === SystemConstants.ONSITE){
        this.isElectricity = this.eventRequestForm.get('electricity').value
      }
    }
  }

  private _addMoreFields() {
    if (this.eventReqItem.eventOptionType === SystemConstants.ONSITE) {
      this.eventRequestForm.addControl("numberOfTable", new FormControl('', Validators.required))
      this.eventRequestForm.addControl("electricity", new FormControl(null, Validators.required))
      this.eventRequestForm.addControl("numberOfChairs", new FormControl('', Validators.required));
      this.isElectricity = false;
      // this.eventRequestForm.addControl("raffleItem", new FormControl('',  []
      // this._commonService.setCustomValidatorMethods({ disableRequired: true, minLength: 2, maxLength: 500 })
      // ));
      this.eventRequestForm.addControl("interactiveComponent", new FormControl("",  []
      // this._commonService.setCustomValidatorMethods({ disableRequired: true, minLength: 2, maxLength: 500 })
      ));
    }
    this.eventRequestForm.addControl("isCheckBoxChecked", new FormControl('',[Validators.required]))
    if(this.eventReqItem.eventOptionType === SystemConstants.VIRTUAL){
      this.eventRequestForm.addControl("virtualEventLink", new FormControl('', [Validators.required] 
      // this._commonService.setCustomValidatorMethods({ isUrlPattern: true })
      ))
      this.eventRequestForm.addControl("exhibitorEventDocument", new FormControl(''))
    }
    this.addMoreAttendee();
  }

  onImageSelect(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target && target.files && target.files[0]) {
      this.fileType = target.files[0].type;
      const reader = new FileReader();
      reader.onload = (ev) => {
        this.exhibitorEventReqDocSrc = ev.target.result as string;
        this.eventRequestForm.controls.exhibitorEventDocument.setValue('');
        this.exhibitorEventDocument.clearValidators();
        this.exhibitorEventDocument.updateValueAndValidity();

      };
      reader.readAsDataURL(target.files[0]);
    }
  }

  private _setAttributesForFileUploading(): void {
    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (
      item,
      response,
      status,
      headers
    ) => {
      const data = JSON.parse(response);

      if (
        status === 200 &&
        data &&
        data.files &&
        data.files[0] &&
        data.files[0].filename
      ) {
        this.exhibitorEventDocument.setValue(data.files[0].filename)
        this.eventSubmitted.emit(this.eventRequestForm.value);
        this.closPopup()
        return
      } else {
        if (data && data.error && data.error.message) {
          console.error(data.error.message);
        }
      }
    };
  }


  loadPdf(pdfName:string) {
     this.eventService.getPdf(pdfName).subscribe(
     (response) => {
       const base64String = this.arrayBufferToBase64(response.pdfData.data)
       const dataUrl = `data:application/pdf;base64,${base64String}`;
        this.exhibitorEventReqDocSrc = dataUrl as string;
         this.exhibitorEventDocument.clearValidators();
         this.exhibitorEventDocument.updateValueAndValidity();
     });
   }
  arrayBufferToBase64(buffer: ArrayBuffer): string {
    const binary = Array.prototype.map.call(new Uint8Array(buffer), (byte: number) => String.fromCharCode(byte)).join('');
      return btoa(binary);
  }
  get exhibitorEventDocument() {
    return this.reqForm.exhibitorEventDocument
  }
 scrollToFirstInvalidControl() {
    let form = document.getElementById('formId');
    let invalidControls = form.querySelectorAll('.ng-invalid');

    for (let i = 0; i < invalidControls.length; i++) {
        let control = invalidControls[i];
        if (control.tagName.toLowerCase() !== 'fieldset') {
            control.scrollIntoView();
            (control as HTMLElement).focus();
            break;
        }
    }
}
}
