import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventCommonService {
  selectedEventIndex: any;

  constructor() { }
  public setSelectedEventForAdmin(eventData:any){
    this.selectedEventIndex = eventData;
  }

  public getSelectedEventForAdmin(){
   return this.selectedEventIndex
  }

}
