import { Component, OnInit, NgZone, TemplateRef, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { AuthService } from "../services/auth.service";
import { LoginService } from "../login/login.service";
import { EventService } from "../event/event.service";
import { ProfileService } from "../profile/profile.service";
import { AppComponent } from "../app.component";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { PageChangedEvent } from "ngx-bootstrap/pagination";
import { forkJoin } from "rxjs";
import { ExportService } from "../event-detail/export.service";
import { DatePipe } from "@angular/common";
import * as _ from "lodash";
import * as moment from 'moment-timezone';
//import { ObjectId } from 'bson';
// Import utility method class
import { Utility } from "../utility";
import { FileUploader } from "ng2-file-upload";
// Handle the global property
import { NotifierService } from "angular-notifier";
import { Globals } from "../globals";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { TaxonomyService } from "../taxonomy/taxonomy.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SystemConstants } from "../constants/system.constants";
import { CommonService } from "../services/common.service";
import * as translateJson from '../../assets/i18n/en.json'
import { ErrorsValidatorOptions, RegionQuery } from "../interface/common-validators/common-validators.interface";
import { UserRole } from "../constants/user-role.constants";
import { UnregisteredExhibitor } from "../interface/unregister-exhibitor/unregister-exhibitor.interface";
import { CommonStatus } from "../constants/common-status.constants";
import { EventDetail } from "src/app/interface/event-details/event_detail.interface";
import { debounceTime } from "rxjs/operators";
import { CommonStatusType } from "../global-typeof";
import { CommonTabStatus, EventType, UserRoleStatus } from "./model/event";
import { TimezoneOption, USTimezoneService } from "./timezone.service";
import { EventCommonService } from "../services/event.service";
@Component({
  selector: "app-event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.scss"],
  providers: [DatePipe],
})
export class EventComponent implements OnInit {
  @ViewChild('scrollableElement') scrollableElement!: ElementRef;

  mydata = -1;
  date3: string;
  dateRangeValue: Date[];
  nextDate = new Date();
  userData: any;
  userSlug: string = "";
  userRole: any = null;
  loginUserRole: any = null;
  eventForm: FormGroup;
  eventRequestCancelForm: FormGroup;
  inviteForm: FormGroup;
  isAddEvent = "";
  // requestRaffleValue = false;
  eventName = "";
  inviteOpen = "";
  indexCurrent = -1;
  editEventSelectedItem: any;
  customers: any = [];
  exhibListAttributes: any = {};
  fAttributes: any = {};
  submitInvite = false;
  centerRadius = 16094;
  eventPaymentCheckOutList: any = [];
  exhibitorSearch: number = 0;
  submitted = false;
  showAmountField = 0;
  maxDate: Date;
  isSubmitted: boolean;
  userRoleStatus = UserRole;
  userSystemConstants = SystemConstants;
  isManualMapSelected:boolean = false;
  isApproveButtonVisible:boolean;
  commonStatus = CommonStatus;
  currentPage = this.userSystemConstants.DEFAULT_CURRENT_PAGE
  isEditRequestFormEnabled:boolean;
  isCrossIconDisbale:boolean = false
  listItemAndLineHeightForStaticTimeZone:number = 232;


  // this object is used to handle event form
  submits = {
    currentStep: "step1",
    step1: {
      visibility: true,
      submitted: false,
    },
    step2: {
      visibility: false,
      submitted: false,
    },
    step3: {
      visibility: false,
      submitted: false,
    },
    step4: {
      visibility: false,
      submitted: false,
    },
    // TODO:- Hiding step 5

    // step5: {
    //   visibility: false,
    //   submitted: false,
    // },
  };

  // raffelType: any = [
  //   { name: "Yes", value: true },
  //   { name: "No", value: false },
  // ];

  users = [
    { id: "anjmao", name: "Anjmao" },
    { id: "varnas", name: "Tadeus Varnas" },
  ];
  eventApprovalType = [
    { id: "free", name: "Free" },
    { id: "paid", name: "Paid" },
    { id: "hfp", name: "Hfp" },
  ];

  // TODO:- Hiding attendance List

  // attendanceList = [
  //   { id: "yes", name: "Yes" },
  //   { id: "no", name: "No" },
  //   { id: "hfp", name: "HFP" },
  // ];
  minStartDate: Date = new Date();
  maxStartDate: Date = new Date();
  selectedDate = new Date();
  hourStep = 1;
  minuteStep = 15;
  disableTime = true;
  endTime = false;
  imageSrc = "";
  imageCdn = environment.imageCdn.getHost;
  imageBaseUrl = environment.ImageBase;
  screeningParentList: any = [];

  // todo : hiding the industry field as we are not using it now
  
  // industriesList: any = [];
  mapRegion: any = [];
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  miles = 50;
  allEventData: any = [];
  corporateUserList: any = [];
  editIndex: number = -1;
  inviteIndex: number = -1;
  eventReqItem: any;
  adminApprovedCount = 0;
  adminApprovedSearchCount = 0;
  adminPendingCount = 0;
  adminDeclinedCount = 0;
  adminApprovedCountSearch = 0;
  adminPendingCountSearch = 0;
  adminDeclinedCountSearch = 0;
  adminApprovedPastCountSearch = 0;
  modalRef: BsModalRef

  adminActiveTab = 1;
  activeEventCount = 0;
  pastEventCount = 0;

  exhbitorEventActiveTab = 1;

  conditionAccepted = false;
  eventsAttributes: any = {};
  currentDate = new Date();

  indexNumber: number;

  selectedEventId: any = [];
  selectedEvenType = "free";

  eventSearchForm: FormGroup;
  emailTemplate: any;
  indexData: any;
  tempSlug: any;
  eventSlug: any;
  tempEventData: any;
  eventStatus: any;

  eventDynamicUrl: any = false;

  selectedEventImage = "";

  eventImages = [];
  eventImageName: string = "defaultEventImages";

  eventImgUrl: string;
  corporateHFImgUrl: string;
  onsiteWebImgUrl: string;
  virtualClassEvntImgUrl: string;
  nutritionImgUrl: string;
  fitnessImgUrl: string;
  seatMassageImgUrl: string;
  cookingDemoImgUrl: string;

  userDataFindByAdmin: any;

  addEventType:EventType;
  selectedEvent = ''
  modalOpen:boolean = false
  public uploader: FileUploader = new FileUploader({
    url:
      environment.apiUrl + SystemConstants.EVENT_IMAGE_FOLDER_UPLOADER_URL,
  });
  translateJson: typeof translateJson = Object.assign({}, (translateJson as any).default);
  isEditModeEnable: boolean;
  nextStepForEventForm:string;
  startItem:number;
  activeScreenList: any = [];
  isSelectedTimeZones: boolean =  false 
  currentTimezone;
  timezones: TimezoneOption[] = [];
  isDefaultListing: boolean = true;
  selectedTimeZone:string
  DefaultTimezones = []
  isTimeZoneDropDownOpen:boolean = false
  selectedRegionCode: any;
  itemHeight: any;
  constructor(
    private fb: FormBuilder,
    private comp: AppComponent,
    private taxonomyService: TaxonomyService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private modalService: BsModalService,
    public globals: Globals,
    private loginService: LoginService,
    private eventService: EventService,
    private profileService: ProfileService,
    private ngZone: NgZone,
    private exportService: ExportService,
    private notifier: NotifierService,
    private datePipe: DatePipe,
    private _commonService: CommonService,
    private timezoneService: USTimezoneService,
    private eventCommonService:EventCommonService,
    private cdr: ChangeDetectorRef
  ) {

    this.maxDate = new Date(new Date().setHours(new Date().getTime() - 1));

    this.nextDate.setDate(this.nextDate.getDate() + 7);
    this.dateRangeValue = [new Date(), this.nextDate];
    this.currentDate.setDate(this.currentDate.getDate() + 1);

    // Copy object
    this.exhibListAttributes = Object.assign(
      {},
      this.globals.adminPaginationAttrs
    );
    this.route.params.subscribe((params) => {
      if (params["slug"]) {
        this.tempSlug =
          this.router.url.split("/").length == 4
            ? this.router.url.split("/")[2]
            : "";
        this.eventSlug = params["slug"];
        this.eventSlug = decodeURIComponent(this.eventSlug);
      } else {
        this.tempSlug =
          this.router.url.split("/").length == 4
            ? this.router.url.split("/")[2]
            : "";
        this.eventSlug = this.router.url.substr(
          this.router.url.lastIndexOf("/") + 1
        );

        this.eventSlug = decodeURIComponent(this.eventSlug);
      }
    });

    for (let i = 0; i < 8; i++) {
      this.eventImages.push("defaultEventImage_" + i + ".png");
    }
    // this.selectedEventImage = 'defaultEventImage_0.png';
  }

  formIniti() {
    //  console.log("I am in..");
    this.eventForm = this.fb.group({
      eventName: [
        "", [Validators.required]
        //  this._commonService.setCustomValidatorMethods({ isNoWhitespaceValidation: true, minLength: 2, maxLength: 100 })
      ],
      permalink: ["", []],
      eventOptionType: ["", []],
      eventDescription: ["", 
      this._commonService.setCustomValidatorMethods({ isNoWhitespaceValidation: true,disableRequired:true})
    ],
      auth: [this.userRoleStatus.COMPANY_ADMIN, Validators.required],
      status: [CommonStatus.ACTIVE, Validators.required],
      eventDate: ["", Validators.required],
      startDateTime: [new Date(), Validators.required],
      endDateTime: [
        new Date(new Date().setHours(23, 59, 59)),
        Validators.required,
      ],
      timeZone:[this.currentTimezone.label,Validators.required],
      maxExhibitor: [
        0,
        [Validators.required, Validators.pattern(new RegExp("^[0-9 ]*$"))],
      ],
      //todo hiding this we are not using this time
      // requestReffleItem: [false, Validators.required],
      // media: this.fb.array([], [Validators.required,Validators.minLength(1)]),
      // media: this.fb.group({
      //   eventImageName: new FormControl(),
      //   corporateHFImageName: new FormControl(),
      //   onsiteWebImageName: new FormControl(),
      //   virtualClassEvntImageName: new FormControl(),
      //   nutritionImageName: new FormControl(),
      //   fitnessImageName: new FormControl(),
      //   seatMassageImageName: new FormControl(),
      //   cookingDemoImageName: new FormControl()
      // }, Validators.required),
      media: this.fb.array([], [Validators.required]),
      // eventImageName: new FormControl(''),
      // exhibitorReq: new FormControl(
      //   [],
      //   [] //Validators.required
      // ),
      screenReq: new FormControl([], []),
      regions: this.fb.array([]),
      otherExhibitor: ["",
       this._commonService.setCustomValidatorMethods({ isNoWhitespaceValidation: true, isMultiEmailValidation:true,disableRequired:true})
      ],
      company: ["",  [Validators.required]
      // this._commonService.setCustomValidatorMethods({isNoWhitespaceValidation: true,minLength: 2, maxLength: 60})
    ],
      onsiteEmp: ["",
      [
        this._commonService.ConditionalValidator(()=> this.isManualMapSelected,
        Validators.required),
        Validators.pattern(new RegExp("^[0-9]*$"))
      ]
],
      hostedBy: ["", []
      // this._commonService.setCustomValidatorMethods({isNoWhitespaceValidation:true,minLength:2,maxLength:100,disableRequired:true})
    ],
      eventAddress: this.fb.group({
        formatted_address: ["", []],
        location: ["", this._commonService.setCustomValidatorMethods({isNoWhitespaceValidation:true})],
        state: ["", []],
        city: ["", []],
        country: ["", []],
        zip_code: ["", []],
        lat: [, []],
        lng: [, []],
        additionLocation: [""],
        fullAddess: ["", []],
      }),
     // website: ["",  this._commonService.setCustomValidatorMethods({ isUrlPattern: true })],
     address2:["",[]],
        website: ["",Validators.required],

      // TODO:- Hiding step 4

      // medicalCarriers: ["",[]],
      // visionCarries: ["",[],],
      // dentalCarries: ["", [],],
      parkingInstructions: ['',
        [
          this._commonService.ConditionalValidator(()=> this.isManualMapSelected,
          Validators.required)
      ],
      ],
      checkIn: ['', 
        [
          this._commonService.ConditionalValidator(()=> this.isManualMapSelected,
          Validators.required)
        ],
      ],
      onsiteContact: ["",
      [
        this._commonService.ConditionalValidator(()=> this.isManualMapSelected,
        Validators.required)
      ],
],
      phone: [
        "",[Validators.required]
        // this._commonService.setCustomValidatorMethods()
      ],
      regionCode: [
        "", 
        [Validators.required] // Add validation if necessary
      ],
      memberId: [, [Validators.required]],

      // TODO:- Hiding attendance List

      // attendanceFee: [this.userSystemConstants.NO, []],
    });
    // This  is event  seach form
    this.eventSearchForm = this.fb.group({
      eventName: ["", []],
      eventDate: ["", []],
      searchByRegion:["",[]]

    });
    if(this.loginUserRole != this.userRoleStatus.SUPER_ADMIN ){
      this.eventFormMemberId.clearValidators();
      this.eventFormMemberId.updateValueAndValidity()
    }
  }
 
   
   
  get eventFormName():AbstractControl {
    return this.eventForm.get("eventName");

  }
  get eventFormOtherExhibitor():AbstractControl {
    return this.eventForm.get("otherExhibitor");

  }
  get eventFormCompany() :AbstractControl{
    return this.eventForm.get("company");

  }
  get eventFormHostedBy():AbstractControl {
    return this.eventForm.get("hostedBy");

  }
  get eventFormWebsite():AbstractControl {
    return this.eventForm.get("website");

  }
  get eventFormPhone():AbstractControl {
    return this.eventForm.get("phone");

  }
  get regionCode():AbstractControl {
    return this.eventForm.get("regionCode");

  }
  get eventFormEventDescription() :AbstractControl{
    return this.eventForm.get("eventDescription");
  }
  get eventFormStartDateTime() :AbstractControl{
    return this.eventForm.get("startDateTime");
  }
  get eventFormEndDateTime() :AbstractControl{
    return this.eventForm.get("endDateTime");
  }
  get eventFormOnsiteContact() :AbstractControl{
    return this.eventForm.get("onsiteContact");

  }
  get eventFormAddress(){
    return this.eventForm.get('eventAddress') as FormGroup
  }
  get eventFormLocation():AbstractControl{
        return this.eventFormAddress.get('location')
  }
  get eventSearchName():AbstractControl{
    return this.eventSearchForm.get('eventName')
  }
  get eventFormParkingInstructions():AbstractControl{
    return this.eventForm.get('parkingInstructions')
  }
  get eventFormCheckIn():AbstractControl{
    return this.eventForm.get('checkIn')
  }
  get eventFormMemberId():AbstractControl{
    return this.eventForm.get('memberId')
  }
  get searchByRegion():AbstractControl {
    return this.eventSearchForm.get("searchByRegion");

  }
  get eventAddress2():AbstractControl {
    return this.eventForm.get("address2");
  }
  get timeZone() :AbstractControl{
    return this.eventForm.get("timeZone");
  }

  isValidEvent():boolean{
    return (
      this.eventForm.controls['company'].valid &&
      this.eventForm.controls['onsiteEmp'].valid &&
      this.eventFormAddress.controls['location'].valid &&
      this.eventFormAddress.controls['additionLocation'].valid &&
      this.eventForm.controls['website'].valid )  
  }
 
  //Todo hiding this because we are not using this time
  // onRadioBoxChange(option, event) {
  //   if (event.target.checked) {
  //     //  console.log("abc", option.value)
  //     if (option.value == true) {
  //       this.requestRaffleValue = true;
  //       this.eventForm.patchValue({
  //         requestReffleItem: true,
  //       });
  //     } else if (option.value == false) {
  //       this.requestRaffleValue = false;
  //       this.eventForm.patchValue({
  //         requestReffleItem: false,
  //       });
  //     }
  //     // console.log(this.requestRaffleValue);
  //   }
  // }

  handleImgError(ev: any) {
    let source = ev.srcElement;
    let imgSrc = `/assets/images/avatars/avatar-5.png`;

    source.src = imgSrc;
  }

  get eventMediaArray() {
    return this.eventForm.get("media") as FormArray;
  }

 
  ngOnInit() {
    this.DefaultTimezones = this.timezoneService.getUSTimezones();
    this.currentTimezone = this.timezoneService.getCurrentTimezone();
    this.selectedTimeZone = this.currentTimezone.label;
  
    this.zoom = 7;
    this.geoCoder = new google.maps.Geocoder();
    this.latitude = 47.751076;
    this.longitude = -120.740135;
    // Copy object
    this.eventsAttributes = Object.assign(
      {},
      this.globals.adminPaginationAttrs
    );
    

    // if (this.injector.get(GetRouterLinkService).getPageAccordingUsers(this.comp)) {
    //   this.eventStatus = this.authService.getEventStatus();
    //   if (this.eventStatus && this.eventStatus >= 0) {
    //     this.adminActiveTab = this.eventStatus;
    //   }

    //   // console.log("amanstatus",this.eventStatus);

    //   this.eventRequestCancelForm = this.fb.group({
    //     reason: ["", Validators.required],
    //   });
    //   this.userData = this.comp.userData;

    //   this.loginUserRole = this.userData.role;
    //   this.formIniti();
    //   if (this.loginUserRole === 0) {
    //     if (this.tempSlug == "update-event") {
    //       this.adminActiveTab = 1;
    //       this.eventDynamicUrl = true;
    //       this.getTempEventData(this.eventSlug);
    //     }

    //     this.onChangeFomValue();

    //     this.getUserAndEventList();
    //   } else if (this.loginUserRole === 10) {
    //     //
    //     if (this.tempSlug == "update-event") {
    //       this.exhbitorEventActiveTab = 1;
    //       this.eventDynamicUrl = true;
    //       this.getTempEventData(this.eventSlug);
    //     }
    //     this.onChangeFomValue();
    //     this.getCorporateEventListAndCounts();
    //     //this.emailSends();
    //   } else if (this.loginUserRole === 20) {
    //     this.getExhibitorEventListAndCounts();
    //   }
    // } else {
    //   this.router.navigateByUrl("/");
    // }

    this.authService.isLoggedIn.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.getScreeningTaxo()
        this.eventStatus = this.authService.getEventStatus();
        if (this.eventStatus >= 0) {
          this.adminActiveTab = this.eventStatus;
        }

        // console.log("amanstatus",this.eventStatus);

        this.eventRequestCancelForm = this.fb.group({
          reason: ['', Validators.required]
        });
        this.userData = this.comp.userData;

        this.loginUserRole = this.userData.role;
        this.authService.masqueradeUserRoleObservable.subscribe((masqueradeRole) => {
          if (masqueradeRole) {
            this.loginUserRole = masqueradeRole;
          }
        })
        this.formIniti(); 
        if (this.loginUserRole === 0) {
          if (this.tempSlug == 'update-event') {
            this.adminActiveTab = 1;
            this.eventDynamicUrl = true;
            this.getTempEventData(this.eventSlug);
          }

          this.onChangeFomValue();

          this.getUserAndEventList();

        } else if (this.loginUserRole === 10) {

          //                                                                                                                                                      
          if (this.tempSlug == 'update-event') {
            this.exhbitorEventActiveTab = 1;
            this.eventDynamicUrl = true;
            this.getTempEventData(this.eventSlug);
          }
          this.onChangeFomValue();
          this.getCorporateEventListAndCounts();
          //this.emailSends();

        } else if (this.loginUserRole === 20) {
          this.getExhibitorEventListAndCounts();
        }
      } else {
        this.router.navigate(['/login']);
      }
    }, err => {
      console.log(err);
    });


    // file upload steps
    this.uploader.onAfterAddingFile = (file: any) => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (item: any, response: any, status: any) => {
      const data = JSON.parse(response);

      if (
        status === 200 &&
        data &&
        data.files &&
        data.files[0] &&
        data.files[0].filename
      ) {
        // this.eventForm.controls['media'] = this.fb.array([]);
        // console.log( "ng on init" +this.eventForm.controls['media']);

        // if (this.key == 'eventImage') {
        //   this.eventImgUrl = data.files[0].filename;
        // }
        // if (this.key == 'corporateHFImage') {
        //   this.corporateHFImgUrl = data.files[0].filename;
        // }
        // if (this.key == 'virtualClassEvntImage') {
        //   this.virtualClassEvntImgUrl = data.files[0].filename;
        // }
        // if (this.key == 'seatMassageImage') {
        //   this.seatMassageImgUrl = data.files[0].filename;
        // }
        // if (this.key == 'onsiteWebImage') {
        //   this.onsiteWebImgUrl = data.files[0].filename;
        // }
        // if (this.key == 'nutritionImage') {
        //   this.nutritionImgUrl = data.files[0].filename;
        // }
        // if (this.key == 'fitnessImage') {
        //   this.fitnessImgUrl = data.files[0].filename;
        // }
        // if (this.key == 'cookingDemoImage') {
        //   this.cookingDemoImgUrl = data.files[0].filename;
        // }

        this.eventMediaArray.reset();
        this.eventMediaArray.push(new FormControl(data.files[0].filename));
        // this.eventMediaArray.push(this.fb.group({
        //   eventImageName: data.files[0].filename
        // }))

        // this.eventMediaArray.patchValue({
        //   eventImageName: data.files[0].filename
        // });
      } else {
        if (data && data.error && data.error.message) {
          //this.notifier.notify('error', data.error.message);
        } else {
          //this.notifier.notify('error', 'Something went wrong!');
        }
      }
    };
    if(this.loginUserRole == this.userRoleStatus.EXHIBITOR){
        this.eventSearchName.valueChanges.pipe(debounceTime(500)).subscribe(data=>
          this.eventSearch()
        )
    }
  }

 
  getAllTheTimeZones(event:Event) {
    this.isSelectedTimeZones = true; 
    this.isDefaultListing = false; 
    event.stopPropagation(); 
    this.timezones = this.timezoneService.getAllTimezones().sort(
      (a, b) => a.offset - b.offset
    ); 
    setTimeout(()=>{
      let firstDiv = document.getElementById('scrollableElement');
     if(firstDiv)firstDiv.scrollTop += this.listItemAndLineHeightForStaticTimeZone; 
    },0)
  }
  
  getDefaultTimeZones(event:Event) {
    this.isSelectedTimeZones = false;
    this.isDefaultListing = true; 
    event.stopPropagation(); 
    this.timezones = this.timezoneService.getUSTimezones(); 
  }
  removeSelectedTimeZone(){
    this.selectedTimeZone = ''
    this.currentTimezone = ''
    this.isCrossIconDisbale = false
    this.timeZone.reset();
    this.timeZone.markAsDirty();
    this.timezones = this.timezoneService.getUSTimezones(); 
    this.isSelectedTimeZones = false;
    this.isDefaultListing = true; 
  }
  openAndCloseTZ() {
   if (this.isTimeZoneDropDownOpen === true) {
      this.isTimeZoneDropDownOpen = false
    } else {
      this.isTimeZoneDropDownOpen = true
    }
    if(this.hasSelectedTimeZone()){
      this.timezones = this.timezoneService.getUSTimezones();
      this.isSelectedTimeZones = false;
      this.isDefaultListing = true; 
    } else{
      if(this.timeZone.value !== null){
        this.timezones = this.timezoneService.getAllTimezones()
      }
    }
    console.log(this.hasSelectedTimeZone())
      if (String(this.timeZone.value) === this.timeZone.value && !this.hasSelectedTimeZone()) {
        console.log(this.timeZone)
        let selectedTimeZoneLabel = this.getTheSelectedTimeZoneObject(this.timeZone.value)
        this.targetScroll(selectedTimeZoneLabel)
      }else{
        if(this.timeZone.value && !this.hasSelectedTimeZone())this.targetScroll(this.timeZone.value)
      }
  }
  hasSelectedTimeZone(){
    console.log("time-zone",this.timeZone.value)
    let selectedTimeZoneLabel 
    if (String(this.timeZone.value) === this.timeZone.value ) {
      selectedTimeZoneLabel  = this.getTheSelectedTimeZoneObject(this.timeZone.value)
    }else{
      selectedTimeZoneLabel = this.timeZone.value
    }
    this.DefaultTimezones = this.timezoneService.getUSTimezones();
    const matchingDefaultTimezone = this.DefaultTimezones.find(
      (timezone) => timezone.label === selectedTimeZoneLabel?.label
    )
    console.log(matchingDefaultTimezone)
    if (matchingDefaultTimezone) {
      return true; 
    }else{
      return false
    }
  }

  @HostListener('document:click', ['$event.target'])
  onOutsideClick(targetElement: HTMLElement): void {
    const dropdownElement = document.querySelector('.time-zone-selectbox');
    const isInside = dropdownElement?.contains(targetElement);
    if (!isInside) {
      this.isTimeZoneDropDownOpen = false;
    }
  }

  get fg() {
    return this.eventRequestCancelForm.controls;
  }

  getValidationErrors(options: ErrorsValidatorOptions): string {
    return this._commonService.getErrorMessage(options);
  }

  showInviteForm(index) {
    this.indexData = index;
    let eventSelectedData = this.allEventData[index];
    let eventName = eventSelectedData.eventName
      ? eventSelectedData.eventName
      : eventSelectedData.permalink;
    let eventAddress = eventSelectedData.eventAddress.formatted_address
      ? eventSelectedData.eventAddress.formatted_address
      : "";
    //let eventName = eventSelectedData.eventName?eventSelectedData.eventName:eventSelectedData.permalink;
    this.eventName = eventName;
    // eventDescription
    let eventId = eventSelectedData.id;
    let eventDate = eventSelectedData.eventDate
      ? eventSelectedData.eventDate
      : new Date();
    //    return moment(experationDate).isBefore(moment(currentdate));
    let eventDateFormatted = moment(eventDate).format("MM/DD/YYYY");
    //start time
    let startTime = eventSelectedData.startDateTime
      ? eventSelectedData.startDateTime
      : new Date();
    let eventStartTimeFormatted = moment(startTime).format("HH a");
    //end time
    let endTime = eventSelectedData.endDateTime
      ? eventSelectedData.endDateTime
      : new Date();
    let eventEndTimeFormatted = moment(endTime).format("HH a");

    let eventTime = eventStartTimeFormatted + "-" + eventEndTimeFormatted;
    let url = environment.sentEmailHost +
      "/event/" +
      eventId +
      "/" +
      this.userData.id;

    this.emailTemplate =
      "Hello,\n\nWe are coordinating an employee health fair and would like to invite you to participate in the event.  Here is the information:\n\n" +
      eventName +
      ", \n" +
      eventDateFormatted +
      "\n" +
      eventTime +
      "\n" +
      eventAddress +
      "\n" +
      url +
      "\n \n \nIf you are available to attend, please fill out our registration by following this link:\n\n\nWe hope to see you at the event! ";
    // console.log(this.emailTemplate);
    this.inviteForm = this.fb.group({
      fromEmailAddress: [
        this.userData.email,
        [
          Validators.required
          // Validators.minLength(2),
          // Validators.maxLength(250),
        ],
      ],
      email: [
        "",
        this._commonService.setCustomValidatorMethods({ isEmailPattern: true })
      ],
      eventId: [eventId, [Validators.required]],
      eventName: [eventName, [Validators.required]],
      eventDate: [eventDate, [Validators.required]],
      fee: ["no", [Validators.required]],
      status: [0, Validators.required],
      userId: [this.userData.id, [Validators.required]],
      url: [url, [Validators.required]],
      emailFormat: ["", []],
    });
  }

  getTempEventData(slug: string): any {
    this.getTempEventBySlug(slug).subscribe(
      (res) => {  
        if (res && res.length > 0) {
          this.tempEventData = res[0];
          // const newTaxoData = this.tempEventData.exhibitorReq.concat(
          //   this.tempEventData.screenReq
          // );
          const newTaxoData = this.tempEventData.screenReq;
          // this.getScreeningTaxos(newTaxoData);
          this.getScreeningTaxo()

          this.eventMediaArray.reset();
          this.mapRegion = [];
          //  this.eventForm.get('media')).clear();
          this.eventForm.controls["media"] = this.fb.array([]);
          setTimeout(() => {
            this.editEventSelectedItem = this.tempEventData;
            // this.date3 = this.datePipe.transform(this.editEventSelectedItem.eventDate, 'MMM d, y')
        if (this.editEventSelectedItem && this.activeScreenList) {
          const screeningParentIdSet = new Set(this.activeScreenList.map(item => item._id));
          this.editEventSelectedItem.screenReq = this.editEventSelectedItem.screenReq.filter(id => screeningParentIdSet.has(id));
        }
            this.date3 = moment(this.editEventSelectedItem.eventDate).format(
              "MM/DD/YYYY"
            );

            this.editEventSelectedItem.eventDate = this.date3;
            if(typeof this.editEventSelectedItem.otherExhibitor !== 'string' ){
              this.editEventSelectedItem.otherExhibitor.join(',')
            }
            this.eventForm.patchValue(this.editEventSelectedItem);
            console.log(this.editEventSelectedItem.timeZone)
            if(String(this.editEventSelectedItem.timeZone) === this.editEventSelectedItem.timeZone){
              let selectedTimeZoneLabel = this.getTheSelectedTimeZoneObject(this.editEventSelectedItem.timeZone)
              this.selectedRegionCode = this.editEventSelectedItem.regionCode
              this.timeZone.patchValue(selectedTimeZoneLabel.label)
            }else{
              this.selectedRegionCode = this.editEventSelectedItem.regionCode
              this.timeZone.patchValue(this.editEventSelectedItem?.timeZone?.label)
            }
            this.selectedTimeZone =  this.timeZone?.value

            // this.requestRaffleValue = this.tempEventData.requestReffleItem;
            if (this.editEventSelectedItem.media) {
              for (const { } of this.editEventSelectedItem.media) {
                // this.eventMediaArray.push(new FormControl(item));
              }
            }
            // add regions to form
            if (this.editEventSelectedItem.regions) {
              this.mapRegion = [];
              for (const regionItem of this.editEventSelectedItem.regions) {
                this.mapRegion.push(regionItem);
                this.latitude = regionItem.lat ? regionItem.lat : 47.751076;
                this.longitude = regionItem.lng ? regionItem.lng : -120.740135;
              }
            }
            this.addMediaValue(this.editEventSelectedItem.media && this.editEventSelectedItem.media[0])

          }, 0);

          this.isAddEvent = "edit";
          this.editIndex = -1;
          this.isEditModeEnable = true;
          this.addEventType = this.tempEventData && this.tempEventData.eventOptionType
        }
      },
      (err) => console.log(err)
    );
  }

  onTimezoneChange(selected: any) {
    // Prevent interaction if the selected item is already active
    if (selected && selected.label === this.selectedTimeZone) {
      this.isTimeZoneDropDownOpen = false; 
      console.log("Item already selected:", selected);
      return;
    }
      if (selected) {
      this.eventForm.get('timeZone')?.setValue(selected);
      this.selectedTimeZone = selected.label; // Update to the selected label
    }
    this.isCrossIconDisbale = true;
    this.isTimeZoneDropDownOpen = false; // Close the dropdown
  }

  compareTimezones(t1: TimezoneOption, t2: TimezoneOption): boolean {
    return t1 && t2 ? t1.value.name === t2.value.name : t1 === t2;
  }
  // to get event deatil for preview
  getTempEventBySlug(slug: any): any {
    return new Observable((observer) => {
      let where = {
        filter: {
          where: {
            //  status: 1,
            permalink: slug,
          },
        },
      };
      this.eventService.getTempEventList(where).subscribe(
        (res) => {
          observer.next(res);
        },
        (err) => observer.next(err)
      );
    });
  }
  // Get screening taxo list
  getScreeningTaxos(ids): void {
    forkJoin([
      this.taxonomyService.getTaxonomyList({
        filter: {
          where: {
            status: 1,
            taxonomyTypeId: this.globals.taxonomyTypesConst.screeningId,
          },
        },
      }),
      this.taxonomyService.getTaxonomyList({
        filter: {
          where: {
            status: 1,
            taxonomyTypeId: this.globals.taxonomyTypesConst.industryId,
          },
        },
      }),
    ]).subscribe(
      (results) => {
        this.screeningParentList = results[0] ? results[0] : [];

        // todo : hiding the industry field as we are not using it now

        // this.industriesList = results[1] ? results[1] : [];
      },
      () => { }
    );

    const queryObj = {
      filter: {
        where: {
          status: 1,
          id: { inq: ids },
        },
      },
    };
    forkJoin([this.taxonomyService.getTaxonomyListNew(queryObj)]).subscribe(
      (results) => {
        const taxoList = results && results.length ? results : [];
        _.forEach(taxoList, (data) => {

          // TODO:- hidning Industries we are not using this time 

          // if (
          //   String(data.taxonomyTypeId) ==
          //   String(this.globals.taxonomyTypesConst.industryId)
          // ) {
          //   this.industriesList.push(data);
          // }
          if (
            String(data.taxonomyTypeId) ==
            String(this.globals.taxonomyTypesConst.screeningId)
          ) {
            this.screeningParentList.push(data);
          }
        });
      },
      () => { }
    );
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.inviteForm.controls;
  }

  onItemChange(selectedValue: string) {
    if (selectedValue == "yes") {
      this.showAmountField = 1;
      this.inviteForm.addControl(
        "amount",
        new FormControl("", this._commonService.setCustomValidatorMethods({ isNumericPattern: true })))
    } else if (selectedValue == "no") {
      this.showAmountField = 0;
      this.inviteForm.removeControl("amount");
    }
  }

  sendInvite(index: number) {
     let event = this.allEventData[index] as EventDetail;
    this.submitInvite = true;
    if (this.inviteForm.invalid) {
      this.isSubmitted = true;
      return;
    }else
    this.isSubmitted=false;
     this.eventService.sendInviteEmail(event, this.inviteForm.value)
     this.inviteForm.removeControl("amount");
     this.inviteIndex = -1;

  }


  openEventApprovalPopUp(content: any, data: any, ind: number): any {
    this.selectedEventId = data;

    this.indexCurrent = ind;
    this.selectedEvenType = "free";
    this.modalRef = this.modalService.show(
      content,
      Object.assign({}, { class: "pop-event-approval" })
    );
  }
  declineInvitationOfEvent(): void {
    this.submitted = true;

    if (this.eventRequestCancelForm.valid) {
      let queryObj: any = {};
      if (
        this.selectedEventId.paid == 0 ||
        this.selectedEventId.payButtonShow == 1
      ) {
        queryObj = { status: 2 };
      } else {
        queryObj = { status: 3 };
      }

      let id = this.selectedEventId.eventReqData.id;
      this.eventService.updateEventReqById(id, queryObj).subscribe(
        () => {
          let temEmail: any = {};
          temEmail.userId = this.selectedEventId.corpData[0]._id;
          //

          temEmail.slug = "exhibitor-event-request-declined";

          //  temEmail.subject = 'Your Event Approved';
          temEmail.keys = {
            EXHIBITOR_NAME: this.userData.username,
            CORPORATE_NAME: this.selectedEventId.corpData[0].username,
            REASON: this.eventRequestCancelForm.value.reason,
            companyName:this.selectedEventId && this.selectedEventId.corpData[0] && this.selectedEventId.corpData[0].data.company_name
          };
          this.eventService.emailSends(temEmail);
          this.allEventData[this.indexCurrent].eventReqStatus = 3;
          this.notifier.notify("success", "Request cancelled.");
          this.modalRef.hide();
          this.eventRequestCancelForm.reset();

          ///email code ends
        },
        () => { }
      );
    }
    /*  */
  }


  // to count of events
  getLocalEventsCounts(countStatusType: number): void {
    const queryObj: any = {
      where: {
        status: countStatusType,
      },
    };

    // This statement for search to event list
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventName
    ) {
      queryObj.where.eventName = this.eventSearchForm.value.eventName.trim().replace(/\s+/g, ' ');
      /* queryObj.where.eventName = {
         regexp: 'm.*' + this.eventSearchForm.value.eventName + '/mi'
       };*/
    }
   this.searchEventByRegion(queryObj)
    var todayEndDate = new Date();
    todayEndDate.setDate(todayEndDate.getDate() - 1);
    todayEndDate.setHours(23, 59, 59, 999);

    var end = new Date();
    end.setDate(end.getDate() + 999999);
    end.setHours(0, 0, 0, 0);
    // alert( start + ':' + end);
    queryObj.where.eventDate = { gt: todayEndDate, lte: end };

    //  console.log("aaaaaaaaaaaaaa",this.exhibitorSearch)
    if (this.exhibitorSearch == 0) {
      this.eventSearchForm.reset();
    }
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate &&
      this.eventSearchForm.value.eventDate[0] &&
      this.exhibitorSearch == 1
    ) {
      var start = new Date(this.eventSearchForm.value.eventDate[0]);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.eventSearchForm.value.eventDate[1]);
      end.setHours(23, 59, 59, 999);

      // alert( start + ':' + end);
      queryObj.where.eventDate = { gt: start, lte: end };
    } else if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate &&
      this.exhibitorSearch == 1
    ) {
      var start = new Date(this.eventSearchForm.value.eventDate);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.eventSearchForm.value.eventDate);
      end.setHours(23, 59, 59, 999);

      // alert( start + ':' + end);
      queryObj.where.eventDate = { gt: start, lte: end };
    }

  if(countStatusType === this.commonStatus.PENDING || countStatusType === this.commonStatus.DECLINE) delete queryObj.where.eventDate;
    this.eventService.findLocalEventCountSuperAdmin(queryObj).subscribe(
      (data) => {
        if (this.exhibitorSearch == 1) {
          if (countStatusType === 1) {
            // console.log(data);
            //  this.adminApprovedCount = (data && data.count) ? data.count : 0;
            this.adminApprovedCountSearch = data ? data : 0;
          }
          if (countStatusType === 0) {
            this.adminPendingCountSearch = data ? data : 0;
            //this.eventsAttributes.count = (data && data.count) ? data.count : 0;
          }
          if (countStatusType === 2) {
            this.adminDeclinedCountSearch = data ? data : 0;
            //  this.eventsAttributes.count = (data && data.count) ? data.count : 0;
          }
          if (this.adminActiveTab == countStatusType) {
            this.eventsAttributes.count = data ? data : 0;
          }
        } else {
          if (countStatusType === 1) {
            // console.log(data);
            //  this.adminApprovedCount = (data && data.count) ? data.count : 0;
            this.adminApprovedCount = data ? data : 0;
            if(this.adminActiveTab === 1) this.eventsAttributes.count = this.adminApprovedCount;
          }
          if (countStatusType === 0) {
            this.adminPendingCount = data ? data : 0;
            //this.eventsAttributes.count = (data && data.count) ? data.count : 0;
            if(this.adminActiveTab === 0)this.eventsAttributes.count = this.adminPendingCount;
          }
          if (countStatusType === 2) {
            this.adminDeclinedCount = data ? data : 0;
            //  this.eventsAttributes.count = (data && data.count) ? data.count : 0;
            if(this.adminActiveTab === 2)  this.eventsAttributes.count = this.adminDeclinedCount;
          }

          if (this.adminActiveTab == countStatusType) {
            // console.log("aaaaaaa", this.adminActiveTab);
            // console.log("bb", countStatusType, data);
            this.eventsAttributes.count = data ? data : 0;
          }
        }
      },
      () => { }
    );
  }

  // update super admin count 
  updateEventCountForSuperAdmin(){
    this.eventService.updateLocalEventCountForSuperAdmin({}).subscribe((data)=>{
      data.forEach(eventCount => {
        if(this.adminActiveTab === this.commonStatus.ACTIVE){
        this.adminApprovedCount = eventCount.approvedEvent ?  eventCount.approvedEvent : 0;
        this.eventsAttributes.count =  this.adminApprovedCount;
        }else if(this.adminActiveTab === this.commonStatus.PENDING){
        this.adminPendingCount = eventCount.pendingEvent ? eventCount.pendingEvent : 0;
        this.eventsAttributes.count = this.adminPendingCount;
        }else if(this.adminActiveTab === this.commonStatus.DECLINE){
        this.adminDeclinedCount = eventCount.declinedEvent ? eventCount.declinedEvent: 0;
        this.eventsAttributes.count = this.adminDeclinedCount;
        }
        this.adminApprovedSearchCount = eventCount.approvedPastEvent ? eventCount.approvedPastEvent:0;
      });
    })
  }
  // to count of events
  getLocalEventsCountsPast(countStatusType: number, eventClick: number): void {
    const queryObj: any = {
      where: {
        status: countStatusType,
      },
    };

    // This statement for search to event list
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventName
    ) {
      queryObj.where.eventName = this.eventSearchForm.value.eventName.trim().replace(/\s+/g, ' ');



    }
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.searchByRegion
    ) {
      let regionFilter = { lat: this.latitude, lng: this.longitude }
      queryObj.where.filterByRegion = regionFilter;
    }

    if (this.exhibitorSearch == 0) {
      this.eventSearchForm.reset();
    }
    var todayEndDate = new Date();
    todayEndDate.setDate(todayEndDate.getDate() - 1);
    todayEndDate.setHours(23, 59, 59, 999);
    var end = new Date(1991);
    end.setHours(0, 0, 0, 0);
    // alert( start + ':' + end);
    queryObj.where.eventDate = { gt: end, lte: todayEndDate };

    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate &&
      this.eventSearchForm.value.eventDate[0] &&
      this.exhibitorSearch == 1
    ) {
      var start = new Date(this.eventSearchForm.value.eventDate[0]);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.eventSearchForm.value.eventDate[1]);
      end.setHours(23, 59, 59, 999);

      // alert( start + ':' + end);
      queryObj.where.eventDate = { gt: start, lte: end };
    } else if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate &&
      this.exhibitorSearch == 1
    ) {
      var start = new Date(this.eventSearchForm.value.eventDate);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.eventSearchForm.value.eventDate);
      end.setHours(23, 59, 59, 999);

      // alert( start + ':' + end);
      queryObj.where.eventDate = { gt: start, lte: end };
    }

    this.eventService.findLocalEventCountSuperAdmin(queryObj).subscribe(
      (data) => {

        if (this.exhibitorSearch == 1) {
          if (countStatusType === 1) {
            // console.log(data);
            //  this.adminApprovedCount = (data && data.count) ? data.count : 0;
            this.adminApprovedCountSearch = data ? data : 0;
            this.eventsAttributes.count = this.adminApprovedCountSearch;
          }
          if (countStatusType === 0) {
            this.adminPendingCountSearch = data ? data : 0;
            //this.eventsAttributes.count = (data && data.count) ? data.count : 0;
          }
          if (countStatusType === 2) {
            this.adminApprovedPastCountSearch = data ? data : 0;
            //  this.eventsAttributes.count = (data && data.count) ? data.count : 0;
          }
          // if (this.adminActiveTab == countStatusType) {
          //   this.eventsAttributes.count = data ? data : 0;
          // }

          if (this.adminActiveTab == countStatusType && eventClick != 1) {
            this.eventsAttributes.count = data ? data : 0;
          }
          if (this.adminActiveTab == 3 && eventClick != 1) {
            this.adminApprovedPastCountSearch = data ? data : 0;
            this.eventsAttributes.count = data ? data : 0;
          }
        } else {
          if (countStatusType === 1) {
            // console.log(data);
            //  this.adminApprovedCount = (data && data.count) ? data.count : 0;
            this.adminApprovedSearchCount = data ? data : 0;
          }
          if (countStatusType === 0) {
            this.adminPendingCount = data ? data : 0;
            //this.eventsAttributes.count = (data && data.count) ? data.count : 0;
          }
          if (countStatusType === 2) {
            this.adminDeclinedCount = data ? data : 0;
            //  this.eventsAttributes.count = (data && data.count) ? data.count : 0;
          }
          // console.log(eventClick);
          if (this.adminActiveTab == countStatusType && eventClick != 1) {
            this.eventsAttributes.count = data ? data : 0;
          }
          if (this.adminActiveTab == 3 && eventClick != 1) {
            this.eventsAttributes.count = data ? data : 0;
          }
        }
      },
      () => { }
    );
  }

  /**
   * Get Event list according to pagination with help of skip and limit
   * @param limitNum number
   * @param skipNum  number
   */

  getEventList(limitNum: number, skipNum: number): void {
    this.eventsAttributes.pageItems = [];

    /* if (this.adminActiveTab === 1) {
         console.log("dsf",this.eventsAttributes.count);
         this.eventsAttributes.count = this.adminApprovedCount;
       } else if (this.adminActiveTab === 2) {
         this.eventsAttributes.count = this.adminDeclinedCount;
       } else if (this.adminActiveTab === 0 && this.adminPendingCount > 0) {
         this.eventsAttributes.count = this.adminPendingCount;
       }  */

    let activeTab = this.adminActiveTab;
    if (activeTab == 1) {

      activeTab = 1;
      this.minStartDate = new Date();
      this.minStartDate.setHours(23, 59, 59, 999);
    }

    const queryObj: any = {
      filter: {
        where: {
          status: activeTab,
        },
        limit: limitNum,
        skip: skipNum,
      },
    };
    if (this.exhibitorSearch == 0) {
      this.eventSearchForm.reset();
    }

    var todayEndDate = new Date();
    todayEndDate.setDate(todayEndDate.getDate() - 1);
    todayEndDate.setHours(23, 59, 59, 999);

    var end = new Date();
    end.setDate(end.getDate() + 999999);
    end.setHours(0, 0, 0, 0);
    // alert( start + ':' + end);
    queryObj.filter.where.eventDate = { gt: todayEndDate, lte: end };
    // This statement for search to event list
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventName
    ) {
      // queryObj.filter.where.eventName = {
      //   regexp: 'm.*' + this.eventSearchForm.value.eventName + '/mi'
      // };
      queryObj.filter.where.eventName = this.eventSearchForm.value.eventName.trim().replace(/\s+/g, ' ');
    }

    /* if (this.eventSearchForm && this.eventSearchForm.value && this.eventSearchForm.value.eventDate) {
 
       let startDate = new Date(moment(this.eventSearchForm.value.eventDate).startOf('day').format("YYYY-MM-DD HH:m:s"));
       var new_date = moment(startDate).clone().add(1, 'days').format('YYYY-MM-DD');;
       let endtDate = new Date(moment(this.eventSearchForm.value.eventDate).endOf('day').format("YYYY-MM-DD HH:m:s"));
       queryObj.filter.where.eventDate = { gte: startDate, lt: endtDate };
     }
 */

    //  console.log(this.eventSearchForm.value);
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.searchByRegion
    ) {
      let regionFilter = { lat: this.latitude, lng: this.longitude }
      queryObj.filter.where.filterByRegion =regionFilter;
    }
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate &&
      this.eventSearchForm.value.eventDate[0] &&
      this.exhibitorSearch == 1
    ) {
      var start = new Date(this.eventSearchForm.value.eventDate[0]);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.eventSearchForm.value.eventDate[1]);
      end.setHours(23, 59, 59, 999);

      // alert( start + ':' + end);
      queryObj.filter.where.eventDate = { gt: start, lte: end };
    } else if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate &&
      this.exhibitorSearch == 1
    ) {
      var start = new Date(this.eventSearchForm.value.eventDate);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.eventSearchForm.value.eventDate);
      end.setHours(23, 59, 59, 999);

      // alert( start + ':' + end);
      queryObj.filter.where.eventDate = { gt: start, lte: end };
    }

   if(activeTab === this.commonStatus.PENDING || activeTab === this.commonStatus.DECLINE ) delete  queryObj.filter.where.eventDate;
    this.eventService.getLocalEventList(queryObj).subscribe(
      (data) => {

        const res = data && data.length ? data : [];
        if (res && res.length > 0) {
          res.forEach((v: any, k): any => {
            if (v.event) {
              let mapId = "map_" + k;
              this.loadListMap(mapId, v.eventAddress);
            }
          });
          this.eventsAttributes.pageItems = res;
          // this.findEventsBySearchText();
          this.allEventData = res;
          // console.warn(this.allEventData);
        } else {
          this.eventsAttributes.pageItems = res;
          this.allEventData = res;
        }

        this.allEventData.forEach((element) => {
          let currentdate = new Date();
          let endDate = new Date(element.eventDate);
          element.disable = false;
          if (currentdate.getTime() >= endDate.getTime()) {
           element.disable = true;
        }

          let todayStartDate = this._commonService.getTodayStartDate()
          let expiryDate = this._commonService.getExpiryDate()
          if(( endDate <= expiryDate && endDate >= todayStartDate)){
            element.isApproveButtonVisible
            = true
            } else {
              element.isApproveButtonVisible = false;
            }
        });
      },
      () => { }
    );
  }

  getEventListPast(limitNum: number, skipNum: number): void {
    this.eventsAttributes.pageItems = [];
    /* if (this.adminActiveTab === 1) {
         console.log("dsf",this.eventsAttributes.count);
         this.eventsAttributes.count = this.adminApprovedCount;
       } else if (this.adminActiveTab === 2) {
         this.eventsAttributes.count = this.adminDeclinedCount;
       } else if (this.adminActiveTab === 0 && this.adminPendingCount > 0) {
         this.eventsAttributes.count = this.adminPendingCount;
       }  */

    const queryObj: any = {
      filter: {
        where: {
          status: 1,
        },
        limit: limitNum,
        skip: skipNum,
      },
    };

    if (this.exhibitorSearch == 0) {
      this.eventSearchForm.reset();
    }
    //console.log("Form val: ",this.eventSearchForm.value);

    // This statement for search to event list
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventName
    ) {
      queryObj.filter.where.eventName = this.eventSearchForm.value.eventName.trim().replace(/\s+/g, ' ');
    }

    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.searchByRegion
    ) {
      let regionFilter = { lat: this.latitude, lng: this.longitude }
      queryObj.filter.where.filterByRegion = regionFilter;
    }

    var todayEndDate = new Date();
    todayEndDate.setDate(todayEndDate.getDate() - 1);
    todayEndDate.setHours(23, 59, 59, 999);
    var end = new Date(1991);
    end.setHours(0, 0, 0, 0);
    // alert( start + ':' + end);
    queryObj.filter.where.eventDate = { gt: end, lte: todayEndDate };

    //  console.log(this.eventSearchForm.value);
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate &&
      this.eventSearchForm.value.eventDate[0] &&
      this.exhibitorSearch == 1
    ) {
      var start = new Date(this.eventSearchForm.value.eventDate[0]);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.eventSearchForm.value.eventDate[1]);
      end.setHours(23, 59, 59, 999);

      // alert( start + ':' + end);
      queryObj.filter.where.eventDate = { gt: start, lte: end };
    } else if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate &&
      this.exhibitorSearch == 1
    ) {
      var start = new Date(this.eventSearchForm.value.eventDate);
      start.setHours(0, 0, 0, 0);
      var end = new Date(this.eventSearchForm.value.eventDate);
      end.setHours(23, 59, 59, 999);

      // alert( start + ':' + end);
      queryObj.filter.where.eventDate = { gt: start, lte: end };
    }
    this.eventService.getLocalEventList(queryObj).subscribe(
      (data) => {
        const res = data && data.length ? data : [];
        if (res && res.length > 0) {
          res.forEach((v: any, k): any => {
            if (v.event) {
              let mapId = "map_" + k;
              this.loadListMap(mapId, v.eventAddress);
            }
          });
          this.eventsAttributes.pageItems = res;
          // console.log(this.eventSearchForm.value.eventName !== null || this.eventSearchForm.value.eventDate !== null);
          // this.findEventsBySearchText();
          this.allEventData = res;
          // console.warn(this.allEventData);
        } else {
          this.eventsAttributes.pageItems = res;
          this.allEventData = res;
        }
        this.allEventData.forEach((element) => {
          let currentdate = new Date();
          let enddate = new Date(element.eventDate);
          element.disable = false;
          // console.log("1",currentdate.getTime()>= enddate.getTime(),currentdate, enddate,element.endDateTime, )

          if (currentdate.getTime() >= enddate.getTime()) {
            element.disable = true;
          }
        });
      },
      () => { }
    );
  }

  /**
   * This function is used for pagination
   */
  pageChanged(event: PageChangedEvent): void {
     this.startItem = (event.page - 1) * event.itemsPerPage;

    if (this.loginUserRole === 0) {
      let activeTab = this.adminActiveTab;
      if (activeTab == 3) {
        this.getPastEventListAndCount(this.commonStatus.ACTIVE)
      } else {
        // this.getListAndCountAccordingToStatus()
        this.getEventList(this.eventsAttributes.limits, this.startItem);
        // this.updateEventCountForSuperAdmin()
      }
    } else if (this.loginUserRole === 10) {
      this.getEventListForCorporate(
        this.exhbitorEventActiveTab,
        this.eventsAttributes.limits,
        this.startItem
      );
    } else if (this.loginUserRole === 20) {
      this.getEventListForExibitor(
        this.exhbitorEventActiveTab,
        this.eventsAttributes.limits,
        this.startItem
      );
    }
  }

  removeEventName(): void {
    this.eventSearchForm.get("eventName").setValue("");
    this.eventSearch();
  }
  removeEventDate(): void {
    this.eventSearchForm.get("eventDate").setValue("");
    this.eventSearch();
  }

  /**
   * This function is used to get Events Region list.
   */
  getEventRegionList(): void {
    let locArr = [];
    if (this.userData.region && this.userData.region.length) {
      this.userData.region.forEach((v) => {
        locArr.push({ loc: { near: [v.lat, v.long], maxDistance: 20 } });
      });
    }

    let userLocation = { lat: 42.266271, lng: -72.6700016 };
    const queryObj: any = {
      filter: {
        where: {
          location: {
            near: userLocation,
            maxDistance: 2,
          },
        },
      },
    };
    // const queryObj: any = {or: locArr};
    this.eventService.getEventRegion(queryObj).subscribe(
      (data) => {
        const res = data && data.length ? data : [];
        if (res && res.length > 0) {
          //  console.log(res);
        }
      },
      () => { }
    );
  }
  getUserAndEventList(): void {
    //console.log("11111", this.getEventList)
    forkJoin([
      this.getLocalEventsCountsPast(1, 1),
      this.getCorporateUserList(),
      this.getEventList(
        this.eventsAttributes.limits,
        this.eventsAttributes.skips
      ),

      this.getLocalEventsCounts(0),
      this.getLocalEventsCounts(2),
      this.getLocalEventsCounts(1),
    ]);
  }

  getUserAndEventListSuperAdmin(): void {
    if (this.adminActiveTab == 3) {
      forkJoin([
        this.getCorporateUserList(),
        this.getEventListPast(
          this.eventsAttributes.limits,
          this.eventsAttributes.skips),
        this.getLocalEventsCountsPast(1, 2),
      ]);
    } else {
      forkJoin([
        this.getCorporateUserList(),
        this.getEventList(
          this.eventsAttributes.limits,
          this.eventsAttributes.skips
        ),

        this.getLocalEventsCounts(this.adminActiveTab),
      ]);
    }
  }

  eventSearch(): void {
    this.exhibitorSearch = 1;
    if (this.loginUserRole === 0) {
      this.onChangeFomValue();
      this.getUserAndEventListSuperAdmin();
    } else if (this.loginUserRole === 10) {
      this.onChangeFomValue();
      if (this.exhbitorEventActiveTab == 1) {
        var activeTb = "active";
      } else {
        var activeTb = "past";
      }

      // forkJoin([this.getEventListForCorporate(this.exhbitorEventActiveTab, this.eventsAttributes.limits, this.eventsAttributes.skips),
      // this.getEventCountForCorporate(this.exhbitorEventActiveTab, activeTb)]);
      forkJoin([
        this.getEventListForCorporate(
          this.exhbitorEventActiveTab,
          this.eventsAttributes.limits,
          this.eventsAttributes.skips
        ),
      this.getEventCountForCorporate(this.exhbitorEventActiveTab ,activeTb)
      ]);
    }else if (this.loginUserRole === this.userRoleStatus.EXHIBITOR) {
      if (this.exhbitorEventActiveTab == this.commonStatus.ACTIVE) {

        var activeTb = "active";
      } else {
        var activeTb = "past"

      }

      forkJoin([
        this.getEventListForExibitor(
          this.exhbitorEventActiveTab,
          this.eventsAttributes.limits,
          this.eventsAttributes.skips
        ),
      this.getEventCountForExibitor(this.exhbitorEventActiveTab, activeTb),
      ]);

    }
  }

  getEventListForExibitor(
    ativeTab: number,
    limitNum: number,
    skipNum: number
  ): void {
    this.eventsAttributes.pageItems = [];
    this.currentPage = this.userSystemConstants.DEFAULT_CURRENT_PAGE

    // this.currentDate.setDate(this.currentDate.getDate() - 1);

    // Create new Date instance
    var todayD = new Date();
    var start = new Date();
    start.setHours(0, 0, 0, 0);

    // Add a day
    todayD.setDate(todayD.getDate() - 1);
    todayD.setHours(0, 0, 0, 0);
    //   console.log("dsfdsaf", this.userData);
    const queryObj: any =
      ativeTab === 1
        ? {
          where: {
            status: 1,
            coordinates: this.userData.region,
            eventDate: { gte: start },
          },
          limit: limitNum,
          skip: skipNum,
        }
        : {
          where: {
            status: 1,
            coordinates: this.userData.region,
            eventDate: { lte: todayD, gte: this.userData.created },
          },
          limit: limitNum,
          skip: skipNum,
        };
    // const queryObj: any = { where: { status: 1, eventDate: {gte: this.currentDate} }, limit: limitNum,skip: skipNum, };

    // This statement for search to event list
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventName
    ) {
      queryObj.where.eventName = this.eventSearchForm.value.eventName.trim().replace(/\s+/g, ' ');
      /*{
        regexp: 'm.*' + this.eventSearchForm.value.eventName + '/mi'
      }; */
    }
    this.searchEventByRegion(queryObj)

  //  start screening search screenRequired
    if (
      this.userData.screenRequired &&
      this.userData.screening &&
      this.userData.screening.length > 0
    ) {
      queryObj.where.screenReq = this.userData.screening;
    }
    queryObj.where.exhibId = this.userData.id;
    ///end screening search
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate &&
      this.exhibitorSearch == 1
    ) {
      if (ativeTab === 1) {
        var start = new Date(this.eventSearchForm.value.eventDate);
        start.setHours(0, 0, 0, 0);
        var end = new Date(this.eventSearchForm.value.eventDate);
        end.setHours(23, 59, 59, 999);

        // alert( start + ':' + end);
        queryObj.where.eventDate = { gt: start, lte: end };
      } else {
        var start = new Date(this.eventSearchForm.value.eventDate);
        start.setHours(0, 0, 0, 0);
        var end = new Date(this.eventSearchForm.value.eventDate);
        end.setHours(23, 59, 59, 999);
        //  queryObj.where.eventDate = { gte: today, lte: new Date(tommorowSelectDate) };

        queryObj.where.eventDate = { gt: start, lte: end };
      }
    } else if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate
    ) {
      ativeTab === 1
        ? (queryObj.where.eventDate = {
          gte: this.eventSearchForm.value.eventDate,
        })
        : (queryObj.where.eventDate = {
          lte: this.eventSearchForm.value.eventDate,
        });
    }

    this.eventService.getExhibitorEventList(queryObj).subscribe(
      (data) => {
        const res = data && data.length ? data : [];
        if (res && res.length > 0) {
          res.forEach((v: any, k): any => {
            if (v.event) {
              let mapId = "map_" + k;
              this.loadListMap(mapId, v.eventAddress);
            }
          });
          // this.activeEventCount = (res && res.length) ? res.length : 0;
          this.eventsAttributes.pageItems = res;
          this.allEventData = res;
        }
        this.allEventData.forEach((element) => {
          let currentdate = new Date();
          let enddate = new Date(element.eventDate);

          element.disable = false;
          //  console.log("1",currentdate.getTime()>= enddate.getTime(),currentdate, enddate,element.endDateTime, )

          if (currentdate.getTime() >= enddate.getTime()) {
            element.disable = true;
          }
        });
      },
      () => { }
    );
  }

  /**
   * This function is used to count Events For Exibitor according to active and past tab
   */

  getEventCountForExibitor(activeTab: number, activeDate: string): void {
    // var todayD = this.currentDate;

    var todayD = new Date();
    todayD.setDate(todayD.getDate() - 2);
    todayD.setHours(23, 59, 59, 999);

    var start = new Date();
    start.setHours(0, 0, 0, 0);
    // Add a day
    //
    // console.log("121111111111111111111",this.exhibitorSearch)
    const queryObj: any =
      activeDate === "active"
        ? {
          where: {
            status: 1,
            coordinates: this.userData.region,
            eventDate: { gte: start },
          },
        }
        : {
          where: {
            eventDate: { lte: todayD, gte: this.userData.created },
            status: 1,
            coordinates: this.userData.region, // eventDate: { lte: this.currentDate }
          },
        };
    // This statement for search to event list
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventName
    ) {
      queryObj.where.eventName = this.eventSearchForm.value.eventName.trim().replace(/\s+/g, ' ');
      /* queryObj.where.eventName = {
         regexp: 'm.*' + this.eventSearchForm.value.eventName + '/mi'
       };*/
    }
     this.searchEventByRegion(queryObj)

    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate &&
      this.exhibitorSearch == 1
    ) {
      if (activeDate === "active") {
        var start = new Date(this.eventSearchForm.value.eventDate);
        start.setHours(0, 0, 0, 0);
        var end = new Date(this.eventSearchForm.value.eventDate);
        end.setHours(23, 59, 59, 999);

        // alert( start + ':' + end);
        queryObj.where.eventDate = { gt: start, lte: end };
      } else {
        var start = new Date(this.eventSearchForm.value.eventDate);
        start.setHours(0, 0, 0, 0);
        var end = new Date(this.eventSearchForm.value.eventDate);
        end.setHours(23, 59, 59, 999);
        //  queryObj.where.eventDate = { gte: today, lte: new Date(tommorowSelectDate) };

        queryObj.where.eventDate = { gt: start, lte: end };
      }
    } else if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate
    ) {
      activeDate === "active"
        ? (queryObj.where.eventDate = {
          gte: this.eventSearchForm.value.eventDate,
        })
        : (queryObj.where.eventDate = {
          lte: this.eventSearchForm.value.eventDate,
        });
    }

    // start screening search
    if (
      this.userData.screenRequired &&
      this.userData.screening &&
      this.userData.screening.length > 0
    ) {
      queryObj.where.screenReq = this.userData.screening;
    }
    ///end screening search

    this.eventService.exhibitorEventsCounts(queryObj).subscribe(
      (data) => {
        if (activeDate === "active") {
          //  console.log("a");

          this.activeEventCount = data && data.count ? data.count : 0;
        } else if (activeDate === "past") {
          // console.log("b");
          this.pastEventCount = data && data.count ? data.count : 0;
        }
        activeTab === 1
          ? (this.eventsAttributes.count = this.activeEventCount)
          : (this.eventsAttributes.count = this.pastEventCount);
      },
      () => { }
    );
  }

  /**
   * This function is used to get Events for corporate user by Userid
   * @param activeTab number
   * @param  limitNum number
   * @param  skipNum  number
   */
  getEventListForCorporate(
    ativeTab: number,
    limitNum: number,
    skipNum: number
  ): void {
    /// console.log("zzzzzzzzzzzz");

 this.currentPage = this.userSystemConstants.DEFAULT_CURRENT_PAGE

    var todayD = new Date();
    todayD.setDate(todayD.getDate() - 1);
    todayD.setHours(23, 59, 59, 999);

    // Add a day
    // todayD.setDate(todayD.getDate() - 1)

    var start = new Date();
    start.setHours(0, 0, 0, 0);

    this.eventsAttributes.pageItems = [];
    const queryObj: any =
      ativeTab === 1
        ? {
          memberId: this.userData.id,
          eventDate: { gte: start },
          status: { inq: [0, 1, 2, 3] },
          limit: limitNum,
          skip: skipNum,
        }
        : {
          memberId: this.userData.id,
          eventDate: { lte: todayD },
          status: { inq: [0, 1, 2, 3] },
          limit: limitNum,
          skip: skipNum,
        };

    // This statement for search to event list
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventName
    ) {
      queryObj.eventName = this.eventSearchForm.value.eventName.trim().replace(/\s+/g, ' ');
      // queryObj.eventName = {
      //   regexp: 'm.*' + this.eventSearchForm.value.eventName + '/mi'
      // };
    }
    this.corporateSearchByRegion(queryObj)


    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate &&
      this.exhibitorSearch == 1
    ) {
      if (ativeTab === 1) {
        var start = new Date(this.eventSearchForm.value.eventDate);
        start.setHours(0, 0, 0, 0);
        var end = new Date(this.eventSearchForm.value.eventDate);
        end.setHours(23, 59, 59, 999);

        // alert( start + ':' + end);
        queryObj.eventDate = { gt: start, lte: end };
      } else {
        var start = new Date(this.eventSearchForm.value.eventDate);
        start.setHours(0, 0, 0, 0);
        var end = new Date(this.eventSearchForm.value.eventDate);
        end.setHours(23, 59, 59, 999);
        //  queryObj.where.eventDate = { gte: today, lte: new Date(tommorowSelectDate) };

        queryObj.eventDate = { gt: start, lte: end };
      }
    } else if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate
    ) {
      ativeTab === 1
        ? (queryObj.eventDate = { gte: this.eventSearchForm.value.eventDate })
        : (queryObj.eventDate = { lte: this.eventSearchForm.value.eventDate });
    }
    this.eventService.getEventForCorporate(queryObj).subscribe(
      (data) => {
        const res = data && data.length ? data : [];
        if (res && res.length > 0) {
          res.forEach((v: any, k): any => {
            if (v.event) {
              let mapId = "map_" + k;
              this.loadListMap(mapId, v.eventAddress);
            }
          });
          this.eventsAttributes.pageItems = res;
          // console.log(this.eventsAttributes.pageItems);
          this.allEventData = res;
        }
      },
      () => { }
    );
  }

  /**
   * This function is used to count Events For Corporate according to active and past tab
   */

  getEventCountForCorporate(activeTab: number, activeDate: string): void {
    // console.log("22222222222222222212132132132")

    var start = new Date();
    start.setHours(0, 0, 0, 0);

    // Add a day
    //todayD.setDate(todayD.getDate() - 1)
    var todayD = new Date();
    todayD.setDate(todayD.getDate() - 1);
    todayD.setHours(23, 59, 59, 999);
    const queryObj: any =
      activeDate === "active"
        ? {
          memberId: this.userData.id,
          eventDate: { gte: start },
          status: { inq: [0, 1, 2, 3] },
        }
        : {
          memberId: this.userData.id,
          eventDate: { lte: todayD },
          status: { inq: [0, 1, 2, 3] },
        };

    // This statement for search to event list
    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventName
    ) {
      // queryObj.eventName = {
      //   regexp: 'm.*' + this.eventSearchForm.value.eventName + '/mi'
      // };
      queryObj.eventName = this.eventSearchForm.value.eventName.trim().replace(/\s+/g, ' ');
    }
     this.corporateSearchByRegion(queryObj)

    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate &&
      this.exhibitorSearch == 1
    ) {
      if (activeDate === "active") {
        var start = new Date(this.eventSearchForm.value.eventDate);
        start.setHours(0, 0, 0, 0);
        var end = new Date(this.eventSearchForm.value.eventDate);
        end.setHours(23, 59, 59, 999);

        // alert( start + ':' + end);
        queryObj.eventDate = { gt: start, lte: end };
      } else {
        var start = new Date(this.eventSearchForm.value.eventDate);
        start.setHours(0, 0, 0, 0);
        var end = new Date(this.eventSearchForm.value.eventDate);
        end.setHours(23, 59, 59, 999);
        //  queryObj.where.eventDate = { gte: today, lte: new Date(tommorowSelectDate) };

        queryObj.eventDate = { gt: start, lte: end };
      }
    } else if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.eventDate
    ) {
      activeDate === "active"
        ? (queryObj.eventDate = { gte: this.eventSearchForm.value.eventDate })
        : (queryObj.eventDate = { lte: this.eventSearchForm.value.eventDate });
    }

    this.eventService.findCountsEventsForCorporate(queryObj).subscribe(
      (data) => {
        if (activeDate === "active") {
          this.activeEventCount = data && data.count ? data.count : 0;
        } else if (activeDate === "past") {
          this.pastEventCount = data && data.count ? data.count : 0;
        }
        activeTab === 1
          ? (this.eventsAttributes.count = this.activeEventCount)
          : (this.eventsAttributes.count = this.pastEventCount);
      },
      () => { }
    );
  }


  /*
   * Redirect to Event Detai Page
   */
  getperson() {
    // console.log("amdslkfslkdfdslk",dateData);
    //     this.date3 = this.datePipe.transform(dateData, 'MMM d, y')
  }
  viewDetail(slug) {

    this.router.navigate(["event-detail", slug]);
  }

  /*
   * Redirect to Corporate Review Event
   */
  viewCorporateReviewEvent(slug) {

    this.router.navigate(["corporate-review-event", slug]);
  }

  viewExibitor(slug,selectedStatus:number) {
    if(slug.eventId) this.authService.setEventID(slug.eventId);
    if(selectedStatus !== undefined) this.authService.setExhibitorListStatus(selectedStatus);
    this.router.navigate(["view-exhibitors", slug.permalink], { queryParams: { status: selectedStatus } });
  }
  addEventData() { }

  searchTaxo(term: string, item: any) {
    // console.log(term,item);
    // let strSearchLow = term.toLowerCase();
    // let objectLowerCase = item.name.toLowerCase();
    // var n = objectLowerCase.startsWith(strSearchLow);
    // if (n && n == true) {
    //   return item;
    // }
    let strSearchLow = term.toLowerCase();
    let nameLowerCase = item.name.toLowerCase();
    let formattedNameLowerCase = item.formattedName.toLowerCase();
    
    // Check if either the item's name or its formatted name contains the search term
    if (nameLowerCase.includes(strSearchLow) || formattedNameLowerCase.includes(strSearchLow)) {
        return true;
    } else {
        return false;
    }
  }

  eventsAttributesPageItems() {
    /****sort array by recnet date */
    // console.log(this.eventsAttributes.pageItems)
    return this.eventsAttributes.pageItems.sort((a, b) => {
      return new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime();
    });
  }

  async searchCorpUserList(term: string, item: any) {
    // let array = [];
    // array.push(item);
    // array = array.filter((x)=> {
    //    return x.username === item
    // })
    // console.log(array);
    let strSearchLow = term.toLowerCase();
    let objectLowerCase = item.username.toLowerCase();
    var n = objectLowerCase.startsWith(strSearchLow);
    if (n && n == true) {
      return item;
    }
  }

  // onImageSelect(event, key: string) {
  //   this.key = key;
  //   if (event && event.target && event.target.files && event.target.files[0]) {
  //     const reader = new FileReader();
  //     reader.onload = (event: any) => {
  //       this.imageSrc = event.target.result;
  //       this.uploader.uploadAll();
  //     };
  //     reader.readAsDataURL(event.target.files[0]);
  //   }
  // }

  resetOnToggle(): void {
    this.eventForm.reset();
    this.formIniti();
  }
  changed() {

    if (
      this.eventFormEndDateTime.value < 
      this.eventFormStartDateTime.value
    ) {
      this.endTime = true;
    } else {
      this.endTime = false;
    }
  }
  onChangeFomValue(): void {
    this.eventForm.get("startDateTime").valueChanges.subscribe(() => {
      this.disableTime = false;
      // console.log(val);
    });

    // this.eventForm.get('endDateTime').valueChanges.subscribe(val => {

    //   if (this.eventForm.get('endDateTime').value < this.eventForm.get('startDateTime').value) {
    //     this.endTime = true;
    //   } else {
    //     this.endTime = false;
    //   }
    // });
  }

  /**
   * Close Popup
   */

  closPopup(): void {
    this.modalRef.hide();
  }

  /**
   * Function for previous setps of from
   */
  previous(key) {
    // console.log(key);
    this.nextStepForEventForm = key
    this.submits["currentStep"] = key;
    // this.eventForm.controls.memberId.reset();
  }

  /*
   * Function for to move next step and check validation
   */


  // showEventImages(key: string){
  //   this.eventImages = [];
  //   this.eventImageName = key;
  //   if(key == 'defaultEventImages'){
  //     for (let i = 1; i < 3; i++) {
  //       this.eventImages.push('defaultEventImage_' + i + '.png');
  //     }
  //   }
  //   if (key == 'corporateHFImages') {
  //     for(let i = 0; i<2; i++){
  //       this.eventImages.push('corporateHFImage_'+ i +'.png');
  //     }
  //   }
  //   if (key == 'virtualClassEvntImages') {
  //     for(let i = 0; i<2; i++){
  //       this.eventImages.push('virtualClassEvntImage_'+ i +'.png');
  //     }
  //   }
  //   if (key == 'seatMassageImages') {
  //     for(let i = 0; i<2; i++){
  //       this.eventImages.push('seatMassageImage_'+ i +'.png');
  //     }
  //   }
  //   if (key == 'onsiteWebImages') {
  //     for(let i = 0; i<2; i++){
  //       this.eventImages.push('onsiteWebImage_'+ i +'.png');
  //     }
  //   }
  //   if (key == 'nutritionImages') {
  //     for(let i = 0; i<2; i++){
  //       this.eventImages.push('nutritionImage_'+ i +'.png');
  //     }
  //   }
  //   if (key == 'fitnessImages') {
  //     for(let i = 0; i<2; i++){
  //       this.eventImages.push('fitnessImage_'+ i +'.png');
  //     }
  //   }
  //   if (key == 'cookingDemoImages') {
  //     for(let i = 0; i<2; i++){
  //       this.eventImages.push('cookDemo_'+ i +'.png');
  //     }
  //   }
  // }

  addMediaValue(eventImage) {
    this.eventMediaArray.removeAt(0);
    this.eventMediaArray.push(new FormControl(eventImage));
    // this.eventForm.get('eventImageName').setValue(this.eventImageName);
    this.selectedEventImage = eventImage;
  }

  findUserByAdmin() {
    if (this.loginUserRole == 0) {
      const queryObj: any = {
        filter: {
          where: {
            status: 1,
            role: 10,
            _id: this.eventForm.controls.memberId.value,
          },
          order: "created DESC",
        },
      };
      this.loginService
        .findUserListByAdmin(queryObj)
        .subscribe((data: any[]) => {
          this.userDataFindByAdmin = Object.assign({}, data[0]);
        });
    } else {
      this.userDataFindByAdmin = this.userData.data;
    }
  }

  searchFilterCorporateUserList(term: string, item: any) {
    let strSearchLow = term.toLowerCase();
    let objectLowerCase = item.username.toLowerCase();
    var n = objectLowerCase.startsWith(strSearchLow);

    if (n && n == true) {
      if (item.username.match(term)) {
        return item;
      }
    }
  }

  next(key:string, valid:boolean ,nextStep:string) {
    this.nextStepForEventForm = nextStep
    this.submits["currentStep"] = key;
    this.submits[key]["submitted"] = true;
    if(this.eventFormPhone.value){
      this.eventFormPhone.setErrors(null);
      this.eventFormPhone.updateValueAndValidity()
      }
    if(this.eventForm && this.eventForm.controls && this.eventForm.controls.maxExhibitor && this.eventForm.controls.maxExhibitor.value && (typeof(this.eventForm.controls.maxExhibitor.value)!== "number")) this.eventForm.controls.maxExhibitor.setValue(this.eventForm && this.eventForm.controls && this.eventForm.controls.maxExhibitor && this.eventForm.controls.maxExhibitor.value.trim())
    if (nextStep == "step5") {
      // this.latitude = 47.751076;
      // this.longitude = -120.740135;
    }
 
    if (nextStep == "step2" && this.isAddEvent == "add") {
      if (this.eventMediaArray.length == 0) {
       this.fAttributes.submitted = true;
      }

      if (this.loginUserRole != 0) {
        this.userDataFindByAdmin = this.userData;
      }

      if (
        this.userDataFindByAdmin &&
        this.userDataFindByAdmin.data &&
        this.userDataFindByAdmin.data.company_name &&
        this.userDataFindByAdmin.data.company_name != ""
      ) {
        this.eventForm.patchValue({
          company: this.userDataFindByAdmin.data.company_name,
        });
      }
      if (
        this.userDataFindByAdmin &&
        this.userDataFindByAdmin.data &&
        this.userDataFindByAdmin.data.number_employee &&
        this.userDataFindByAdmin.data.number_employee != ""
      ) {
        this.eventForm.patchValue({
          onsiteEmp: parseInt(this.userDataFindByAdmin.data.number_employee),
        });
      }
      if (
        this.userDataFindByAdmin &&
        this.userDataFindByAdmin.data &&
        this.userDataFindByAdmin.data.website &&
        this.userDataFindByAdmin.data.website != ""
      ) {
        this.eventForm.patchValue({
          website: this.userDataFindByAdmin.data.website,
        });
      }
      //set address
      if (
        this.userDataFindByAdmin &&
        this.userDataFindByAdmin.address &&
      ( this.addEventType === EventType.Virtual || this.addEventType === EventType.ONSITE)
      ) {
        this.eventForm.patchValue({
          eventAddress: {
            location: this.userDataFindByAdmin.address.addressOne
              ? this.userDataFindByAdmin.address.addressOne
              : this.userDataFindByAdmin.address.formatted_address,
          },
        });
      }
      if (
        this.userDataFindByAdmin &&
        this.userDataFindByAdmin.address &&
        this.userDataFindByAdmin.address.addressOne &&
        this.userDataFindByAdmin.address.addressOne != "" &&
       (this.addEventType == this.userSystemConstants.ONSITE  || this.addEventType == this.userSystemConstants.VIRTUAL)
      ) {
        this.eventForm.patchValue({
          eventAddress: {
            fullAddess: this.userDataFindByAdmin.address.addressOne,
          },
        });
      }

      if (
        this.userDataFindByAdmin &&
        this.userDataFindByAdmin.address &&
        this.userDataFindByAdmin.address.formatted_address &&
        this.userDataFindByAdmin.address.formatted_address != ""
      ) {
        this.eventForm.patchValue({
          eventAddress: {
            formatted_address: this.userDataFindByAdmin.address
              .formatted_address,
          },
        });
      }

      if (
        this.userDataFindByAdmin &&
        this.userDataFindByAdmin.address &&
        this.userDataFindByAdmin.address.state &&
        this.userDataFindByAdmin.address.state != ""
      ) {
        this.eventForm.patchValue({
          eventAddress: { state: this.userDataFindByAdmin.address.state },
        });
      }

      if (
        this.userDataFindByAdmin &&
        this.userDataFindByAdmin.address &&
        this.userDataFindByAdmin.address.city &&
        this.userDataFindByAdmin.address.city != ""
      ) {
      }
      this.eventForm.patchValue({
        eventAddress: { country: "" },
      });

      if (
        this.userDataFindByAdmin &&
        this.userDataFindByAdmin.address &&
        this.userDataFindByAdmin.address.zip_code &&
        this.userDataFindByAdmin.address.zip_code != ""
      ) {
        this.eventForm.patchValue({
          eventAddress: { zip_code: this.userDataFindByAdmin.address.zip_code },
        });
      }
      if (
        this.userDataFindByAdmin &&
        this.userDataFindByAdmin.address &&
        this.userDataFindByAdmin.address.lat &&
        this.userDataFindByAdmin.address.lat != ""
      ) {
        this.eventForm.patchValue({
          eventAddress: { lat: this.userDataFindByAdmin.address.lat },
        });
      }
      if (
        this.userDataFindByAdmin &&
        this.userDataFindByAdmin.address &&
        this.userDataFindByAdmin.address.lng &&
        this.userDataFindByAdmin.address.lng != ""
      ) {
        this.eventForm.patchValue({
          eventAddress: { lng: this.userDataFindByAdmin.address.lng },
        });
      }
      if (
        this.userDataFindByAdmin &&
        this.userDataFindByAdmin.address &&
        this.userDataFindByAdmin.address.lng &&
        this.userDataFindByAdmin.address.lng != "" &&
        this.userDataFindByAdmin.address.lat &&
        this.userDataFindByAdmin.address.lat != ""
      ) {
        this.getAddressAccordingLatLng(
          this.userDataFindByAdmin.address.lat,
          this.userDataFindByAdmin.address.lng
        );
        this.latitude = this.userDataFindByAdmin.address.lat;
        this.longitude = this.userDataFindByAdmin.address.lng;
      }

    }
    let startTime = new Date(this.eventForm.value.startDateTime).getTime()
    let endTime =new Date(this.eventForm.value.endDateTime).getTime()

    if (startTime >endTime) {
      this.fAttributes.submitted = true;
      return
    }
    const eventDateYMD = moment(this.eventForm.value.eventDate).format(SystemConstants.YEAR_MONTH_DAY);
    const eventEndTime= moment(this.eventForm.value.endDateTime).format(SystemConstants.TIME_HOUR_MIN_SEC);
    const finalEventDate = eventDateYMD + '' + eventEndTime;
    let endEventDateTime = new Date(finalEventDate);
    let currentDate = new Date()
        if(this.isAddEvent == SystemConstants.EDIT){
          if(nextStep==='step2'){
            if (endEventDateTime < currentDate ) {
              this.notifier.notify(this.userSystemConstants.ERROR,this.userSystemConstants.EVENT_CAN_NOT_BE_CREATED_IN_PAST_DATE);
              return  this.fAttributes.submitted = true;
            }
          }
        }
        // TODO:- Validate on sumbit in edit mode

        if(nextStep===this.userSystemConstants.SUBMIT){
          if(this.eventForm.status===this.userSystemConstants.INVALID){
            this.notifier.notify(this.userSystemConstants.ERROR,this.userSystemConstants.PLEASE_ENTER_REQUIRED_DETAILS_IN_FORM);
            return  this.fAttributes.submitted = true;
          }
            if(endEventDateTime < currentDate){
            this.notifier.notify(this.userSystemConstants.ERROR,this.userSystemConstants.EVENT_CAN_NOT_BE_CREATED_IN_PAST_DATE);
            return  this.fAttributes.submitted = true;
            }
        }
    if (valid) {
      this.fAttributes.submitted = false;
      this.submits["currentStep"] = nextStep;
      if (this.addEventType == EventType.Virtual) {
        this.eventForm.patchValue({
          eventOptionType: EventType.Virtual,
        });
      } else {
        this.eventForm.patchValue({
          eventOptionType: EventType.ONSITE,
        });
      }
    console.log(this.eventForm)
      if (nextStep == "preview") {
        this.addTempEventData();
        this.modalOpen = false
      } else if (nextStep == "submit") {
        this.updateEventById(this.editEventSelectedItem.id);
        this.modalOpen = false

      }
    }else {
      this.fAttributes.submitted = true;
    }
    // window.scroll(0,250);
  }



  /**
   * confime template
   */
  setOnsiteEvent(template?: TemplateRef<any>){

    // TODO:- Adding On site event starts from step 1
    this.submits.currentStep = SystemConstants.STEP1;
    this.addEventType = EventType.ONSITE
    this.selectedEventImage = ''
    this.currentTimezone = this.timezoneService.getCurrentTimezone();
    this.selectedTimeZone = this.currentTimezone.label;
    this.timeZone.patchValue(this.currentTimezone)
    this.targetScroll(this.currentTimezone)
    console.log(this.currentTimezone );
    this.openConfirmModal(template)
  }
  setVirtualEvent(template?: TemplateRef<any>){

    // TODO:- Adding On site event starts from step 1
    this.submits.currentStep = SystemConstants.STEP1;
    this.addEventType =EventType.Virtual
    this.selectedEventImage = ''
    this.currentTimezone = this.timezoneService.getCurrentTimezone();
    this.timeZone.patchValue(this.currentTimezone)
    this.selectedTimeZone = this.currentTimezone.label;
    this.targetScroll(this.currentTimezone)
    this.openConfirmModal(template)
  }
  openConfirmModal(template: TemplateRef<any>) {
    if (this.addEventType == this.userSystemConstants.VIRTUAL || this.addEventType == this.userSystemConstants.ONSITE) {
    this.resetOnToggle();
      if(this.isAddEvent)
        if(template){
          this.modalRef = this.modalService.show(template, { class: "modal-sm" });
        }
     this.isAddEvent='add';
     this.getScreeningTaxo();
    }
  }

  openConfrimBox(template: TemplateRef<any>,type){
    if(type === translateJson.VIRTUAL_EVENT){
      this.selectedEvent = translateJson.VIRTUAL_EVENT
    }else{
      this.selectedEvent = translateJson.ONSITE_EVENT
    }
    this.modalRef = this.modalService.show(template, { class: "modal-sm" });
  }
  confirmEventForm(): void {
    if(this.selectedEvent === translateJson.VIRTUAL_EVENT ){
      this.setVirtualEvent()
    }else{
      this.setOnsiteEvent()
    }
    this.router.navigate(["event"]);
    this.modalRef.hide();
    if(!this.isAddEvent){
      this.modalOpen = false
    }
  }

  declineEventForm(): void {
    this.modalRef.hide();
  }

  /**
   * This function is used to get screening taxo list.
   */
  getScreeningTaxo(): void {
    // if(this.addEventKey == 'Virtual') {
    //   this.addEventType = 'Virtual';
    // }
    forkJoin([
      this.taxonomyService.getTaxonomyList({
        filter: {
          where: {
            status: 1,
            taxonomyTypeId: this.globals.taxonomyTypesConst.screeningId,
          },
        },
      }),
      this.taxonomyService.getTaxonomyList({
        filter: {
          where: {
            status: 1,
            taxonomyTypeId: this.globals.taxonomyTypesConst.industryId,
          },
        },
      }),
    ]).subscribe(
      (results) => {

        this.screeningParentList = results[0] ? results[0] : [];
        this.screeningParentList.forEach(item => {
          item.formattedName = this.buildFormattedName(item, this.screeningParentList);
      });
      this.screeningParentList.sort((a, b) => {
        return a.formattedName.localeCompare(b.formattedName);
    });
    this.activeScreenList = this.screeningParentList

        // TODO:- hidning Industries List we are not using this time 

        // this.industriesList = results[1] ? results[1] : [];
        if (!this.eventDynamicUrl) {
          if (
            this.userData.industry &&
            this.userData.industry.length > 0 &&
            this.userData.role == 20
          ) {
            let selectedIndustry: any = [];
            //#TODO: ##create single function
            // this.industriesList.forEach(async (value) => {
            //   _.find(this.userData.industry, function (o) {
            //     if (String(o) == String(value.id)) {
            //       selectedIndustry.push(value);
            //     }
            //   });
            // });
            // this.industriesList = selectedIndustry;
          }
          //screening
          if (
            this.userData.screening &&
            this.userData.screening.length > 0 &&
            this.userData.role == 20
          ) {
            let selectedScreening: any = [];
            this.screeningParentList.forEach(async (value) => {
              _.find(this.userData.screening, function (o) {
                if (String(o) == String(value.id)) {
                  selectedScreening.push(value);
                }
              });
            });

            this.screeningParentList = selectedScreening;
          }
        }
        // console.log(selectedIndustry);
      },
      () => { }
    );
  }

  buildFormattedName(item, allItems) {
    if (!item.parentId) {
        return item.name;
    }
    const parent = allItems.find(parentItem => parentItem.id === item.parentId);
    if (!parent) {
        return item.name;
    }
    const parentName = this.buildFormattedName(parent, allItems);
    return `${parentName} > ${item.name}`;
}

  // Google autocomplete address for address field
  onSearchAddressForRegion(event) {
    event.preventDefault();
    const autocompleteFrom = new google.maps.places.Autocomplete(
      <HTMLInputElement>event.srcElement,
      {
        types: ["geocode"],
      }
    );

    google.maps.event.addListener(autocompleteFrom, "place_changed", () => {
      this.ngZone.run(() => {
        // to get the place result
        let place: google.maps.places.PlaceResult = autocompleteFrom.getPlace();
        // to verify result from map
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        // to set latitude, longitude and zoom
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.getAddressAccordingLatLng(this.latitude, this.longitude);
        this.zoom = 7;
      });
    });
  }

  /**
   * This function is used to get address by passing lat and longitude.
   */
  getAddressAccordingLatLng(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          var regionsObj = {
            name: results[0].formatted_address,
            lat: latitude,
            lng: longitude,
            miles: this.miles,
            meters: this.miles * 1609.34,
            centerRadius: this.miles * 1609.34,
          };
          let region = this.mapRegion.find(mapRegion => mapRegion.name === regionsObj.name)

          if (!region) {
            this.mapRegion.push(regionsObj);
          }
          // if (this.mapRegion.length > 1 && this.nextStepForEventForm == 'step2') {
          //   this.mapRegion.splice(0, 1)
          // }

        }
      }

    );
  }

  /**
   * Remove circle
   */
  remvoeCircle(index: number): any {
    this.mapRegion.splice(index, 1)
  }
  /**
   * Google autocomplete address for address field
   */
  onAddreessChange(event) {
    // this.mapRegion = [];
    event.preventDefault();
    const autocompleteFrom = new google.maps.places.Autocomplete(
      <HTMLInputElement>event.srcElement,
      {
        types: ["geocode"],
      }
    );
    google.maps.event.addListener(autocompleteFrom, "place_changed", () => {
      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocompleteFrom.getPlace();
        if (place.address_components && place.address_components.length) {
          if (place.formatted_address) {
            this.eventForm.patchValue({
              eventAddress: { location: place.formatted_address },
            });
          }
          if (place.formatted_address) {
            this.eventForm.patchValue({
              eventAddress: { fullAddess: place.formatted_address },
            });
          }
          if (place.formatted_address) {
            this.eventForm.patchValue({
              eventAddress: { formatted_address: place.formatted_address },
            });
          }

          this.latitude = place.geometry.location.lat()
            ? place.geometry.location.lat()
            : 47.751076;
          this.longitude = place.geometry.location.lng()
            ? place.geometry.location.lng()
            : -120.740135;
          this.getAddressAccordingLatLng(this.latitude, this.longitude);

          for (let i = 0; i < place.address_components.length; i++) {
            this.eventForm.patchValue({
              eventAddress: { lat: place.geometry.location.lat() },
            });

            this.eventForm.patchValue({
              eventAddress: { lng: place.geometry.location.lng() },
            });

            let addressType = place.address_components[i].types[0];
            if (addressType === "administrative_area_level_2") {
              this.eventForm.patchValue({
                eventAddress: { city: place.address_components[i].long_name },
              });
            }
            if (addressType === "administrative_area_level_1") {
              this.eventForm.patchValue({
                eventAddress: { state: place.address_components[i].long_name },
              });
            }
            if (addressType === "postal_code") {
              this.eventForm.patchValue({
                eventAddress: {
                  zip_code: place.address_components[i].long_name,
                },
              });
            }
             if(addressType === this.userSystemConstants.COUNTRY){
              this.eventForm.patchValue({
                eventAddress:{
                  country: place.address_components[i].long_name,
                }
              })
            }
          }
        }
      });
    });
  }

  /**
   * generate Slug
   */
  generateSlug(eventName: string): any {
    this.eventForm.patchValue({
      permalink: eventName,
    });
    this.eventForm.patchValue({
      memberId: this.userData.id,
    });
  }

  /**
   * This function is used to add event data to temp collection.
   */
  addTempEventData(): any {
    this.getEventPermaLinkList();
  }
  /**
   * This function is used to save data in Temp collection for preview and will redirect to preview.
   */

  saveEventDataToTemp(): any {
    if(String(this.timeZone.value) === this.timeZone.value){
      let selectedTimeZoneLabel = this.getTheSelectedTimeZoneObject(this.timeZone.value)
      this.timeZone.patchValue(selectedTimeZoneLabel)
      if(this.hasSelectedTimeZone())this.DefaultTimezones =  this.timezoneService.getUSTimezones()
    }
    const queryObj: any = this.eventForm.value;
    const tepObj = Object.assign({}, queryObj);
    tepObj.regions = this.mapRegion;
    tepObj.maxExhibitor = Number(tepObj.maxExhibitor);
    tepObj.auth = 10;
    tepObj.status = 1;
    // tepObj.requestReffleItem = this.requestRaffleValue;

    /// event date format
    let startDateMDY = moment(tepObj.eventDate).format("YYYY-MM-DD");
    let eventDatess: any = moment(tepObj.endDateTime).format("THH:mm:ss");
    let finalEventDate: any = startDateMDY + eventDatess;
    tepObj.eventDate = new Date(finalEventDate);
    /// event date formatend

    if (
      _.isNil(tepObj.memberId) ||
      tepObj.memberId === null ||
      tepObj.memberId === ""
    ) {
      tepObj.memberId = this.userData.id;
    }

    // console.log("aaaaaaaaaaaaa",tepObj);
    // return

    if (this.eventDynamicUrl) {
      this.eventService
        .updateTempEvent(this.tempEventData.id, tepObj)
        .subscribe(() => {
          console.log(this.router.navigate(["event-detail/preview", tepObj.permalink]));
        });
    } else {
      this.eventService.addTepEvent(tepObj).subscribe(
        () => {
          console.log(this.router.navigate(["event-detail/preview", tepObj.permalink]));
        },
        (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            //this.notifier.notify('error', err.error.error.message);
          }
        }
      ); /* */
    }
  }

  /*
   * add medical value to data
   */

  centerChanged($event: any, indexed: number) {
    // this.latitude = $event.coords.lat;
    // this.longitude = $event.coords.lng;
    // console.log($event);
    // console.log($event * 0.000621);

    if ($event * 0.000621 > 100) {
      this.mapRegion[indexed]["centerRadius"] = 16094;
      this.centerRadius = 16094;
      // console.log(this.mapRegion[indexed]["centerRadius"]);
      return false;
    } else {
      this.mapRegion[indexed]["centerRadius"] = $event;
    }

    // console.log("================>");
    // console.log(this.mapRegion);
    // console.log("---<");
    // this.getAddress(this.latitude, this.longitude);
  }

  /**
   * This function is used to update event by using event Id .
   */

  updateEventById(id: string) {
    // console.log("sdf")

    if(String(this.timeZone.value) === this.timeZone.value){
      let selectedTimeZoneLabel = this.getTheSelectedTimeZoneObject(this.timeZone.value)
      this.timeZone.patchValue(selectedTimeZoneLabel)
      if(this.hasSelectedTimeZone())this.DefaultTimezones =  this.timezoneService.getUSTimezones()
    }
  console.log(this.timeZone)
    const queryObj: any = this.eventForm.value;
    const tepObj = Object.assign({}, queryObj);
    tepObj.regions = this.mapRegion;
    tepObj.maxExhibitor = Number(tepObj.maxExhibitor);
    /// event date format
    let startDateMDY = moment(tepObj.eventDate).format("YYYY-MM-DD");
    let eventDatess: any = moment(tepObj.endDateTime).format("THH:mm:ss");
    let finalEventDate: any = startDateMDY + eventDatess;
    let eventStartDateTime = moment(tepObj.startDateTime).format(SystemConstants.TIME_HOUR_MIN_SEC);
    let finalEventStartDateTime =  startDateMDY + eventStartDateTime;
    tepObj.eventDate = new Date(finalEventDate);
    tepObj.startDateTime = new Date(finalEventStartDateTime)
    tepObj.endDateTime = new Date(finalEventDate)   

    if (
      this.eventMediaArray &&
      this.eventMediaArray.status == "VALID" &&
      this.eventMediaArray.value
    ) {
      tepObj.media = [this.eventMediaArray.value[0]];
    }
    if (tepObj.media.length == 0) {
      const imageName = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      const randomImgName =
        imageName[Math.floor(Math.random() * imageName.length)];
      tepObj.media[0] = randomImgName + ".png";
    }
    /// event date formatend
    this.eventService.updateEvent(id, tepObj).subscribe(
      () => {
       
        this.submits["currentStep"] = "step1";
        if (this.submits["currentStep"] != "submit") {
          // this.toggle();
        }
        this.editIndex = -1;

        this.isAddEvent = "";
        const getIndex: number = this.eventsAttributes.pageItems.findIndex(
          (x) => String(x.id) === String(id)
        );
        if (getIndex >= 0) {
          this.eventsAttributes.pageItems[getIndex] = tepObj;
          this.eventsAttributes.pageItems[getIndex]._id = id;
          this.eventsAttributes.pageItems[getIndex].id = id;
          this.eventsAttributes.pageItems[
            getIndex
          ].openCloseStatus = this.editEventSelectedItem.openCloseStatus;
          this.eventsAttributes.pageItems[
            getIndex
          ].requestStatus = this.editEventSelectedItem.requestStatus;
          this.eventsAttributes.pageItems[
            getIndex
          ].corpData = this.editEventSelectedItem.corpData;
          this.notifier.notify(this.userSystemConstants.SUCCESS, translateJson.UPDATED_SUCCESSFULLY);
          // this.router.navigate(["event"]);
          this.getEventListAfterUpdateAccordingToUserRole()
        }
      },
      () => { }
    );
  }

  getEventListAfterUpdateAccordingToUserRole(){
    if (this.loginUserRole === 0) {
      let activeTab = this.adminActiveTab;
      if (activeTab == 3) {
        this.getPastEventListAndCount(this.commonStatus.ACTIVE)
      } else {
        this.getEventList(this.eventsAttributes.limits, this.startItem);
        this.updateEventCountForSuperAdmin()
      }
    } else if (this.loginUserRole === 10) {
      this.getEventListForCorporate(
        this.exhbitorEventActiveTab,
        this.eventsAttributes.limits,
        this.startItem
      );
      this.getEventCountForCorporate(this.exhbitorEventActiveTab, "active"),
      this.getEventCountForCorporate(this.exhbitorEventActiveTab, "past")
    } else if (this.loginUserRole === 20) {
      this.getEventListForExibitor(
        this.exhbitorEventActiveTab,
        this.eventsAttributes.limits,
        this.startItem
      );
      this.getEventCountForExibitor(this.exhbitorEventActiveTab, "active"),
      this.getEventCountForExibitor(this.exhbitorEventActiveTab, "past")
    }
  }
  /**
   * This function is used to set pre permalink for event .
   */

  onInputEventName(data: string) {
    if (data && data !== "") {
      const perma = data
        .replace("/event/", "")
        .trim()
        .replace(/[^A-Z0-9-]/gi, "-")
        .replace(/-{2,}/g, "-")
        .toLowerCase();
      this.eventForm.patchValue({
        permalink: perma,
      });
    }
  }

  /**
   * This function is used to get Event list for slug.
   */
  getEventPermaLinkList(): void {
    const queryObj: any = { filter: { fields: { permalink: true } } };
    // console.log(queryObj);
    this.eventService.getTempEventList(queryObj).subscribe(
      (data) => {
        const res = data && data.length ? data : [];
        if (res && res.length > 0) {
          let i = 0;
          let j = 1;
          let len = res.length;
          while (i < len) {
            if (res[i].permalink === this.eventForm.value.permalink) {
              this.eventForm.value.permalink =
                this.eventForm.value.permalink.replace(/\d+$/, "") + j;
              this.eventForm
                .get("permalink")
                .setValue(this.eventForm.value.permalink);
              i = -1;
              j++;
            }
            i++;
          }
        }
        this.saveEventDataToTemp();
      },
      () => { }
    );
  }

  /**
   * This function is used to set map on list.
   */
  loadListMap(mapId: string, location: any) {
    setTimeout(() => {
      var map = new google.maps.Map(
        <HTMLInputElement>document.getElementById(mapId),
        {
          center: new google.maps.LatLng(location.lat, location.lng),
          zoom: 10,
        }
      );
      let contentString =
        '<div id="content">' +
        '<div id="bodyContent">' +
        "<p>" +
        location.location +
        "</p>" +
        "</div>" +
        "</div>";

      let infowindow = new google.maps.InfoWindow({
        content: contentString,
      });

      let marker = new google.maps.Marker({
        position: { lat: location.lat, lng: location.lng },
        map: map,
      });
      marker.addListener("click", function () {
        infowindow.open(map, marker);
      });
    }, 0);
  }

  /**
   * This function is used to get Corporate user list for super admin.
   */
  getCorporateUserList(): void {
    const queryObj: any = {
      filter: {
        fields: { username: true, id: true },
        where: { status: 1, role: 10 },
        order: "created DESC",
      },
    };
    this.loginService.findUserList(queryObj).subscribe(
      (data) => {
        this.corporateUserList = data && data.length ? data : [];

        /***sorting array by username */
        this.corporateUserList = this.corporateUserList.sort((a, b) => {
          let au = a.username.toLowerCase(),
            bu = b.username.toLowerCase();

          if (au < bu) {
            return -1;
          }
          if (au > bu) {
            return 1;
          }
          return 0;
        });
      },
      () => { }
    );
  }

  /**
   * This function is used  for super admin to update status for event.
   */

  approveEvent(id: string, sts: number) {
    const queryObj: any = { status: sts };
    if (sts === 1 && this.userData.role === 0) {
      queryObj.eventType = this.selectedEvenType;
    }
    const getIndex: number = this.allEventData.findIndex(
      (x) => String(x.id) === String(id)
    );
    this.eventCommonService.setSelectedEventForAdmin(this.allEventData[getIndex])
    this.eventService.updateEvent(id, queryObj).subscribe(
      () => {
        let selectedEventData = this.eventCommonService.getSelectedEventForAdmin()
        if (selectedEventData) {
          selectedEventData.status = sts;
          selectedEventData.eventType = this.selectedEvenType;
          if (
            sts === 1 &&
            this.userData.role === 0 &&
            this.adminActiveTab == 0
          ) {
            selectedEventData.eventType = this.selectedEvenType;
            let temEmails: any = {};
            temEmails.userId = selectedEventData.memberId;
            temEmails.slug = "event-has-been-approved-template";
            //  temEmail.subject = 'Your Event Approved';
            let url =
              environment.sentEmailHost +
              "/event-detail/" +
              selectedEventData.permalink;
            // let eventURL='<a href='+url+"/"+selectedEventData.permalink+'>Click Here.</a>';
            let eventURL = `<a href='${url}' >Click Here</a>`
            temEmails.keys = {
              eventURL: eventURL,
              corp_name: selectedEventData.corpData[0].username
                ? selectedEventData.corpData[0].username
                : selectedEventData.corpData[0].email,
            };
            this.eventService.emailSends(temEmails);
            //start email to exhibitor
            const query = { eventId: selectedEventData.id ,selectedServics:selectedEventData.screenReq};
            this.eventService.getNearestExhibitor(query).subscribe((data) => {
              if (data && data.length) {
                selectedEventData.eventType = this.selectedEvenType;
                let url =
                  environment.sentEmailHost +
                  "/event-detail/" +
                  selectedEventData.permalink;
                let eventURL = `<a href=${url} >Click Here</a>`;
                data.forEach(async (value) => {
                  let temEmail: any = {};
                  temEmail.slug = "event-accept-by-admin-show-exhibitor-region";
                  temEmail.userId = String(value._id);
                  temEmail.keys = {
                    EVENT: selectedEventData.eventName,
                    EVENT_DATE: this.datePipe.transform(
                      selectedEventData.eventDate,
                      "MMM d, y"
                    ),
                    EVENT_START_TIME: this.datePipe.transform(
                      selectedEventData.startDateTime,
                      "h:mm a"
                    ),
                    EVENT_END_TIME: this.datePipe.transform(
                      selectedEventData.endDateTime,
                      "h:mm a"
                    ),
                    EVENT_ADDRESS: selectedEventData.eventAddress
                      .location
                      ? selectedEventData.eventAddress.location
                      : selectedEventData.eventAddress
                        .formatted_address,
                    CORPORATE_USER_NAME: selectedEventData.corpData[0]
                      .username
                      ? selectedEventData.corpData[0].username
                      : selectedEventData.corpData[0].email,
                    COMPANY_NAME: selectedEventData && selectedEventData.corpData[0] && selectedEventData.corpData[0].data && selectedEventData.corpData[0].data.company_name,
                    EXHIBITOR_NAME: value.username,
                    URL: eventURL,
                    PROFILE_URL: environment.sentEmailHost + "/profile",
                    TIME_ZONE: selectedEventData && selectedEventData.timeZone && selectedEventData.timeZone.label ? selectedEventData.timeZone.label : 'NA'
                  };
                  await this.eventService.emailSends(temEmail);
                });
              }
            });

            ///end emailt to exhibitor

            this.closPopup();
          }
          if (
            sts === 1 &&
            this.userData.role === 0 &&
            this.adminActiveTab == 2
          ) {
            selectedEventData.eventType = this.selectedEvenType;
            let temEmails: any = {};
            temEmails.userId = selectedEventData.memberId;
            temEmails.slug = "event-has-been-approved-template";
            //  temEmail.subject = 'Your Event Approved';
            let url =
              environment.sentEmailHost +
              "/event-detail/" +
              selectedEventData.permalink;
            // let eventURL='<a href='+url+"/"+selectedEventData.permalink+'>Click Here.</a>';
            let eventURL = `<a href=${url} >Click Here</a>`;
            temEmails.keys = {
              eventURL: eventURL,
              corp_name: selectedEventData.corpData[0].username
                ? selectedEventData.corpData[0].username
                : selectedEventData.corpData[0].email,
            };
            this.eventService.emailSends(temEmails);
            //start email to exhibitor

            const query = { eventId: selectedEventData.id };
            this.eventService.getNearestExhibitor(query).subscribe((data) => {
              if (data && data.length) {
                selectedEventData.eventType = this.selectedEvenType;
                let url =
                  environment.sentEmailHost +
                  "/event-detail/" +
                  selectedEventData.permalink;
                let eventURL = `<a href=${url} >Click Here</a>`;
                data.forEach(async (value) => {
                  let temEmail: any = {};
                  temEmail.slug = "event-accept-by-admin-show-exhibitor-region";
                  temEmail.userId = String(value._id);
                  temEmail.keys = {
                    EVENT: selectedEventData.eventName,
                    EVENT_DATE: this.datePipe.transform(
                      selectedEventData.eventDate,
                      "MMM d, y"
                    ),
                    EVENT_START_TIME: this.datePipe.transform(
                      selectedEventData.startDateTime,
                      "h:mm a"
                    ),
                    EVENT_END_TIME: this.datePipe.transform(
                      selectedEventData.endDateTime,
                      "h:mm a"
                    ),
                    EVENT_ADDRESS: selectedEventData.eventAddress
                      .location
                      ? selectedEventData.eventAddress.location
                      : selectedEventData.eventAddress
                        .formatted_address,
                    CORPORATE_USER_NAME: selectedEventData.corpData[0]
                      .username
                      ? selectedEventData.corpData[0].username
                      : selectedEventData.corpData[0].email,
                    COMPANY_NAME: selectedEventData && selectedEventData.corpData[0] && selectedEventData.corpData[0].data && selectedEventData.corpData[0].data.company_name,
                      EXHIBITOR_NAME: value.username,
                    URL: eventURL,
                    PROFILE_URL: environment.sentEmailHost + "/profile",
                    TIME_ZONE: selectedEventData && selectedEventData.timeZone && selectedEventData.timeZone.label ? selectedEventData.timeZone.label : 'NA'
                  };
                  await this.eventService.emailSends(temEmail);
                });
              }
            });

            ///end emailt to exhibitor

            this.closPopup();
          } else if (sts === 2 && this.userData.role === 0) {
            selectedEventData.eventType = this.selectedEvenType;
            let temEmail: any = {};
            temEmail.userId = selectedEventData.memberId;
            temEmail.slug = "event-has-been-declined-template";
            // temEmail.subject = 'Your Event Declined';
            temEmail.keys = {
              eventName: selectedEventData.eventName,
              USERNAME: selectedEventData.corpData[0].username
                ? selectedEventData.corpData[0].username
                : selectedEventData.corpData[0].email,
                COMPANY_NAME: selectedEventData && selectedEventData.corpData[0] && selectedEventData.corpData[0].data && selectedEventData.corpData[0].data.company_name,
            };
            this.eventService.emailSends(temEmail);
            // this.closPopup();
          }
          // if (sts != 2 && this.userData.role === 0) {
          //   this.closPopup();
          // }
          //event-has-been-declined-template
        }
        if (this.loginUserRole === 0) {
          let activeTab = this.adminActiveTab;
          if (activeTab === 3) {
            this.getEventListPast( this.eventsAttributes.limits, this.startItem);
          } else {
            this.getEventList(this.eventsAttributes.limits, this.startItem);
          }
          this.updateEventCountForSuperAdmin()   
        } else if (this.loginUserRole === 10) {
          this.getEventListForCorporate(
            this.exhbitorEventActiveTab,
            this.eventsAttributes.limits,
            this.startItem
          );
          this.getEventCountForCorporate(this.exhbitorEventActiveTab, "active"),
          this.getEventCountForCorporate(this.exhbitorEventActiveTab, "past")
        } else if (this.loginUserRole === 20) {
          this.getEventListForExibitor(
            this.exhbitorEventActiveTab,
            this.eventsAttributes.limits,
            this.startItem
          );
          this.getEventCountForExibitor(this.exhbitorEventActiveTab, "active"),
          this.getEventCountForExibitor(this.exhbitorEventActiveTab, "past")
        }
      },
      () => { }
    );
  }

  /**
   * This is used to get event type for event approval by admin
   * @param data any
   */
  setEventOption(data: any) {
    this.selectedEvenType = data;
  }

  /**
   * This function is used for event close and open by Super Admin and Corporate.
   */

  openAndCloseEvent(id: string, status: number) {
    const queryObj: any = { openCloseStatus: status };
    this.eventService.updateEvent(id, queryObj).subscribe(
      () => {
        const getIndex: number = this.allEventData.findIndex(
          (x) => String(x.id) === String(id)
        );

        if (getIndex >= 0) {
          this.allEventData[getIndex].openCloseStatus = status;
          this.eventsAttributes.pageItems[getIndex].openCloseStatus = status;
          // console.log("111", this.eventsAttributes.pageItems[getIndex].openCloseStatus)
        }
      },
      () => { }
    );
  }

  /**
   * Multi request for admin only.
   */

  /**
   * multi request for Exhibitor only
   */
  getExhibitorEventListAndCounts(): void {
    // console.log(this.exhbitorEventActiveTab,"i M innnnnn");
    if (this.exhbitorEventActiveTab == 1) {
      this.minStartDate = new Date();
      this.maxStartDate.setDate(this.minStartDate.getDate() + 10000);
      this.minStartDate.setDate(this.minStartDate.getDate());
    } else if (this.exhbitorEventActiveTab == 0) {
      /// console.log("i M innnnnn");
      this.minStartDate.setDate(this.minStartDate.getDate() - 10000);
      this.maxStartDate = new Date();
      this.maxStartDate.setDate(this.maxStartDate.getDate() - 1);
    }

    //
    this.eventSearchForm.reset();
    // if(this.exhibitorSearch == 1){

    // }
    forkJoin([
      this.getEventListForExibitor(
        this.exhbitorEventActiveTab,
        this.eventsAttributes.limits,
        this.eventsAttributes.skips
      ),
      this.getEventCountForExibitor(this.exhbitorEventActiveTab, "active"),
      this.getEventCountForExibitor(this.exhbitorEventActiveTab, "past"),
    ]);
  }

  /**
   * multi request for Corporate only
   */
  getCorporateEventListAndCounts(): void {
    /* if (this.exhbitorEventActiveTab == 1) {
       this.minStartDate = new Date();
       this.maxStartDate.setDate(this.minStartDate.getDate() + 10000);
     }
     else if (this.exhbitorEventActiveTab == 0) {
       /// console.log("i M innnnnn");
       this.minStartDate.setDate(this.minStartDate.getDate() - 1000);
       this.maxStartDate = new Date();
     }*/
     this.currentPage =  this.userSystemConstants.DEFAULT_CURRENT_PAGE
     this.editIndex=-1
    if (this.exhbitorEventActiveTab == 1) {
      this.minStartDate = new Date();
      this.maxStartDate.setDate(this.minStartDate.getDate() + 10000);
      this.minStartDate.setDate(this.minStartDate.getDate());
    } else if (this.exhbitorEventActiveTab == 0) {
      /// console.log("i M innnnnn");
      this.minStartDate.setDate(this.minStartDate.getDate() - 10000);
      this.maxStartDate = new Date();
      this.maxStartDate.setDate(this.maxStartDate.getDate() - 1);
    }

    //
    this.eventSearchForm.reset();
    forkJoin([
      this.getEventListForCorporate(
        this.exhbitorEventActiveTab,
        this.eventsAttributes.limits,
        this.eventsAttributes.skips
      ),
      this.getEventCountForCorporate(this.exhbitorEventActiveTab, "active"),
      this.getEventCountForCorporate(this.exhbitorEventActiveTab, "past"),
    ]);
  }

  /**
   * Event List Single item based on index of list
   */
  getEventDetail(index: number): void {
    if (this.editIndex == index) {
    } else {
      this.submits.currentStep = SystemConstants.STEP1;
      this.eventMediaArray.reset();
      this.mapRegion = [];
      //  this.eventForm.get('media')).clear();
      this.eventForm.controls["media"] = this.fb.array([]);
      // setTimeout(() => {
        this.editEventSelectedItem = this.allEventData[index];
        if (this.editEventSelectedItem && this.activeScreenList) {
          const screeningParentIdSet = new Set(this.activeScreenList.map(item => item._id));
          this.editEventSelectedItem.screenReq = this.editEventSelectedItem.screenReq.filter(id => screeningParentIdSet.has(id));
        }
        this.date3 = this.datePipe.transform(
          this.editEventSelectedItem.eventDate,
          "MM-dd-yyyy"
        );
        this.editEventSelectedItem.eventDate = this.date3;
        if(typeof this.editEventSelectedItem.otherExhibitor !== 'string' ){
          this.editEventSelectedItem.otherExhibitor.join(',')
        }

        if( this.editEventSelectedItem.eventOptionType == this.userSystemConstants.VIRTUAL){
          this.eventFormParkingInstructions.clearValidators();
          this.eventFormParkingInstructions.updateValueAndValidity()
          this.eventFormCheckIn.clearValidators();
          this.eventFormCheckIn.updateValueAndValidity()
          // this.eventFormOnsiteContact.clearValidators()
          this.eventFormParkingInstructions.updateValueAndValidity()
        }
        this.eventForm.patchValue(this.editEventSelectedItem); 
        this.timeZone.patchValue(this.editEventSelectedItem?.timeZone?.label);
        if(this.hasSelectedTimeZone())this.DefaultTimezones =  this.timezoneService.getUSTimezones()
        this.selectedTimeZone =  this.editEventSelectedItem?.timeZone?.label
         this.addMediaValue(this.editEventSelectedItem.media && this.editEventSelectedItem.media[0])
        if(this.regionCode && this.regionCode.value) {
         this.selectedRegionCode = this.regionCode.value
        }  
        // this.requestRaffleValue = this.allEventData[index].requestReffleItem;
        this.addEventType = this.editEventSelectedItem.eventOptionType
        // add regions to form
        if (this.editEventSelectedItem.regions) {
          this.mapRegion = [];
          // console.log(this.editEventSelectedItem.regions);
          for (const regionItem of this.editEventSelectedItem.regions) {
            this.mapRegion.push(regionItem);
            this.latitude = regionItem.lat ? regionItem.lat : 47.751076;
            this.longitude = regionItem.lng ? regionItem.lng : -120.740135;
          }
        }
      // }, 0);
    }
  }

 
targetScroll(selectedItem) {
  this.timezones = this.timezoneService.getAllTimezones().sort(
    (a, b) => a.offset - b.offset
  );
  setTimeout(() => {
    const scrollableElement = document.getElementById('scrollableElement');
    if (scrollableElement) {
      const index = this.timezones.findIndex(
        (timezone) => timezone.label === selectedItem.label
      );
      if (index !== -1) {
        const itemHeight = 37; 
        scrollableElement.scrollTop = (index * itemHeight + this.listItemAndLineHeightForStaticTimeZone);
        this.isSelectedTimeZones = true;
        this.isDefaultListing = false;
      }
    }
  }, 0);
}


  getTheSelectedTimeZoneObject(selectedTimezoneLabel: string): TimezoneOption | undefined {
    // Get the default U.S. time zones
    this.DefaultTimezones = this.timezoneService.getUSTimezones();
        const matchingDefaultTimezone = this.DefaultTimezones.find(
      (timezone) => timezone.label === selectedTimezoneLabel
    );
    if (matchingDefaultTimezone) {
      return matchingDefaultTimezone; 
    }
    const allTimezones = this.timezoneService.getAllTimezones();
    const matchingTimezone = allTimezones.find(
      (timezone) => timezone.label === selectedTimezoneLabel
    );
    return matchingTimezone; 
  }
  
  
  
  
  /**
   * Event Clone by Super admin or corporate user
   */
  eventClone(index: number): void {
    if (this.editIndex == index) {
    } else {
      setTimeout(() => {
        const cloneObj = this.eventsAttributes.pageItems[index];
        try {
          delete cloneObj.id;
        } catch (error) { }

        const queryObj: any = { filter: { fields: { permalink: true } } };
        this.eventService.getTempEventList(queryObj).subscribe(
          (data) => {
            const res = data && data.length ? data : [];
            if (res && res.length > 0) {
              let i = 0;
              let j = 1;
              let len = res.length;
              while (i < len) {
                if (res[i].permalink === cloneObj.permalink) {
                  cloneObj.permalink =
                    cloneObj.permalink.replace(/\d+$/, "") + j;
                  i = -1;
                  j++;
                }
                i++;
              }
            }
            cloneObj.eventName = cloneObj.eventName + "_copy";
            cloneObj.maxExhibitor = parseInt(cloneObj.maxExhibitor);
            cloneObj.onsiteEmp = parseInt(cloneObj.onsiteEmp);

            if (
              cloneObj.media == null ||
              cloneObj.media[0] == null ||
              cloneObj.media.length == 0
            ) {
              const imageName = [1, 2, 3, 4, 5, 6, 7, 8, 9];
              const randomImgName =
                imageName[Math.floor(Math.random() * imageName.length)];
              cloneObj.media[0] = randomImgName + ".png";
            }

            this.eventService.addTepEvent(cloneObj).subscribe(
              () => {

                console.log(this.router.navigate([
                  "event-detail/preview",
                  cloneObj.permalink,
                ]));
              },
              (err) => {
                if (
                  err &&
                  err.error &&
                  err.error.error &&
                  err.error.error.message
                ) {
                }
              }
            );
          },
          () => { }
        );
      }, 0);
    }
  }

  editEventData(): void { }


  hasEventExpired(experationDate: Date) {
    // your date logic here, recommend moment.js;
    var currentdate = new Date();
    this.allEventData.forEach((element) => {
      let enddate = new Date(element.eventDate);
      element.disable = false;
      if (currentdate.getTime() <= enddate.getTime()) {
        element.disable = true;
      }
    });
    return moment(experationDate).isBefore(moment(currentdate));
  }


  // Get Exhibitor list for Event
  getExhibitorList(eventID: string): void {
    const queryObj: any = { filter: { where: { localEventId: eventID } } };
    this.eventService.getEventExibitorList(queryObj).subscribe(
      (data) => {
        const res = data && data.length ? data : [];
        if (res && res.length > 0) {
          this.exhibListAttributes.pageItems = res;
          // console.log("====>");
          // console.log(this.exhibListAttributes.pageItems);
          // console.log("<<<<====>");

          let arrays = res;
          this.customers = [];
          for (var value of arrays) {
            const firstName: string = value.eventReqData.firstName
              ? value.eventReqData.firstName
              : "";
            const lastName: string = value.eventReqData.lastName
              ? value.eventReqData.lastName
              : "";
            const name: string = firstName + lastName;
            const address: string = value.address.formatted_address
              ? value.address.formatted_address
              : "";
            const phone: string = value.eventReqData.phone
              ? value.eventReqData.phone
              : "";
            const eventType: string = value.eventReqData.eventType
              ? value.eventReqData.eventType
              : "";
            const email: string = value.eventReqData.email
              ? value.eventReqData.email
              : "";
            const contactName: string = value.eventReqData.contactName
              ? value.eventReqData.contactName
              : "";
            const electricity: string = value.eventReqData.electricity
              ? value.eventReqData.electricity
              : "";
            const numberOfChairs: string = value.eventReqData.numberOfChairs
              ? value.eventReqData.numberOfChairs
              : "0";
            const numberOfTable: string = value.eventReqData.numberOfTable
              ? value.eventReqData.numberOfTable
              : "0";
            const paymentSpecified: string =
              value.eventReqData.paymentSpecified &&
                value.eventReqData.paymentSpecified == 1
                ? "Done"
                : "Not Done";
            var attendee = "";
            let eventStatus: string;
            if (
              value.eventReqData.attendees &&
              value.eventReqData.attendees.length
            ) {
                for (var i = 0; i < value.eventReqData.attendees.length; i++) {
                  var valueAttendee = value.eventReqData.attendees[i];
                  attendee +=
                    "Name: " +
                    valueAttendee.name +
                    " Email: " +
                    valueAttendee.email;
                  if (i < value.eventReqData.attendees.length - 1) {
                    attendee += ",";
                  }
                }
            }

            switch (value.eventReqStatus) {
              case 0:
                eventStatus = "Pending";
                break;
              case 1:
                eventStatus = "Approved";
                break;
              case 2:
                eventStatus = "Declined";
                break;
              default:
                eventStatus = "Pending";
            }

            this.customers.push({
              "Exhibitor Name": name,
              "Phone Number": phone,
              Address: address,
              EventType: eventType,
              Email: email,
              Electricity: electricity,
              ContactName: contactName,
              Attendees: attendee,
              "Number Of Chairs": numberOfChairs,
              "Number Of Table": numberOfTable,
              "Payment Specified": paymentSpecified,
              "Request Status": eventStatus,
            });
          }
          //console.warn(this.customers);
          this.exportService.exportExcel(this.customers, "customers");
        }
      },
      () => { }
    );
  }

  setEventCheckout(item: any, eventData: any): void {
    item.id = item._id;
    item.eventData = eventData;
    item.eventData.id = eventData._id;
    this.eventPaymentCheckOutList.push(item);
    //  console.log(this.eventPaymentCheckOutList);
    this.router.navigateByUrl("/exhibitor-event-payment");
    this.profileService.setOption(this.eventPaymentCheckOutList);
  }

  openURL(): void {
    this.router.navigateByUrl("/event");
  }


  getPastEventListAndCount(status:CommonStatusType){
    // this.exhibitorSearch=this.commonStatus.PENDING;
    this.adminActiveTab=this.commonStatus.ACTIVE_IN_PAST;
    this.currentPage =  this.userSystemConstants.DEFAULT_CURRENT_PAGE
    this.editIndex=-1
    this.getEventListPast( this.eventsAttributes.limits,
     this.startItem);
    this.getLocalEventsCountsPast(status,this.userSystemConstants.DEFAULT_CLICK_EVENT);
  }
  getEventListAndCount(status:CommonStatusType | number){
  // this.exhibitorSearch=this.commonStatus.PENDING;
  this.adminActiveTab=status;
  this.editIndex=-1;
  this.currentPage = this.userSystemConstants.DEFAULT_CURRENT_PAGE
  this.getEventList(this.eventsAttributes.limits, this.startItem);
  this.getLocalEventsCounts(status);
  }

  public get EventTabStatus(): typeof CommonTabStatus {
    return CommonTabStatus; 
  }

  public get usersRoleStatus(): typeof UserRoleStatus {
    return UserRoleStatus; 
  }

searchEventByRegion(queryObj:RegionQuery){

    if (
      this.eventSearchForm &&
      this.eventSearchForm.value &&
      this.eventSearchForm.value.searchByRegion
    ) {
      let regionFilter = { lat: this.latitude, lng: this.longitude }
      queryObj.where.filterByRegion = regionFilter;
    }
 }
corporateSearchByRegion(queryObj:RegionQuery){
  if (
    this.eventSearchForm &&
    this.eventSearchForm.value &&
    this.eventSearchForm.value.searchByRegion
  ) {
    let regionFilter = { lat: this.latitude, lng: this.longitude }
    queryObj.filterByRegion =regionFilter;
  }
}

scroll(el: HTMLElement) {
  el.scrollIntoView(true);
  window.scrollBy(0, -120);
}

}
