import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// Import Custome component.
import { WidgetTypeComponent } from "./widget-type/widget-type.component";
import { WidgetComponent } from "./widget/widget.component";
import { FoldTypeComponent } from "./fold-type/fold-type.component";
import { FoldComponent } from "./fold/fold.component";
import { MenuComponent } from "./menu/menu.component";
import { PageComponent } from "./page/page.component";
import { EventComponent } from "./event/event.component";
import { TaxonomyComponent } from "./taxonomy/taxonomy.component";
import { TaxonomyTypeComponent } from "./taxonomy-type/taxonomy-type.component";
import { IndustriesComponent } from "./industries/industries.component";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ScreeningComponent } from "./screening/screening.component";
import { ManageSliderComponent } from "./fold/fold-template/manage-slider/manage-slider.component";
import { ManageArticleComponent } from "./fold/fold-template/manage-article/manage-article.component";
import { ManageHtmlComponent } from "./fold/fold-template/manage-html/manage-html.component";
import { ManageVideoComponent } from "./fold/fold-template/manage-video/manage-video.component";
import { ManageImageComponent } from "./fold/fold-template/manage-image/manage-image.component";
import { ManageHeroComponent } from "./fold//fold-template/manage-hero/manage-hero.component";
import { HomeComponent } from "./home/home.component";
import { LoginComponent } from "./login/login.component";
import { SignupComponent } from "./signup/signup.component";
import { EmailTemplateComponent } from "./email-template/email-template.component";
import { CorporateUserComponent } from "./corporate-user/corporate-user.component";
import { ProfileComponent } from "./profile/profile.component";

import { LeftImageComponent } from "./left-image/left-image.component";
import { SliderComponent } from "./slider/slider.component";
import { RightImageComponent } from "./right-image/right-image.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { EventDetailComponent } from "./event-detail/event-detail.component";
import { ViewExhibitorsComponent } from "./view-exhibitors/view-exhibitors.component";
import { ExhibitorInvitationComponent } from "./exhibitor-invitation/exhibitor-invitation.component";
import { CorporateReviewEventComponent } from "./corporate-review-event/corporate-review-event.component";
import { FindExhibitorsComponent } from "./find-exhibitors/find-exhibitors.component";
import { ReviewEventsComponent } from "./review-events/review-events.component";
import { AdminEventReviewComponent } from "./admin-event-review/admin-event-review.component";
import { EventInquiriesComponent } from "./event-inquiries/event-inquiries.component";
import { PaymentManagementComponent } from "./payment-management/payment-management.component";
import { ExhibitorEventPaymentComponent } from "./exhibitor-event-payment/exhibitor-event-payment.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { UnregisteredExhibitorsComponent } from "./unregistered-exhibitors/unregistered-exhibitors.component";
import { UnregisterEventRequestComponent } from "./unregister-event-request/unregister-event-request.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { ScreeningListComponent } from "./screening-list/screening-list.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { ExhibitorTermsAndConditionsComponent } from "./exhibitor-terms-and-conditions/exhibitor-terms-and-conditions.component";
import { WhyRegisterComponent } from "./why-register/why-register.component";
import { CorporateTermsAndConditionsComponent } from "./corporate-terms-and-conditions/corporate-terms-and-conditions.component";
import { IndustriesListComponent } from "./industries-list/industries-list.component";
import { RefundPolicyComponent } from "./refund-policy/refund-policy.component";
import { AboutComponent } from "./about/about.component";

export const routes: Routes = [
  { path: "", component: HomeComponent, },
  { path: "widget-type", component: WidgetTypeComponent },
  { path: "widget", component: WidgetComponent },
  { path: "fold-type", component: FoldTypeComponent },
  { path: "fold", component: FoldComponent },
  { path: "taxonomy", component: TaxonomyComponent },
  { path: "screening-list", component: ScreeningListComponent },
  { path: "taxonomy-type", component: TaxonomyTypeComponent },
  { path: "page", component: PageComponent },
  { path: "industries", component: IndustriesComponent },
  { path: "industries-list", component: IndustriesListComponent },
  { path: "screenings", component: ScreeningComponent },
  { path: "menu", component: MenuComponent, },
  { path: "home", component: HomeComponent },
  { path: "login", component: LoginComponent },
  { path: "signup/:slug", component: SignupComponent },
  { path: "dashboard", component: DashboardComponent, },
  { path: "email-template", component: EmailTemplateComponent },
  { path: "event", component: EventComponent },
  { path: "event/update-event/:slug", component: EventComponent },
  { path: "exhibitors", component: CorporateUserComponent },
  { path: "corporate-user", component: CorporateUserComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "profile", component: ProfileComponent },
  { path: "event-detail/:slug", component: EventDetailComponent },
  { path: "event-detail/preview/:slug", component: EventDetailComponent },
  { path: "view-exhibitors/:slug", component: ViewExhibitorsComponent },
  // { path: "exhibitor-invitation", component: ExhibitorInvitationComponent },
  {
    path: "corporate-review-event/:slug",
    component: CorporateReviewEventComponent,
  },
  { path: "find-exhibitors", component: FindExhibitorsComponent },
  { path: "review-events", component: ReviewEventsComponent },
  { path: "admin-event-review/:slug", component: AdminEventReviewComponent },
  { path: "event-inquiries", component: EventInquiriesComponent },
  { path: "payment-management", component: PaymentManagementComponent },
  {
    path: "exhibitor-event-payment",
    component: ExhibitorEventPaymentComponent,
  },
  { path: "reset-password/:tokenData", component: ResetPasswordComponent },
  {
    path: "unregistered-exhibitor",
    component: UnregisteredExhibitorsComponent,
  },
  {
    path: "unregistered-event-request/:slug/:slug",
    component: UnregisterEventRequestComponent,
  },
  { path: "about-us", component: AboutUsComponent },
  { path: "privacy-policy", component: PrivacyComponent },
  { path: "contact-us", component: ContactUsComponent },
  {
    path: "exhibitor-terms-and-conditions",
    component: ExhibitorTermsAndConditionsComponent,
  },
  { path: "why-register", component: WhyRegisterComponent },
  {
    path: "corporate-terms-and-conditions",
    component: CorporateTermsAndConditionsComponent,
  },
  { path: "refund-policy", component: RefundPolicyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

// Import all component in array const.
export const routingComponents = [
  WidgetTypeComponent,
  WidgetComponent,
  FoldTypeComponent,
  FoldComponent,
  MenuComponent,
  ScreeningListComponent,
  PageComponent,
  EventComponent,
  TaxonomyComponent,
  TaxonomyTypeComponent,
  IndustriesComponent,
  FooterComponent,
  HeaderComponent,
  DashboardComponent,
  ScreeningComponent,
  ManageSliderComponent,
  ManageArticleComponent,
  ManageHtmlComponent,
  ManageVideoComponent,
  ManageImageComponent,
  ManageHeroComponent,
  HomeComponent,
  LoginComponent,
  SignupComponent,
  EmailTemplateComponent,
  CorporateUserComponent,
  EventComponent,
  ProfileComponent,
  ForgotPasswordComponent,
  EventDetailComponent,
  ViewExhibitorsComponent,
  ExhibitorInvitationComponent,
  CorporateReviewEventComponent,
  IndustriesListComponent,
  FindExhibitorsComponent,
  ReviewEventsComponent,
  AdminEventReviewComponent,
  EventInquiriesComponent,
  PaymentManagementComponent,
  ExhibitorEventPaymentComponent,
  ResetPasswordComponent,
  UnregisteredExhibitorsComponent,
  UnregisterEventRequestComponent,
  AboutUsComponent,
  PrivacyComponent,
  ContactUsComponent,
  ExhibitorTermsAndConditionsComponent,
  WhyRegisterComponent,
  CorporateTermsAndConditionsComponent,
  RefundPolicyComponent,
];

// Import dynamic component load(entry component)
export const routingEntryComponents = [
  ManageSliderComponent,
  ManageArticleComponent,
  ManageHtmlComponent,
  ManageVideoComponent,
  ManageImageComponent,
  ManageHeroComponent,
  LeftImageComponent,
  SliderComponent,
  AboutComponent,
  RightImageComponent,
];
