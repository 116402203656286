export const SORT_NO_MENU_LIST: ISortNoMenuList[] = [
    {
        menuName: 'dashboard', sortNumber: 1
    },
    {
        menuName: 'events', sortNumber: 2
    },
    {
        menuName: "inquiries", sortNumber: 3
    }, {
        menuName: 'members', sortNumber: 4
    }, {
        menuName: 'payments', sortNumber: 5
    }, {
        menuName: 'layout', sortNumber: 6
    }, {
        menuName: 'menus', sortNumber: 7
    }, {
        menuName: 'settings', sortNumber: 8
    }, {
        menuName: 'review', sortNumber: 9
    }
]

interface ISortNoMenuList {
    menuName: string,
    sortNumber: number
}


interface IComponentsRoutes {
    component: string;
    path: String
}

export const routesData: IComponentsRoutes[] = [
    { path: "widget-type", component: 'WidgetTypeComponent' },
    { path: "widget", component: 'WidgetComponent' },
    { path: "fold-type", component: 'FoldTypeComponent' },
    { path: "fold", component: 'FoldComponent' },
    { path: "taxonomy", component: 'TaxonomyComponent' },
    { path: "screening-list", component: 'ScreeningListComponent' },
    { path: "taxonomy-type", component: 'TaxonomyTypeComponent' },
    { path: "page", component: 'PageComponent' },
    { path: "industries", component: 'IndustriesComponent' },
    { path: "industries-list", component: 'IndustriesListComponent' },
    { path: "screenings", component: 'ScreeningComponent' },
    { path: "menu", component: 'MenuComponent', },
    { path: "home", component: 'HomeComponent' },
    { path: "login", component: 'LoginComponent' },
    { path: "signup/:slug", component: 'SignupComponent' },
    { path: "dashboard", component: 'DashboardComponent', },
    { path: "email-template", component: 'EmailTemplateComponent' },
    { path: "event", component: 'EventComponent' },
    { path: "event/update-event/:slug", component: 'EventComponent' },
    { path: "exhibitors", component: 'CorporateUserComponent' },
    { path: "corporate-user", component: 'CorporateUserComponent' },
    { path: "forgot-password", component: 'ForgotPasswordComponent' },
    { path: "profile", component: 'ProfileComponent' },
    { path: "event-detail/:slug", component: 'EventDetailComponent' },
    { path: "event-detail/preview/:slug", component: 'EventDetailComponent' },
    { path: "view-exhibitors/:slug", component: 'ViewExhibitorsComponent' },
    // { path: "exhibitor-invitation", component: 'ExhibitorInvitationComponent' },
    {
        path: "corporate-review-event/:slug",
        component: 'CorporateReviewEventComponent',
    },
    { path: "find-exhibitors", component: 'FindExhibitorsComponent' },
    { path: "review-events", component: 'ReviewEventsComponent' },
    { path: "admin-event-review/:slug", component: 'AdminEventReviewComponent' },
    { path: "event-inquiries", component: 'EventInquiriesComponent' },
    { path: "payment-management", component: 'PaymentManagementComponent' },
    {
        path: "exhibitor-event-payment",
        component: 'ExhibitorEventPaymentComponent',
    },
    { path: "reset-password/:tokenData", component: 'ResetPasswordComponent' },
    {
        path: "unregistered-exhibitor",
        component: 'UnregisteredExhibitorsComponent',
    },
    {
        path: "unregistered-event-request/:slug/:slug",
        component: 'UnregisterEventRequestComponent',
    },
    { path: "about-us", component: 'AboutUsComponent' },
    { path: "privacy-policy", component: 'PrivacyComponent' },
    { path: "contact-us", component: 'ContactUsComponent' },
    {
        path: "exhibitor-terms-and-conditions",
        component: 'ExhibitorTermsAndConditionsComponent',
    },
    { path: "why-register", component: 'WhyRegisterComponent' },
    {
        path: "corporate-terms-and-conditions",
        component: 'CorporateTermsAndConditionsComponent',
    },
    { path: "refund-policy", component: 'RefundPolicyComponent' },
    { path: "slider", component: 'SliderComponent' },
    { path: 'page-not-found', component: 'PageNotFoundComponent' }
];


export const staticMenu = [
    {
        "id": "",
        "menuName": "menus",
        "menuType": "Header Menu",
        "menuSort": 7,
        "linkTo": {
            "type": "P",
            "page": "menu"
        },
        "auth": 1,
        "accessLevel": 0,
        "status": 1,
    },
    {
        "id": "",
        "menuName": "dashboard",
        "menuSort": 1,
        "menuType": "Header Menu",
        "linkTo": {
            "type": "P",
            "page": "dashboard"
        },
        "auth": 1,
        "accessLevel": 0,
        "status": 1,
    },
    {
        "id": "",
        "menuName": "events",
        "menuSort": 2,
        "menuType": "Header Menu",
        "linkTo": {
            "type": "P",
            "page": "event"
        },
        "auth": 1,
        "accessLevel": 0,
        "status": 1,
    },
    {
        "id": "",
        "menuName": "settings",
        "menuType": "Header Menu",
        "menuSort": 8,
        "linkTo": {
            "type": "L",
            "url": null
        },
        "auth": 1,
        "accessLevel": 0,
        "status": 1,
        "subMenu": [
            {
                subMenuName: "Email Template",
                url: "email-template",
            },
            {
                subMenuName: "Industries",
                url: "industries-list",
            },
            {
                subMenuName: "Screenings",
                url: "screening-list",
            },
        ]
    },
    {
        "id": "",
        "menuName": "layout",
        "menuType": "Header Menu",
        "menuSort": 6,
        "linkTo": {
            "type": "L",
            "url": null
        },
        "auth": 1,
        "accessLevel": 0,
        "status": 1,
        "subMenu": [
            {
                subMenuName: "Pages",
                url: "page",
            },
            {
                subMenuName: "Folds",
                url: "fold",
            },
        ]
    },
    {
        "id": "",
        "menuName": "payments",
        "menuType": "Header Menu",
        "menuSort": 5,
        "linkTo": {
            "type": "P",
            "page": "payment-management"
        },
        "auth": 1,
        "accessLevel": 0,
        "status": 1,
    },
    {
        "id": "",
        "menuName": "review",
        "menuType": "Header Menu",
        "menuSort": 9,
        "linkTo": {
            "type": "P",
            "page": "admin-event-review/corporate-review"
        },
        "auth": 1,
        "accessLevel": 0,
        "status": 1,
    },
    {
        "id": "",
        "menuName": "inquiries",
        "menuType": "Header Menu",
        "menuSort": 3,
        "linkTo": {
            "type": "P",
            "page": "event-inquiries"
        },
        "auth": 1,
        "accessLevel": 0,
        "status": 1,
    },
    {
        "id": "",
        "menuName": "members",
        "menuType": "Header Menu",
        "menuSort": 4,
        "linkTo": {
            "type": "L",
            "url": null
        },
        "auth": 1,
        "accessLevel": 0,
        "status": 1,
        "subMenu": [
            {
                subMenuName: "Exhibitors",
                url: "exhibitors",
            },
            {
                subMenuName: "Corporate User",
                url: "corporate-user",
            },
            {
                subMenuName: "Unregistered Exhibitors",
                url: "unregistered-exhibitor",
            }
        ]
    },
    {
        "id": "",
        "menuName": "find exhibitors",
        "menuType": "Header Menu",
        "menuSort": 0,
        "linkTo": {
            "type": "P",
            "page": "find-exhibitors"
        },
        "auth": 1,
        "accessLevel": 10,
        "status": 1,
    },
    {
        "id": "",
        "menuName": "view events",
        "menuType": "Header Menu",
        "menuSort": 0,
        "linkTo": {
            "type": "P",
            "page": "event"
        },
        "auth": 1,
        "accessLevel": 20,
        "status": 1,
    },
    // {
    //     "id": "",
    //     "menuName": "invitation request status",
    //     "menuType": "Header Menu",
    //     "menuSort": 0,
    //     "linkTo": {
    //         "type": "P",
    //         "page": "exhibitor-invitation"
    //     },
    //     "auth": 1,
    //     "accessLevel": 20,
    //     "status": 1,
    // },
    {
        "id": "",
        "menuName": "review events",
        "menuType": "Header Menu",
        "menuSort": 0,
        "linkTo": {
            "type": "P",
            "page": "review-events"
        },
        "auth": 1,
        "accessLevel": 20,
        "status": 1,
    },
    {
        "menuName": "home",
        "menuSort": 0,
        "menuType": "Footer Menu",
        "linkTo": {
            "type": "P",
            "page": "home"
        },
        "auth": 3,
        "accessLevel": 21,
        "status": 1,
    },
    {
        "menuName": "about us",
        "menuSort": 0,
        "menuType": "Footer Menu",
        "linkTo": {
            "type": "P",
            "page": "about-us"
        },
        "auth": 3,
        "accessLevel": 21,
        "status": 1,
    },
    {
        "menuName": "contact us",
        "menuSort": 0,
        "menuType": "Footer Menu",
        "linkTo": {
            "type": "P",
            "page": "contact-us"
        },
        "auth": 3,
        "accessLevel": 21,
        "status": 1,
    },
    {
        "menuName": "privacy policy",
        "menuSort": 0,
        "menuType": "Footer Menu",
        "linkTo": {
            "type": "P",
            "page": "privacy-policy"
        },
        "auth": 3,
        "accessLevel": 21,
        "status": 1,
    }
]

export const MenuFormConstant = {
    MENU_LINK_FLAG: 'L',
    MENU_PAGE_FLAG: 'P',
    MENU_ARTICLE_FLAG: 'A'
} as const;
