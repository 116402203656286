import { Component, Injector, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder, AbstractControl } from "@angular/forms";
// Import utility method class
// Handle the global property
import { Globals } from "../globals";
import { Credential } from "../client-schema";
import { LoginService } from "../login/login.service";
import { NotifierService } from "angular-notifier";
import { Router } from "@angular/router";
import * as CryptoJS from "crypto-js";
import { AboutComponent } from "../about/about.component";
import { AuthService } from "../services/auth.service";
import { AppComponent } from "../app.component";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  isLogin = false;
  isShow: boolean = false;
  showPwd: boolean = false;
  forgotSubmitted: boolean = false;
  status: boolean = false;
  type: string = "password";
  access: any = {};
  showTemplate: string = "";
  loginForm: FormGroup;
  private notifier: NotifierService;
  forgotPasswordForm: FormGroup;
  isLoginFormSubmitted:boolean;
  constructor(
    private fb: FormBuilder,
    public globals: Globals,
    private loginService: LoginService,
    notifierService: NotifierService,
    private router: Router,
    private injector: Injector,
    private authService: AuthService,
    private appComponent: AppComponent
  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.authService.isLoggedIn.subscribe(
      (isLogin: boolean) => {
        if (isLogin) {
          if (this.appComponent.userData.role === 0) {
            // Load data
            this.router.navigateByUrl('/dashboard');
          } else {
            this.router.navigate([this.router.url]);
          }
        } else {
          this.loginCall();
        }
      },
      (err) => {
        console.log(err);
      }
    );
    // this.loginCall();
  }

  showForgotPassword() {
    this.forgotPasswordForm = this.fb.group({
      email: ["", [Validators.required]],
    });

    this.showTemplate = "forgot-password";
  }
  loginCall() {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      rememberMe: [true, []],
    });
    this.showTemplate = "login";
  }

    get loginEmailOrUserName():AbstractControl {
      return this.loginForm.get("email");
    }

    get loginPassword():AbstractControl {
      return this.loginForm.get("password");
    }

  forgotPassword() {
    if (this.forgotPasswordForm.valid) {
      const emailObj: any = Object.assign({}, this.forgotPasswordForm.value);
      //   console.log(emailObj);

      this.loginService.requestPasswordReset(emailObj).subscribe(
        () => {
          this.notifier.notify(
            "success",
            "Reset Email sent Successful. Please check your email."
          );
          this.loginCall();
        },
        (err) => {
          if (err && err.error && err.error.error && err.error.error.message) {
            this.notifier.notify("error", err.error.error.message);
          }
        }
      );
    } else {
      this.forgotSubmitted = true;
    }
  }

  // to show and hide password
  toggleShow() {
    this.showPwd = !this.showPwd;
    this.status = !this.status;
    if (this.showPwd) {
      this.type = "text";
    } else {
      this.type = "password";
    }
  }

  /**
   * login user in portal
   */
  login(): void {
    if (this.loginForm.invalid) {
      this.isLoginFormSubmitted = true;
      return;
    }
    if (this.loginForm.valid) {
      let loginObj: Credential = Object.assign({}, this.loginForm.value);
      this.loginService.memberLogin(loginObj).subscribe(
        (res) => {
          let userD: any = (res as any).userData;
          this.isLoginFormSubmitted = false;
          this.notifier.notify("success", "Login Successful!");
          if (userD.role == 20 && (userD.region.length == 0 || userD.region[0].loc[0] == 0)) {
            this.router.navigateByUrl("/profile");
          } else if (userD.role == 20 || userD.role == 10) {
            this.router.navigateByUrl("/event");
          } else if (userD.role == 0) {
            let secretKey = "q.[;a27ms.292'xs";
            localStorage.setItem("Em", CryptoJS.AES.encrypt(userD.email, secretKey).toString());
            this.router.navigateByUrl("/dashboard");
          }
          // setInterval(() => {
          //   window.location.reload()
          // }, 3000)
        },
        (err) => {
          console.log(err.error);
          if (
            err &&
            err.error &&
            err.error.error &&
            err.error.error.statusCode == 403
          ) {
            this.showForgotPassword();
            this.notifier.notify("error", err.error.error.message);
          } else if (
            err &&
            err.error &&
            err.error.error &&
            err.error.error.message &&
            err.error.error.message ==
            "Welcome to HFP. Your old password is expired please reset your password by forgot password."
          ) {
            this.showForgotPassword();
            this.showForgotPassword();
            this.notifier.notify("error", err.error.error.message);
          } else if (
            err &&
            err.error &&
            err.error.error &&
            err.error.error.message
          ) {
            this.notifier.notify("error", err.error.error.message);
          }
        }
      );
    }
  }
}
